<template>
    <v-card v-bind="$attrs">
        <v-card-title><h1>Account Setup</h1></v-card-title>
        <v-card-subtitle>We need a little inasdasdformation to setup your account</v-card-subtitle>
        <v-card-text>
            <ehc-form v-model="formData" :meta="forms[page]" @next="page++" @submit="submit()" :shakeInvalid="shake" @valid="valid=$event"></ehc-form>
        </v-card-text>
    </v-card>
</template>


<script>
    export default {
    components: { },
    props: [],
    mixins: [],
    name: 'new-org-prompt',

    data: () => ({
        form: [],
        page: 0,
        forms: [
            [
                {type: "text",   label: "Company Name",    key:"name",     required: true},
                {type: "button", label: "next",            emitOnClick: "next"}
            ],
            [
                {type: "radio",         key:"referredBy", label:"how did you find out about us?", required: true , radio: [
                    {label: "Facebook",              value:"Facebook"},
                    {label: "Instagram",             value:"Instagram"   },
                    {label: "Friend / Referral",     value:"Friend / Referral"},
                    {label: "Podcast",               value:"Podcast"},
                    {label: "Conference / Meetup",   value:"Conference / Meetup"},
                    {label: "LinkedIn",              value:"LinkedIn"},
                    {label: "Google Search",         value:"Google Search"},
                    {label: "Other",                 value:"Other"},

                ]},
                {type: "button",        label: "Submit",          key:"submit",   emitOnClick: "submit"}
            ],

        ],
        formData: {},
        valid: false,
        shake: false,
    }),
    watch: {
    },
    methods: {
        submit() {
            console.log("trying to submit")
            if (this.valid) {
                console.log("make a organization with this info", this.formData)
                this.$store.dispatch("makeOrg", this.formData)

            } else {
                console.log("input invalid")
                this.shake = true
            }
            
        }
    },
    computed: {
        loading: {
            get: function() {
                return this.$store.getters.tenantStatus.loading
            },
            set: function(val) {
                this.$store.commit('setTenantStatus', {loading: val})
            }
        },
        newTenantPrompt: {
            get: function() {
                return this.$store.getters.loadingTenant
            },
            set: function(val) {
                this.$store.commit('setNewTenantPrompt', val)
            }
        },
    },
    created() {

    }  
    };
</script>