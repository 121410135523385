<template>
  <div>
    <v-row justify="end" class="mb-3">
    <v-btn @click="$emit('closeMessage')" class="pointer mb-3" >Close</v-btn>
    </v-row>
    <v-btn x-large block color="primary" class="pointer text-h5" rounded @click="showProperty(message.data)">Go To  Property #{{oldData.docId}}</v-btn>
    <h2 class="mt-5">Old Page Content as of {{message.updatedAt}}</h2>
    <v-expansion-panels>
      <v-expansion-panel v-for="key in dataKeys" :key="key">
        <v-expansion-panel-header class="text-h5">{{ key }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item>
                <v-list-item-content v-html="filteredOldData[key]" class="body-1"></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="text-h5">Raw Data Object Formatted</v-expansion-panel-header>  
        <v-expansion-panel-content>
          <v-list>
            <v-list-item>
                <v-list-item-content v-html="oldData"></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="text-h5">Raw Data Object Un-Formatted</v-expansion-panel-header>  
        <v-expansion-panel-content>
          <v-list>
            <v-list-item>
                <v-list-item-content >{{oldData}}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      message: this.value,
      minStringLength: 30,
      excludedKeys: ["searchAble"]
    };
  },
  computed: {
    dataKeys: function() {
      return Object.keys(this.filteredOldData).sort();
    },
    oldData: function() {
      return this.message.data
    },
    filteredOldData: function() {
      const filteredData = {};
      for (const key in this.oldData) {
        const value = this.oldData[key];
        if (typeof value === 'string' 
          && value.length > this.minStringLength 
          && !value.includes('https://')
          && !this.excludedKeys.includes(key)) {
          filteredData[key] = value;
        }
      }
      return filteredData;
    }


  },
  watch: {
    value: {
      handler: function(val) {
        this.message = val;
      },
      deep: true
    }
  }
};
</script>
