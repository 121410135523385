import { render, staticRenderFns } from "./ehc-table.vue?vue&type=template&id=73a04b5c"
import script from "./ehc-table.vue?vue&type=script&lang=js"
export * from "./ehc-table.vue?vue&type=script&lang=js"
import style0 from "./ehc-table.vue?vue&type=style&index=0&id=73a04b5c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VChip,VImg,VPagination,VProgressLinear})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
