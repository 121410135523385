<template>
  <ehc-page v-if="userHasPermission(['superAdmin'])">
    <ehc-header text="Update Log"></ehc-header>
    <ehc-sheet>
      <ehc-table 
        :headers = headers
        :items = updatesLog
        :selectable="true"
        @click:row="showDetailsPopup($event)"
        :loading="loading"
        pagination="infinite"
        @bottomButton="getLogs(true)"
        @intersectBottom="getLogs(true)"
        :endOfData="endOfData"
        ></ehc-table>
    </ehc-sheet>

    <ehc-message 
            v-model="message"
            @cancel="message = {...closeMessage}"
            @yes="confirmAction(field.key)"
            
            >
      <template #details>
        <ehc-updates-log-details 
          :value="message" 
          :key="messageReloadKey"
          @closeMessage="closeMessage"
        ></ehc-updates-log-details>  
      </template>
     
    </ehc-message>

  </ehc-page>
</template>

<script>
import mixins from '@/mixins'
import EhcUpdatesLogDetails from '../../components/ehc-updates-log-details.vue'
import EhcButtonSmall from '../../components/global/ehc-button-small.vue'

export default {
  mixins: [mixins],
  components: {
    EhcUpdatesLogDetails,
    EhcButtonSmall
  },
  data() {
    return {
      pageTitle: 'Updates Log',
      loading: false,
      updatesLog: [],
      endOfData: false,
      message: {
        show: false,
      },  
      messageReloadKey: 1,
    }
  },
  methods: {
    
    showProperty: function(item){
      let link=`https://everhost.io/${item.propertyId}`
      window.open(link)
    },
    getLogs(nextPage=false) {
      this.loading = true
      let updatesLog = []

      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => { //if I don't debounce then issues come with competing queries when first searching
        if (nextPage === false) { this.updatesLog=[]}


        this.apiGetDocs('updateslog',{limit: 20, nextPage: nextPage, search: this.gcvDebouncedSearchString}).then((data) => {
          if (data.length == 0) {this.endOfData=true}
          updatesLog = [...this.updatesLog, ...data]
          //add a readable date
          updatesLog.forEach((item)=>{
            item.updatedAtAsString = new Date(item.updatedAt).toLocaleString()
          })
         
          this.updatesLog = updatesLog
          this.loading=false
        })

      }, 200)

    },
    showDetailsPopup(event){
      // this.messageReloadKey = this.messageReloadKey + 1
      this.message = {
        show: true,
        width: 1200,
        title: "Updates Log",
        actions: [
            {label: "close", clickEmit: "cancel", plain: true},
        ],
        slotName: "details",
        data: event.oldData,
        updatedAt: this.formatDate(event.updatedAt),
      }
    },  
      
    closeMessage(){
      this.message = {show: false}
    },
  },
  watch: {
    gcvDebouncedSearchString() {
      this.getLogs()
    }
  },
  mounted() {
    this.getLogs()
  },
  computed: {

    headers: function(){
      return [
          {
            text: 'City',
            align: 'start',
            sortable: true,
            value: 'propertyCity',
          },
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'propertyName',
          },
          {
            text: 'Property ID',
            align: 'start',
            sortable: true,
            value: 'propertyId',
          },
          // {
          //   text: 'Doc ID',
          //   align: 'start',
          //   sortable: true,
          //   value: 'docId',
          // },
          {
            text: 'Updated By (ID)',
            value: 'uid',
          },
          { text: 'Updated', value: "updatedAtAsString", sortable: true },
        ]
    },
  },
  created(){
    // this.getLogs()
    // this.$store.dispatch("getUpdateLogs")
    // this.getLogs()
    this.apiGetCountOfOldDocuments()
  }

}
</script>