<template>
<ehc-page>
 <ehc-form 
    v-model="formData" 
    :meta="meta" 
    @submit="submit()"
    @blur="blur()"
    />
    <v-alert
      v-if="errorMessage"
      type="error"
      :value="true"
      >
      {{errorMessage}}
    </v-alert>
    <v-btn 
      dark     
      icon 
      large
      color="primary"
      @click="closeTransfer">
      <v-icon dark>mdi-close</v-icon>
    </v-btn>
</ehc-page>
</template>

<script>
export default {
  name: 'EhcTransferProp',
  props: {
    value: {
      type: Object,
      default: () => []
    },
  },
  data() {
    return {
      pageTitle: 'Transfer',
      property: {},
      errorMessage: null,
      orgsTransferTo: [],
      formData: {
        isSwitched: true,
        uid: null,
        orgId: null
      }
    }
  },      
  computed: {
    meta: function() {
      let meta = [
        {type: "text",          label: "New User Id",               key: "uid",         sortOrder: 1},
        {type: "switch",        label: "Transfer Guest Data",       key: "isSwitched",  sortOrder: 3},
        {type: "space"},
        {type: "button",        label: "submit",                    key: "submit",      sortOrder:4,          emitOnClick: "submit"},
      ] 
      let metaOrg = [
          {type: "text",        
          label: "New Organization ID",                    
          key: "orgId",
          sortOrder: 2
          },
        ]
      if (this.orgsTransferTo.length > 1) {
        meta = [...meta,...metaOrg] 
      } 
      return meta.sort((a,b) => a.sortOrder - b.sortOrder)
    },

  },  
  methods: {
    async submit(){
      await this.updateProperty(this.value.propertyId)
      this.closeTransfer()
    },
    async blur(){
      let uid = this.formData.uid
      let orgIds = await this.apiGetOrgsForUid(uid)
      if (orgIds.length > 1) {
        this.orgsTransferTo = orgIds
      }
      if (orgIds.length === 1) {
        this.formData.orgId = orgIds[0].key
      }
    },
    //TODO:  need to add in tests to make sure uid and orgId are valid
    updateProperty: async function(propertyId){
      // this.formData.uid = this.formData.uid.trim()
      // this.formData.orgId = this.formData.orgId.trim()
      let userExists = await this.$store.dispatch('doesUserExist',this.formData.uid)
      let orgExists = await this.$store.dispatch('doesOrgExist',this.formData.orgId)
      if ( !userExists || !orgExists ){
        !userExists ? this.errorMessage = "User ID does not exist" : null
        !orgExists ? this.errorMessage = "Organization ID does not exist" : null
        !orgExists && !userExists ? this.errorMessage = "User ID and Organization ID do not exist" : null
        return
      }
      let property = {...this.property,...this.formData}
      await this.apiPutProperty( propertyId, property )
      if ( this.formData.isSwitched ) {
        this.moveGuestData(property)
      }
      console.log('property updated', property)
    },
    moveGuestData: function(property){
      //console.log("moveGuestData ", property)
      this.$store.dispatch("moveGuestDataToUser", {uid: property.uid, propertyId: property.propertyId})
    },
    closeTransfer: function(){
      this.$emit('closeTransfer')
    },
    resetFormData: function(){
      this.formData.uid = null
      this.formData.orgId = null
      this.formData.isSwitched = true
    },

  },
  async created(){
    this.property = await this.apiGetDoc('properties', this.value.propertyId)  }
}
</script>