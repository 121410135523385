<!--shows the properties views of an orgObj passed to it.
    needs an org object with orgId as one of the keys
    you will probably have to do something like this

    <ehc-properties :orgObj="{...org, orgId: value}"></ehc-properties>

    otherwise you shouldn't really need to do anything. 
 -->
<template>
    <div class="ehc-properties" >

        <span v-if="userIsAdmin">{{gcvOrg.key}} has {{propCount}} of {{propLimit}}</span>

        <ehc-header 
            :text="headerText" 
            :actions="headerActions"
            @addProperty="addProperty()"
            @viewChange='view=$event'
            @reindexProps="message={...confirmReindex}"
            ></ehc-header>

        <ehc-prop-view-decider 
            :view="view" 
            :properties="properties"
            :actions="actions"
            :loading="loading"
            @deleteProperty="message= {...confirmDelete, prop: $event}"
            @publishProperty="publishProperty($event)"
            @showInterview="showInterview($event.propertyId)"
            @showAnalytics="showAnalytics($event.propertyId)"
            @goToPreview="goToPreview($event.propertyId)"
            @propertyLink="showPropertyLink($event)"
            @propertyQRCode="showPropertyQRCode($event)"
            @editProperty="goToProperty($event)"
            @showTransferProperty="showTransferProperty($event.propertyId)"
            @copyProperty="copyProperty($event)"
            @guestInfo="setGuestInfo($event)"
            @goToOrg="goToOrg($event)"
            @bottomButton="getProperties(true)"
            @intersectBottom="getProperties(true)"
            :endOfData="endOfData"
            ></ehc-prop-view-decider>

        <ehc-message 
            @reindexProps="reindexProps()"
            @cancel ="message=closeMessage"
            @deleteProp = "deleteProperty(message.prop)"
            @nextInterview="nextInterview($event)"
            @closeInterview="closeInterview()"
            v-model="message"
            >

            <template #link>
                Link to {{message.prop.name}} guide has been copied to your clipboard <br/><br/>
                <p class="text-h5 text-center" v-if="!message.prop.publishedAt">This property has not been published yet.</p>
            </template>

            <template #qrcode>
                <qrcode 
                    @downloadQRCodeImage="downloadQRCodeImage" 
                    :value="message.link" 
                    :showTitle=true 
                    :showBigButton=false 
                />
            </template>

            <template #maxProps>
                <h5>you have reached the maximum number of properties available ({{propLimit}}). To talk about our premium options contact ed@everhost.io.</h5> 
            </template>

            <template #transferProp>
                <span style="font-size: .8em;">This feature is in 'beta'. Be sure you enter a valid uid and orgId<br /></span>
                <ehc-transfer-prop :value="message" @transferProp="transferProp($event)" @closeTransfer="closeTransfer" ></ehc-transfer-prop>
            </template>

            <template #interview>
                <ehc-interview 
                    :propertyId="message.propertyId" 
                    :page="message.page" 
                    @change="message.formData = $event" 
                    @closeInterview="closeInterview"
                    :trigger = "message.trigger"
                    :key="propertyId"></ehc-interview>
            </template>

            <template #analytics>
                <ehc-analytics-guest 
                    close 
                    :propertyId="message.propertyId" 
                    :guestInfo="message.guestInfo" 
                    @closeAnalytics="closeAnalytics">
                </ehc-analytics-guest>
            </template>

        </ehc-message>
    </div>
</template>



<script>

import EhcPropertiesTable from '@/components/properties/ehc-properties-table.vue'
import EhcPropertiesGallery from '@/components/properties/ehc-properties-gallery.vue'
import EhcPropViewDecider from '@/components/properties/ehc-prop-view-decider.vue'
import EhcInterview from '@/components/properties/ehc-interview.vue'
import EhcAnalyticsGuest from '@/components/ehc-analytics-guest.vue'
import EhcTransferProp from '@/components/properties/ehc-transfer-prop.vue'

import qrcode from '@/components/ehc-qrcode.vue'

//get index mixin   
import Formating from '@/mixins/index.vue'
import ehcAnalyticsGuestVue from '../ehc-analytics-guest.vue'
import PropertyActionsVue from '../../mixins/propertyActions.vue'

    export default {
        components: {
            EhcPropertiesTable, 
            EhcPropertiesGallery, 
            EhcPropViewDecider, 
            EhcInterview, 
            EhcTransferProp, 
            EhcAnalyticsGuest, 
            qrcode
            },
        mixins: [Formating, PropertyActionsVue],
        props: {
            orgObj: {type: [Object, Boolean], default: false},
            headerText: {type: String, default: "Properties"},
            search: {type: String, default: null},
            },
        data() {
            return {
                closeDialog: {
                    show: false,
                    prop: {},
                    message: '',
                    link: null,
                    orgId: null,
                    moveGuestInfo: false
                },
                message: {
                    show: true,
                },
                // transferPropMessage: {
                //     show: true,
                //     title: "Transfer Property",
                //     message: "TODO: this feature is broken, if we really need it then it should be fixed. ",
                //     slotName: "transferProp",
                //     actions: [
                //         {type: "spacer"},
                //         {label: "ok", clickEmit: "cancel"}
                //     ]
                // },
                maxPropertiesMessage: {
                    show: true,
                    title: "max properties reached",
                    slotName: "maxProps",
                    actions: [
                        {type: "spacer"},
                        {label: "ok", clickEmit: "cancel"}
                    ]
                },
                closeMessage: {
                    show: false,
                    propertyId: null,
                },
                confirmDelete: {
                    show: true,
                    title: "Are You Sure?",
                    message: "are you sure you want to delete this property?",
                    width: 350,
                    persistent: false,
                    loading: false,
                    actions: [
                        {label: "yes delete", clickEmit: "deleteProp", color: "error"},
                        {type: "spacer"},
                        {label: "cancel", clickEmit: "cancel", color: "success"},                        
                    ]
                },
                confirmReindex: {
                    show: true,
                    title: "Are You Sure?",
                    message: "this will re-index all properties, it will take some time",
                    width: "400",
                    persistent: false,
                    loading: false,
                    actions: [
                        {label: "cancel", clickEmit: "cancel",  dark: false, plain: true},
                        {type: "spacer"},
                        {label: "yes re-index", clickEmit: "reindexProps"}
                    ]
                },
                transferPropertyDialog: {
                    show: false,
                    prop: {},
                    orgId: null,
                    moveGuestInfo: false,
                    actions: [
                        {label: "cancel", clickEmit: "cancel",  dark: false, plain: true},
                        {type: "spacer"},
                    ]
                }, 
                linkDialog: {
                    show: false,
                    prop: {}
                },
                message: {
                    show: false
                },
                maxPropsDialog: false,
                properties: [],
                view: 'gallery',
                loading: false,
                endOfData: false,
                propertyId: null,
                // downloadQRCodeImage: null,
            }   
        },
        watch: {
            orgObj(newOrg, oldOrg) {
                // console.log("properties org change", this.orgObj)
                if (newOrg.orgId != oldOrg.orgId) {
                    this.properties = []
                    this.getProperties()
                }
            },
            search() {
                this.properties = []
                this.getProperties(false)
            },
            // Uncomment when you want to show interview on new property when showInterviewOnNewProperty is true
            propertyId(value){
                if ( this.showInterviewOnNewProperty ) {
                    this.showInterview(value)
                } else {
                    console.log("showInterviewOnNewProperty is ", this.showInterviewOnNewProperty)
                }
            },
            // message(){
            //     console.log("message changed in ehc-properties", this.message)
            // },
            // "message.trigger"(){
            //     console.log("message.trigger changed in ehc-properties", this.message.trigger)
            // },
        },
        mounted() {
            // console.log("properties mounted", this.orgObj)
            this.getProperties()
        },
        computed: {
            actions() {
                let items = [
                        {type:'link',   label: 'Edit',                iconURL: require('@/assets/icons/edit@3x.svg'),     emitClick: 'editProperty',     toolTip: 'Edit property'},
                        {type:'link',   label: 'Link',                iconURL: require('@/assets/icons/Link@3x.svg'),     emitClick: 'propertyLink',     toolTip: 'Get property QR Code & link'},
                        {type:'link',   label: 'QR Code',                iconURL: require('@/assets/icons/qrcode.svg'),     emitClick: 'propertyQRCode',     toolTip: 'Get property QR Code & link'},
                        {type:'link',   label: 'Delete',              iconURL: require('@/assets/icons/trash@3x.svg'),    emitClick: 'deleteProperty',   toolTip: 'Delete property'},
                        {type:'toggle', key: 'publishedAt',   
                            onTrue:     {label: 'Unpublish',          iconURL: require('@/assets/icons/unpulish@3x.svg'), emitClick: 'publishProperty',        toolTip: 'click to unpublish property'},
                            onFalse:    {label: 'Publish',            iconURL: require('@/assets/icons/unpulish@3x.svg'), emitClick: 'publishProperty',        toolTip: 'click to publish property'}
                        },
                        {type:'link',   label: 'Copy',                iconURL: require('@/assets/icons/Copy@3x.svg'),     emitClick: 'copyProperty',     toolTip: 'Copy property'},
                        {type:'toggle', key: 'noguestinfo',   
                            onFalse: {label: 'Do not ask for guest info',            icon:    'mdi-clipboard-account-outline',           emitClick: 'guestInfo',        toolTip: 'Copy property'},
                            onTrue:  {label: 'Ask for guest info',                   icon:    'mdi-clipboard-account-outline',           emitClick: 'guestInfo',        toolTip: 'Copy property'}
                        },
                        {type:'link',   label: 'Analytics', icon: 'mdi-chart-box-outline', emitClick: 'showAnalytics', toolTip: 'Guest Analytics'},                        
                        ...this.userHasPermission(['superAdmin'],[
                            {type: 'subheader', label:'Admin'},
                            {type: 'divider'},
                            {type:'link', label: 'Transfer', icon: "mdi-swap-horizontal", emitClick: 'showTransferProperty', toolTip: 'Transfer property to another account'},
                            {type:'link', label: 'View Organization', iconURL:  require('@/assets/icons/Home@3xplain.svg'), emitClick: 'goToOrg', toolTip: 'view the organization of this property'},
                            {type:'link',   label: 'Setup',              icon: 'mdi-handshake',    emitClick: 'showInterview',   toolTip: 'Add Property Helper'},                        
                            {type:'link',   label: 'Preview',              icon: 'mdi-eye-arrow-right',    emitClick: 'goToPreview',   toolTip: 'Preview of Dev'},                        
                            {type:'link',   label: 'Property Template',              icon: 'mdi-copyleft',    emitClick: 'goToDefault',   toolTip: 'Default Template'},                        
                        ],[])
                    ]
                return items
            },
            appSite() {return this.$store.getters.appSite},
            propLimit() {
                const orgObj = this.orgObj
                const appSettings = this.gcvAppSettings

                return (orgObj.hasOwnProperty('propertyLimit')) ? orgObj.propertyLimit : appSettings.defaultPropertyLimit
            },
            headerActions() {
                let adminActions = this.userHasPermission(['superAdmin'],[
                    {actionType: 'menu', icon: 'mdi-database-outline', items: [
                        {type:'link',   label: 'reIndex Properties', icon: "mdi-database-cog-outline",     emitClick: 'reindexProps',     toolTip: 're index all properties'}
                    ]}
                ],[])

                let actions = [
                    {actionType: 'v-btn-toggle', dense: true, changeEmit: "viewChange", default: "gallery", mandatory: true,
                        buttons: [     
                            {label: 'Grid View', value: 'gallery', outlined: true, prependIconURL: require('@/assets/icons/Grid View@3x.svg')},
                            {label: 'List View', value: 'table', outlined: true, prependIconURL: require('@/assets/icons/List View@3x.svg')}
                        ]},
                ]
                let orgActions = (this.orgObj != false) ? [{actionType: 'v-btn', label: "add property", color: "primary", dark: true, prependIcon: "mdi-plus", clickEmit: "addProperty"}] : []

                return [...adminActions, ...actions, ...orgActions]
            },
            showInterviewOnNewProperty: function(){
                //turn on/off the interview process on a new property creation
                //TODO: comment out next line before merging
                // return true 
                try {
                    return this.$store.getters.appSettings.showInterviewOnNewProperty 
                } catch (err) {
                    console.log(err)
                    return true
                }
            },
            propCount: function(){
                return this.properties.length
            },
        },
        methods: {
            downloadQRCodeImage(){
                console.log("downloadQRCodeImage in parent", this.$refs.qrCodeComponent)
            },
            onDataUrlChange(dataUrl) {
                console.log("onDataUrlChange", dataUrl)
                this.dataUrl = dataUrl
            },
            reload() {
                console.log("test reload")
                this.getProperties()
            },
            goToOrg(item) {
                this.$router.push(`/organization/${item.orgId}`) 
            },
            async reindexProps() {
                console.log("reindexProps")
                this.message = {
                    show:true,
                    title: "reindexing",
                    loading: true,
                    persistent: true,
                    message: "reindexing: please do not close your browser, this may take a while"
                }


                const props = await this.apiGetDocs('properties')
                const currentDate = Date.now()

                for (let i=0; i<props.length ;i++) {
                    if (props[i].docId) {
                        await this.apiUpdateDoc('properties', props[i].docId, {indexedAt: currentDate, key: props[i].docId})
                    }
                }

                this.message = {
                    show: true,
                    title: "complete",
                    message: "complete",
                    actions:[
                    {type: "spacer"},
                    {label: "ok", clickEmit: "cancel"},]
                }


            },
            setGuestInfo(item){
                //TODO: make this work with apimixin
                this.$store.dispatch("setGuestInfoSwitchOnProperty", item)
            },
            async copyProperty(item) {

                if ( this.propLimit - this.propCount <= 0 ) {
                    console.log("max properties reached")
                    this.message = {...this.maxPropertiesMessage}
                    return
                } else {
                    console.log("max properties not reached", this.propLimit, this.propCount)
                }


                try {
                    this.message = {
                        show: true,
                        loading: true,
                        persistent: true,
                        title: "Copying Property"
                    }

                    const newPropertyId = await this.$store.dispatch('copyProperty', item);
                    // Wait for 3 seconds to give the new property time to be created.
                    await new Promise(resolve => setTimeout(resolve, 3000));

                    this.getProperties();

                    this.message.show = false;

                    return newPropertyId;
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            transferProperty(item){
                alert("transfer property is not working yet")
                //TODO: make this work with apimixin
                // console.log("transferProperty", item)
                // this.item = item
                // this.$store.commit("setShowConfirm",true)
                // let message = "Are you sure that you want to transfer this property"
                // if ( this.moveGuestInfo ) { message += " and guest data" } else { message += " without guest data" }
                // message = message + " to " + this.newUid + "?"
                // this.$store.commit("setConfirmMessage", message)
            },
            goToProperty(prop) {
                const url = this.appSite + "login/" + prop.propertyId
                // console.log("opening property URL", url)
                window.open(url,"", "width=900, height=812");
            },
            showPropertyLink(prop) {
                // this.message = {...this.linkMessage}
                this.message = {
                    show: true,
                    title: "Link Copied",
                    prop: prop,
                    width:450,
                    link: `${this.$store.getters.appSite}${prop.propertyId}`,
                    slotName: 'link',
                    transition: "dialog-bottom-transition",
                }
                this.copyLink(this.message.link)
                console.log('show property link', prop)
                setTimeout(() => {
                    this.message = this.closeMessage
                }, 3000);
            },
            showPropertyQRCode(prop) {
                // this.message = {...this.linkMessage}
                this.message = {
                    show: true,
                    title: "Guidebook QR Code",
                    prop: prop,
                    width:450,
                    link: `${this.$store.getters.appSite}${prop.propertyId}`,
                    slotName: 'qrcode',
                    transition: "dialog-bottom-transition",
                    actions: [
                        {type: "spacer"},
                        {label: "ok", clickEmit: "cancel"}, 
                    ]
                }
                this.copyLink(this.message.link)
                // console.log('show property link', prop)
            },
            showInterview(propertyId) {
                console.log("showInterview...", propertyId)
                this.$store.commit("toggleTrigger")
                this.message = {
                    show: true,
                    page: 0,
                    message: '',
                    propertyId: propertyId,
                    width:564,
                    slotName: 'interview',
                    transition: "dialog-bottom-transition",
                    persistent: true,
                    trigger: this.$store.getters.trigger,
                    actions: [ 
                        {type: "spacer"},
                        // {label: "Next", clickEmit: "nextInterview", plain:true, iconImg:"mdi-arrow-right"}, 
                    ]
                }
            },
            showTransferProperty(propertyId) {
                console.log("transferProperty", propertyId)
                this.message = {
                    show: true,
                    title: "Transfer Property",
                    message: 'You will need the new owner\'s ID and the new organizations ID to transfer this property',
                    propertyId: propertyId,
                    width:550,
                    slotName: 'transferProp',
                    transition: "dialog-bottom-transition",
                    persistent: false,
                    actions: [ 
                        {type: "spacer"},
                        // {label: "Next", clickEmit: "nextInterview", plain:true, iconImg:"mdi-arrow-right"}, 
                    ]
                }
            },
            goToPreview(propertyId) {
                console.log("goToPreview")
                if ( !this.$store.getters.appSettings.showPreviewLink ) {
                    alert("Preview of new features is not currently available") 
                    return
                }
                let previewLink = this.$store.getters.appSettings.previewLink
                window.open(`${previewLink}/login/${propertyId}`, "", "width=900, height=812");
            },
            closeTransfer(){
                this.message.show = false
            },
            toWords: function(number) {
                let word = ""
                let numberWords = {
                    1: "One",
                    2: "Two",
                    3: "Three",
                    4: "Four",
                    5: "Five",
                    6: "Six",
                    7: "Seven",
                    8: "Eight",
                    9: "Nine",
                    10: "Ten",
                    11: "Eleven",
                    12: "Twelve",
                    13: "Thirteen",
                    14: "Fourteen",
                    15: "Fifteen",
                }
                word = numberWords[number].toLowerCase() || number
                return word
            },
            copyLink(link) {
                navigator.clipboard.writeText(link);
            },
            async addProperty() {
                console.log("addProperty button pushed")
                const orgObj = this.orgObj
   
                let limit = this.propLimit
                limit = (typeof limit ===  'string') ? parseInt(limit, 10) : limit
                
                let count = ('propCount' in orgObj) ? orgObj.propCount : 0;

                let orgId = orgObj.orgId
                let uid = this.user.uid
                let orgName = orgObj.name


                if (count < limit) {
                    this.message={
                        show: true,
                        loading: true,
                        persistent: true,
                        title: "Adding Property"
                    }
                    this.loading=true
                    this.propertyId = await this.apiMakeNewProperty(orgId, uid, orgName).then(res => {
                        console.log("res", res.propertyId)
                        this.$emit('addProperty', true)
                        this.message= {
                            show: false,
                        }
                        // TODO: check to see if showInterview on a watcher for propertyId is better
                        // setTimeout(() => {this.showInterview(res.propertyId)}, 1000)
                        // this.showInterview(res.propertyId)
                        return res.propertyId
                    })

                    this.loading=false

                    // getProperties() after 2 seconds
                    setTimeout(() => {this.getProperties()}, 2000)

                    //sends an email to a admins
                    const sendToArr = ['tom@everhost.io']
                    this.sendEmail(orgName, orgId, uid, sendToArr)
                    //goto route interview with new propertyId
                    // this.$router.push(`/interview/${propertyId}`)
                    console.log("show interview for propertyId", this.propertyId)

                } else {
                    this.message = {...this.maxPropertiesMessage}
                }
            },
            sendEmail(orgName, orgId, uid, sendToArr){
                try {

                    let mailObj = {
                        to: sendToArr,
                        subject: "Everhost property initiated by " + orgName,
                        html: `
                            <html>
                            <body>
                            A new property was created for ${orgName} by ${uid}<br>
                            Orgid: ${orgId}<br>
                            </body>
                            </html>
                        `
                        }

                        console.log("property created email", mailObj)
                        this.$store.dispatch('sendMail',mailObj)
                    
                    } catch (error) {
                        console.log("error", error)
                    }
            },
            async getProperties(nextPage=false) {
                // console.log("getProperties", nextPage)
                this.loading = true
                this.endOfData = false

                let search = this.search
                if (search === "") {search = null}

                let where = [['deletedAt','==',null]]

                if (this.orgObj) {
                    where.push(['orgId','==',this.orgObj.orgId])
                }

                const payload = {
                    nextPage: nextPage,
                    search: search,
                    where: where,
                    snapshot: true,
                    storedArray: 'properties',
                    orderBy: [['createdAt', 'desc']],
                    limit: 10
                }
                this.apiGetDocs('properties',payload).then((data)=>{
                    if (data.length == 0) {
                        this.endOfData=true
                        }
                    this.loading= false
                })
            },
            nextInterview(){
                console.log("nextInterview", this.message.formData)
                this.updateProperty()
                this.message.page++
            },
            closeInterview(){
                this.message.show = false
                this.message.page = 0
                this.message.formData = {}
            },
            //TODO: this is a duplicate of the one in ehc-interview  
            updateProperty: async function(){
            let obj = {}
            let formData = this.message.formData
            let propertyId = this.propertyId || formData.propertyId
            if (formData.name) {obj.name = formData.name}
            if (formData.avatarImg) {obj.avatarImg = formData.avatarImg}
            if (formData.mapAddress) {obj.mapAddress = formData.mapAddress}
            if (formData.unit_of_temperature) {obj.unit_of_temperature = formData.unit_of_temperature}
            if (formData.backgroundColor) {obj.backgroundColor = formData.backgroundColor}
            if (formData.noguestinfo) {obj.noguestinfo = formData.noguestinfo}
            if (formData.bookagain) {obj.bookagain = formData.bookagain}

            await this.apiUpdateProperty( propertyId, obj )
            console.log("updated property: ", obj)
            },
            showAnalytics(propertyId) {
                console.log("showAnalytics...", propertyId)
                // this.$store.commit("toggleTrigger")
                this.message = {
                    show: true,
                    page: 0,
                    title: "Analytics",
                    message: '',
                    propertyId: propertyId,
                    width:564,
                    slotName: 'analytics',
                    transition: "dialog-bottom-transition",
                    persistent: true,
                    // trigger: this.$store.getters.trigger,
                    actions: [ 
                        {type: "spacer"},
                        // {label: "Next", clickEmit: "nextInterview", plain:true, iconImg:"mdi-arrow-right"}, 
                    ]
                }
            },
            closeAnalytics(){
                this.message = {}
            },

        },
        //TODO: this doesnt work well -- Grant fix
        beforeDestroy() {
            console.log("beforeDestroy ehc properties")
            try {
                this.apiUnsub.forEach((unsub) => {
                    unsub()
                })
            } catch (error) {
                console.log("something went wrong unsubscribing")
            }
        },
    }


</script>



<style>


.qrcode canvas {
    width: 100% !important;
    height: auto !important;
}

</style>