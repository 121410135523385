<template>
  <!--convert to download dialog-->
  <ehc-page v-if="userHasPermission(['superAdmin'])">


    <ehc-header 
      text="Users" 
      :actions="headerActions" 
      @create="message = {...createNewUser}"
      @promote="showPromote = !showPromote" 
      @indexUsers="message = {...confirmIndexDialog}"
      @downloadUsers="handleDownloadUsers()">
      
      </ehc-header>



    <ehc-sheet>
      <ehc-table 
        :loading="loading"
        :headers="headers" 
        :items="users" 
        :selectable="true"
        @addPropertyToUser="message = { ...addPropertyToUserMessage, user: $event}"
        @editPermissions="message={...editPermissionsMessage, user: $event, title: 'change permissions', message: 'permissions for ' + $event.email}" 
        @viewOrg="viewOrg($event)"
        pagination="infinite"
        @bottomButton="getUsers(true)"
        @intersectBottom="getUsers(true)"
        :endOfData="endOfData"
        >

      </ehc-table>
    </ehc-sheet>

    <ehc-message 
      v-model="message" 
      @cancel="message ={...closeMessage}" 
      @savePermissions="editPermissions(message.user)"
      @indexUsers="indexUsers()"
      @createUser="submitNewUser()"
      >

        <template #downloadUsers>
          <div class="d-flex justify-center">
            <vue-json-to-csv 
                :json-data="message.userData"
                csv-title="EH Users" 
                @success="val => handleDownloadUsersSuccess(val)"
                >
              <v-btn
                class="mx-2 "
                small
                color="primary"
              >
                download
              </v-btn>
          </vue-json-to-csv> 
        </div>
      </template>

      <template #editPermissions>
        <v-switch 
          v-for="(option, index) in permissionOptions" 
          :key="index" 
          v-model="message.user.permissions"
          :label="option" 
          :value="option" 
          small 
          dense>
        </v-switch>
      </template>

      <template #createNewUser>
        <ehc-form 
          dense
          :meta="newUserMeta" 
          :shakeInvalid="message.shakeInvalid"
          v-model="message.userData" 
          ></ehc-form>
      </template>

      <template #addPropertyToUser>
        <span style="font-size: .8em;">This feature is in 'beta'. Be sure you enter a valid propertyId<br /></span>
        Add an existing property to {{ message.user.email }}:&nbsp;

        <v-text-field v-if="!newPropertyMessage" v-model="newPropertyId" label="New Property Id"
          :append-outer-icon="newPropertyId ? 'mdi-send' : ''" @click:append-outer="transferProperty(message.user)">
        </v-text-field>
        <v-checkbox v-if="!newPropertyMessage" v-model="moveGuestInfo" label="Transfer guest data also"
          :value=moveGuestInfo class="my-1"></v-checkbox>
        <p v-if="newPropertyMessage">{{ newPropertyMessage }}</p>
      </template>

    </ehc-message>
  </ehc-page>
</template>

<script>
import mixins from '@/mixins'
import auth from '@/mixins/auth'
import ehcAlertConfirm from '@/components/ehc-alert-confirm'
import VueJsonToCsv from 'vue-json-to-csv'
// import EhcDownloadButton from '@/components/ehc-download-button.vue'


export default {
  name: "users",
  mixins: [mixins, auth],
  components: {
    'ehc-alert-confirm': ehcAlertConfirm,
    'vue-json-to-csv': VueJsonToCsv,
  },
  data: function () {
    return {
      message: {
        show: false,
      },
      addPropertyToUserMessage: {
        show: true,
        width: "700px",
        title: "BETA: add existing property to user",
        slotName: "addPropertyToUser"
      },
      createNewUser: {
        show: true,
        slotName: "createNewUser",
        title: "Create New User",
        userData: {},
        shakeInvalid: false,
        actions: [
            { label: "Cancel", clickEmit: "cancel", dark: false, plain: true },
            { type: 'spacer' },
            { label: "Create User", clickEmit: "createUser" },
        ]
      },
      downloadUsers: {
        show: true,
        slotName: "null",
        title: "Download Users",
        userData: [],
        shakeInvalid: false,
        loading: true, 
        actions: [
            { label: "Cancel", clickEmit: "cancel", dark: false, plain: true },
            { type: 'spacer' },
        ]
      },
      editPermissionsMessage: {
        width: 400,
        slotName: 'editPermissions',
        message: null,
        show: true,
        actions: [
          { label: "Cancel", clickEmit: "cancel", dark: false, plain: true },
          { type: 'spacer' },
          { label: "Save Permissions", clickEmit: "savePermissions" },
        ]
      },
      confirmIndexDialog: {
        show: true,
        message: "are you sure you want to index all users, this will take a while",
        title: "index users",
        actions: [
          { label: "Cancel", clickEmit: "cancel", dark: false, plain: true },
          { type: 'spacer' },
          { label: "Index all users", clickEmit: "indexUsers" },
        ]
      },
      closeMessage: {
        show: false,
      },
      loading: false,
      search: null,
      showPromote: false,
      newPropertyId: null,
      newPropertyMessage: null,
      moveGuestInfo: true,
      item: {},
      formData: {},
      newUserFormData: {},
      meta: [
        { type: "text", label: "UserId", key: "uid" },
        { type: "button", label: "submit", key: "submit", emitOnClick: "submit" },
      ],
      newUserMeta: [
        { type: "email", label: "email", key: "email", required: true },
        { type: "password", label: "password", key: "password", required: true},
      ],
      users: [],
      endOfData: false
    }
  },
  watch: {
    gcvDebouncedSearchString(val) {
      console.log("gcvDebouncedSearchString", val)
      this.users=[]
      this.endOfData=false
      this.apiVars.getUsers = {
        lastDoc: null,
        lastQuery: null
      }
      this.getUsers()
    }
  },
  methods: {
    async handleDownloadUsers() {
      this.message = {...this.downloadUsers}
      this.message.title = "Loading users..."

      let payload = {
        search: this.gcvDebouncedSearchString,
      }
      await this.apiGetDocs('users',payload).then((resp)=>{
        this.message.userData = resp.map( el => {
          return {
            Email: el.email,
            'Display Name': el.displayName,
            'First Name': el.firstName ? el.firstName : '',
            'Last Name': el.lastName ? el.lastName : '',
            'User Id': el.uid,
            Created: this.formatDate(el.createdAt,"dateOnly"),
          }
        })
        this.message.loading = false
        this.message.slotName="downloadUsers"
        this.message.actions = [
            { label: "close", clickEmit: "cancel", dark: false, plain: true },
            { type: 'spacer' },
        ]
      })
      this.message.title = "Users loaded"
    },
    handleDownloadUsersSuccess(val) {
      this.message.title = "Downloading..."
      setTimeout(()=>{
        this.message.show = false
      },2000)
    },
    getUsers(nextPage=false) {
      this.loading = true

      let payload = {
        search: this.gcvDebouncedSearchString,
        limit:20,
        nextPage: nextPage
      }

      this.apiGetDocs('users',payload).then((resp)=>{
        if (resp.length == 0) {this.endOfData=true}
        this.users = [...this.users, ...resp]
        this.loading = false
      })
    },
    indexUsers() {
      console.log("indexUsers")
      this.message.persistent = true
      this.message.loading =  true
      this.message.actions = null

      this.apiGetDocs('users').then(async (users)=>{
        // console.log(users)
        let index = []
        for (var i=0; i < users.length; i++){
          console.log(users[i], i)
          await this.apiGenerateIndex(users[i], ['displayName', 'email', 'permissions', 'uid']).then(async (index)=>{
            return await this.apiUpdateUser(users[i].uid, {searchIndex: index}).then((resp)=>{
              this.message.message = "indexing user number: "  + i
              return true
            })
          })
        }
        this.message = {
          show: true,
          title: "Finished indexing",
          message: "successfully indexed " + i + " users",
          actions: [{type:'spacer'},{ label: "OK", clickEmit: "cancel", }]
        }
      })

    },
    editPermissions(user) {
      if (!('permissions' in user)) {
        user.permissions = []
      }

      this.message = {
        title: "Applying to " + user.displayName,
        show: true,
        loading: true,
        width: "400px",
        persistent: true,
        message: "Applying Permissions...",
      }

      this.apiUpdateUser(user.uid, { permissions: user.permissions }).then((resp) => {
        this.message = this.closeMessage
      })

    },
    async viewOrg(user) {

      this.apiGetOrgFromUser(user.uid).then((resp) => {
        this.$router.push({ name: 'organization', params: { id: resp.id } })
      })
    },

    //TODO: add validation in firebase functions
    submitNewUser: async function () {
      this.message.loading = true
      const userData = this.message.userData
      let obj = {
        'email': userData.email,
        'password': userData.password
      }
      if (!userData.email || !userData.password) {
        this.message.alert={
          type: "error",
          text: "Please fill in all fields",
        }
        this.message.shakeInvalid = true
        this.message.loading = false
        return
      }
      if (obj.password.length < 6) {
        this.message.alert={
          type: "error",
          text: "Password must be at least 6 characters long",
        }
        this.message.shakeInvalid = true
        this.message.loading = false
        return
      }
      let that=this
      this.message = {
        title:"creating User",
        show: true,
        loading: true,
      }
      await this.apiCreateUserWithEmailAndPassword(obj).then((resp)=>{
        console.log(resp)
        this.message = {
          title: "Create New User",
          show: true,
          alert: {
            type: "success",
            text: "user created"
          },
          actions: [
            { type:"spacer"},
            { label: "Ok", clickEmit: "cancel" },
          ]
        }
      }).catch((error) =>{
        this.message = {
          ...this.createNewUser,
          alert: {
            type: "error",
            text: error.toString()
          }
        }
        console.log(this.message)
      })
      this.showNewUser = false
    },
    transferProperty(item) {
      // need to get new orgId and orgName
      console.log("transferProperty", item)
      this.$store.dispatch("updatePropertyUid", { uid: item.uid, propertyId: this.newPropertyId })
      .then((resp) => {
        alert("transfered property")
      })
      .catch(err => alert("error transfering property") )
      // this.item = item
      // this.$store.commit("setShowConfirm", true)
      // let message = "Are you sure that you want to transfer this property"
      // if (this.moveGuestInfo) { message += " and guest data" } else { message += " without guest data" }
      // message = message + " to " + item.uid + "?"
      // this.$store.commit("setConfirmMessage", message)
    },
    addPropertyToUser: async function (uid) {
      let propertyId = this.newPropertyId
      if (this.moveGuestInfo) { await this.$store.dispatch("moveGuestDataToUser", { uid: uid, propertyId: propertyId.trim() }) }
      return await this.$store.dispatch('doesPropertyExist', propertyId.trim())
        .then((response) => {
          if (!response) {
            this.newPropertyMessage = "Not a valid property id"
            setTimeout(() => {
              this.expanded = []
              this.newPropertyMessage = ""
              this.newPropertyId = null
            }, 3000)
          } else {
            this.$store.dispatch("updatePropertyUid", { uid: uid, propertyId: propertyId }).then(() => {
              this.newPropertyMessage = "Updated"
              setTimeout(() => {
                this.expanded = []
                this.newPropertyMessage = ""
                this.newPropertyId = null
              }, 3000)
            })
          }
        })
        .catch(err => {
          this.newPropertyMessage = err
        })
    },
  },
  computed: {
    permissionOptions() {
      return this.gcvAppSettings.permissionOptions
    },
    headerActions() {
      let actions = [
        ...this.userHasPermission(['superAdmin'], [{
          actionType: 'menu', icon: 'mdi-database-outline', items: [
            { type: 'link',  label: 're-index all users for search', emitClick: 'indexUsers' },          ]
          },
          { actionType: 'v-btn', icon: true, iconName: "mdi-download", clickEmit: "downloadUsers"},
        ], []),
        { actionType: 'v-btn', label: "create new user", color: "primary", dark: true, clickEmit: "create" },

      ]
      return actions
    },
    usersForDownload: function () {
      let usersForDownload = []
      let user = {}
      this.users.forEach(el => {
        user = {}
        user.ID = el.uid
        user.EMAIL = el.email
        user.NAME = el.displayName || ""
        user.CREATED = this.dateFormat(el.createdAt, "dateOnly")
        usersForDownload.push(user)
      })
      return usersForDownload
    },
    headers: function () {
      return [
        { text: 'Email', sortable: true, value: 'email' },
        {
          type: 'menu',
          icon: 'mdi-dots-vertical',
          items: [
            { type: 'link', label: 'View User Organization', emitClick: 'viewOrg' },
            { type: 'link', label: 'Edit Permissions', emitClick: 'editPermissions' },
            { type: 'link', label: 'Add Property To User', emitClick: 'addPropertyToUser' }
          ]
        },
        { text: 'User Id', value: 'uid' },
        { text: 'Display Name', value: 'displayName' },
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Permissions', value: 'permissions', type: 'chips' },
        { text: 'Created', value: "createdAtAsString", sortable: true },
      ]
    },
    // searchString: function () {
    //   return this.$store.getters.searchString
    // },
  },
  async created() {
    this.getUsers()
    await this.$store.dispatch("getUserOrg", this.$store.getters.user.uid)
    .then( (org) => console.log("got org", org))
    .catch( err => console.log("error getting org", err))  }
}

</script>

