<template>
    <ehc-dialog
      v-model="showAlert"
      width="500"
      :title="alert.title"
    >
        <v-card-text class="text-center">
        </v-card-text>

        <v-card-text class="">
          {{message}}
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name='confirm'></slot>
          <v-btn
            color="primary"
            text
            @click="showAlert = false"
          >
            Close
          </v-btn>
        </v-card-actions>
    </ehc-dialog>
</template>

<script>
export default {
  components: { },
  data() {
    return {
      show: true,
      dialog: false,
    }
  },
  computed: {
    showAlert: {
      get() {return this.$store.getters.showAlert},
      set(value) {this.$store.commit('setShowAlert',value)}
    },
    message: function(){
      return this.$store.getters.alertMessage
    },
    alert: function() {
      return this.$store.getters.alert
    }
  },
  methods: {
    confirmAction: function() {
      alert("confirm")
    }
  }
 
}
</script>