<template>
  <ehc-page>
    <div class="bg-images-grid">
      <div v-for="(bgImage, index) in bgImages" :key="index" class="bg-image pointer" @click="goToImage(bgImage.imageUrl)">
        <!-- You can display the image or other information here -->
        <v-img :src="bgImage.imageUrl" alt="Background Image" />
        <span>{{ bgImage.text }}</span>
      </div>
    </div>
  </ehc-page>
</template>


<script>
import apiMixin from '@/mixins/api.vue'

export default {
  mixins: [apiMixin],
  data: () => ({
    pageTitle: 'BackGroundImages',
    bgImages: [],
    firestoreLocation: 'https://firebasestorage.googleapis.com/v0/b/everhostio.appspot.com/o/backgroundImages%2F',
    firestoreToken: '?alt=media&token=26366178-eb35-46f4-9b0b-eecb0b4f2f0c&_gl=1*1fk9qow*_ga*MTgxODU4MjIxMS4xNjg2NjY3NjQz*_ga_CW55HF8NVT*MTY5NzIwNTI1OC4xMzQuMS4xNjk3MjA1MjczLjQ1LjAuMA',
  }), 
  methods: {
    async getBackgroundImages() {
      const bgImages = await this.apiGetBackgroundImages();

      this.bgImages = bgImages
        .filter((bgImage) => bgImage.active)
        .sort((a, b) => a.text.localeCompare(b.text))
        .map((bgImage) => ({
          ...bgImage,
          imageUrl: `${this.firestoreLocation}${bgImage.fileName}${this.firestoreToken}`,
        }));
      // console.log("Got background images", this.bgImages);
    },
    goToImage(imageUrl) {
      window.open(imageUrl, '_blank');
    },

  },

  created() {
    this.getBackgroundImages()
  },

}
</script>

<style>
.bg-images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.bg-image {
  text-align: center;
}

.bg-image img {
  max-width: 100%;
  max-height: 200px;
}
</style>

