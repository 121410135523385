<template>
    <div class="ehc-sheet mb-4" v-bind="$attrs" :style="'width: ' + width">
        <slot>

        </slot>
    </div>
</template>

<script>

export default {
    props: {
        width: {type: String, default: ""}
    },
    data() {
        return {

        }
    }
}

</script>

<style>
.ehc-sheet {
    max-width:100%;
    margin-top: 15px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    display: inline-block
}

</style>


