import { render, staticRenderFns } from "./ehc-property-card.vue?vue&type=template&id=656f48cf&scoped=true"
import script from "./ehc-property-card.vue?vue&type=script&lang=js"
export * from "./ehc-property-card.vue?vue&type=script&lang=js"
import style0 from "./ehc-property-card.vue?vue&type=style&index=0&id=656f48cf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "656f48cf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VImg,VListItem,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VTooltip})
