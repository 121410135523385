<!-- 
    example:
        <template>
            <ehc-form 
                v-model="formData" 
                :meta="meta" 
                :shakeInvalid="shakeVariable" - (optional) set to true to shake invalid inputs
                @submit="somefunction()"
                @valid="isValid = $event" - (optional) returns whether or not the form is valid
                />
        </template>
        <script>
            data() {
                return {
                    meta: [
                        {type: "text",          label: "name",                      key: "name"},
                        {type: "email",         label: "email",                     key: "email"},
                        {type: "textArea",      label: "Description of problem",    key: "description"},
                        {type: "subheader",     label: "subheader text"},
                        {type: "fileInput",     label: "upload screenshot(s)",      key: "upload",          multiple:true},
                        {type: "phoneNumber",   label: "Phone Number",              key: "phoneNumber"},
                        {type: "button",        label: "submit",                    key: "submit",          emitOnClick: "submit"},
                        {type: "switch",        label: "switch me",                 key: "isSwitched",      prependLabel: true,     required: true, errorMessage: "you must switch"}, //by default it just says "required" but errorMessage overrides that message
                        {type: "select",        label: "select one",                options: ["first opt", "second opt"], required: true, errorMessage: "you must select something"}            
                    ],
                }
            },
            methods: {
                somefunction() {
                    do.something.with(this.formData)
                }
            }
        </script>

        FIXME: currently when chrome autofills the form it overlaps the labels. figure out how to fix that. 
 -->


<template>
    <div class="mb-0">
        <v-container >
            <v-form 
                v-model="valid"
                ref="ehcForm"
                onSubmit="return false"
                >
            <slot></slot>
            <template v-for="(field, fieldIndex) in meta">
                <v-row :key="fieldIndex">

                <!-- TEXT -->
                    <v-text-field   ref='input'
                                    v-if="field.type == 'text'" 
                                    :color="color"
                                    :rules="inputRules(field)"
                                    outlined 
                                    :dense = dense
                                    :disabled = "field.disabled"
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})"
                                    >
                                    <template slot="label">
                                        <span v-html="field.label"></span>
                                    </template>
                                    </v-text-field>

                <!-- RICH TEXT EDITOR (ckeditor)                 -->
                    <ehc-editor 
                                    v-else-if="field.type === 'editor'"
                                    v-model="data[field.key]"
                                    class="wide"
                                    >{{field.label}}</ehc-editor>
                                    <!---No @blur or @change needed if we use a save button-->

                <!--  NUMBER -->
                    <v-text-field   ref='input'
                                    v-else-if="field.type == 'number'" 
                                    type="number"
                                    :color="color"
                                    :rules="inputRules(field)"
                                    outlined 
                                    :dense = dense
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    </v-text-field>

                <!-- EMAIL -->
                    <v-text-field   v-else-if="field.type == 'email'" 
                                    :rules="emailRules(field)"
                                    :color="color"
                                    outlined 
                                    :dense = dense
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    </v-text-field>   

                <!-- PASSWORD -->
                    <v-text-field   v-else-if="field.type == 'password'" 
                                    :rules="inputRules(field)"
                                    :color="color"
                                    :dense = dense
                                    outlined 
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    </v-text-field>    

                <!-- ALERT -->
                    <v-alert v-else-if="field.type == 'alert'" type="info" width="100%" dense>
                        {{field.label}}
                    </v-alert>

                <!-- SPACE -->
                    <p 
                        v-else-if="field.type == 'space'"
                        :class="('class' in field)? field.class : 'my-n1'" >&nbsp;
                    </p>
     
                <!-- MAPS AUTOCOMPLETE -->
                    <ehc-google-autocomplete 
                        v-else-if="field.type == 'mapsAutocomplete'" 
                        :label="field.label"
                        v-model="data[field.key]">
                    </ehc-google-autocomplete>                                                             

                <!-- INTERNATIONAL PHONE NUMBER -->
                    <ehc-int-phone-input
                                    v-else-if="field.type == 'intPhoneNumber'"
                                    v-model="data[field.key]" 
                                    :color="color"
                                    :dense = dense
                                    outlined
                                    v-bind="field"
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})"
                                    >
                                    </ehc-int-phone-input>                                                                      

                <!-- PHONE NUMBER -->
                    <ehc-phone-input   
                                    v-else-if="field.type == 'phoneNumber'"  
                                    :props="field"
                                    :color="color" 
                                    :dense = dense
                                    v-model="data[field.key]" 
                                    @demaskedOut="data[field.key] = $event"
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    </ehc-phone-input>

                <!-- TEXTAREA -->
                    <v-textarea   v-else-if="field.type == 'textArea'" 
                                    outlined
                                    :color="color" 
                                    :rules="inputRules(field)"
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    </v-textarea>

                <!-- LINK -->
                    <v-text-field   v-else-if="field.type == 'link'" 
                                    :rules="linkRules(field)"
                                    :color="color"
                                    outlined 
                                    :dense = dense
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @blur="onBlurLink(field.key, data[field.key])"
                                    @change="onChangeLink(field.key, data[field.key])">
                                    </v-text-field>

                <!-- RADIO BUTTONS GROUP -->
                    <v-radio-group  v-else-if="field.type == 'radio'"
                                    v-model="data[field.key]" 
                                    :color="color"
                                    v-bind="field">
                                    <template #label>{{field.label}}</template>
                                    <v-radio v-for="(radio, index) in field.radio" v-bind="radio" :key="index">
                                    </v-radio>
                    </v-radio-group>

                <!-- FILE INPUT -->
                    <ehc-file-input   
                                    :color="color" 
                                    v-else-if="field.type == 'fileInput'" 
                                    v-model="data[field.key]" 
                                    :props="field"></ehc-file-input>

                <!-- IMAGE UPLOAD -->
                    <ehc-form-image-upload
                        v-else-if="field.type == 'imageUpload'"
                        v-model="data[field.key]" 
                        :remove="field.remove"
                        :field="field" 
                        :class="('labelClass' in field)? field.labelClass : 'text-h6'" 
                        v-on="$listeners"
                        >
                    </ehc-form-image-upload>

                <!-- SELECT -->
                    <v-select
                        v-else-if="field.type === 'select'"
                        v-model="data[field.key]"
                        :color="color"
                        :items="field.options"
                        :label="field.label"
                        outlined
                        :dense = dense
                        :rules="inputRules(field)"
                        @change="$emit('change', {key: field.key, value: data[field.key]})">
                    ></v-select>   

                <!-- SELECT PROPERTY TYPES -->
                    <ehc-form-property-types
                        v-else-if="field.type === 'selectPropertyTypes'"
                        v-model="data[field.key]"
                        v-bind="field"
                        :type="data.type" 
                        :options="field.options"
                        @select="$emit('select', $event)"
                        @change="$emit('change', {key: field.key, value: data[field.key]})"
                        />  

                <!-- SWITCH -->

                    <div v-else-if="field.type === 'switch'" style="width:100%;height:40px">
                            <span class="text-h6 font-weight-bold float-left mt-4" v-if="field.prependLabel">{{field.label}}</span>  
                            <span class="float-right">
                            <v-switch
                                :dense = dense
                                class="float-right"
                                color="green darken-2"
                                v-bind="field"
                                v-model="data[field.key]"
                                :rules="inputRules(field)"
                                :label="!field.prependLabel ? field.label : ''"
                                @change="$emit('change', {key: field.key, value: data[field.key]})">
                            ></v-switch>                    
                            </span>
                    </div>

                <!-- SUBMIT -->
                    <ehc-btn
                        v-else-if="field.type === 'submit'"
                        color = 'button'
                        dark 
                        block
                        @click="$emit('submit')"
                        >
                        <strong>
                        {{field.label}}
                        </strong>
                    </ehc-btn> 

                <!-- BUTTON -->
                    <ehc-btn
                        v-else-if="field.type === 'button'"
                        :color="(field.color) ? field.color : color"
                        block
                        dark
                        v-bind="field"
                        @click="$emit(field.emitOnClick)"
                        >
                        {{field.label}}
                    </ehc-btn> 

                <!-- SLOT -->
                    <slot v-else-if="field.type === 'slot'" :name="field.slotName" :data="data[field.key]" :field="field"></slot>

                <!-- SUBHEADER -->
                    <v-subheader 
                        v-else-if="field.type === 'subheader'" 
                        :class="('class' in field)? field.class : 'text-h6 mb-5 text-center'">
                        {{field.label}}
                    </v-subheader>   

                <!-- HEADER -->
                    <div v-else-if="field.type === 'header'">
                        <p 
                            v-if="field.label" 
                            :class="('labelClass' in field)? field.labelClass : 'text-h6 font-weight-bold mb-9'" 
                            v-html="field.label" />
                        <p 
                            v-if="field.instructions" 
                            :class="('instructionsClass' in field)? field.instructionsClass : 'text-body-1 font-weight-regular mb-1'" 
                            v-html="field.instructions" />
                    </div>    

                <!-- DIVIDER -->    
                    <v-divider v-else-if="field.type === 'divider'" v-bind="field" :class="field.class">{{field.label}}</v-divider>   
                                                               
                </v-row>
            </template>
            </v-form>
        </v-container>

    </div>
</template>

<script>
    import EhcFileInput from './ehc-file-input.vue'
    import EhcPhoneInput from './ehc-phone-Input.vue'
    import EhcIntPhoneInput from './ehc-int-phone-input.vue'
    import EhcFormImageUpload from '@/components/global/ehc-form/ehc-form-image-upload.vue'
    import EhcGoogleAutocomplete from '@/components/global/ehc-form/ehc-google-autocomplete.vue'
    import EhcFormPropertyTypes from '@/components/global/ehc-form/ehc-form-property-types.vue'
    // import EhcSwitch from '@/components/global/ehc-form/ehc-switch.vue'


    export default {
        mixins: [],
        props: {
            meta: Array,
            value: Object,
            shakeInvalid: {type: Boolean, default: false},
            dense: {type: Boolean, default: false},
            color: {type: String, default: "black2"},
        },
        components: {
            EhcFileInput,
            EhcPhoneInput,
            EhcIntPhoneInput,
            EhcFormImageUpload,
            EhcGoogleAutocomplete,
            EhcFormPropertyTypes,
            // EhcSwitch,
        },
        data: () => ({
            data: {},
            valid: null
        }),
        created () {
            this.data = this.value
        },
        async mounted() {
 
        },          
        watch: {
            shakeInvalid() {
                this.$refs.ehcForm.validate()
            },
            valid(val) {
                this.$emit('valid', val)
            },
            value() {
                this.data = this.value
            },
            data(val) {
                this.$emit('input', val)
            }
        },
        computed: {

        },
        methods: {
            // rules
            emailRules(field) {
                let rule = []
                let errorMessage = (field.errorMessage) ? field.errorMessage : "required"
                if (field.required) { rule.push(v => !!v || errorMessage)}
                rule.push(v => /(.+@.+\..+)|^(?![\s\S])/.test(v) || 'Must be valid Email')

                return rule
            },
            inputRules(field) {
                let rule = []
                let errorMessage = (field.errorMessage) ? field.errorMessage : "required"
                if (field.required) { rule.push(v => !!v || errorMessage)}

                return rule
            },
            linkRules(field) {
                let rule = []
                let errorMessage = (field.errorMessage) ? field.errorMessage : "required"
                if (field.required) { rule.push(v => !!v || errorMessage)}
                rule.push(v => /^$|null|([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)|^(?![\s\S]))/.test(v) || 'Must be valid link (including https:// or http://)')

                return rule
            },
            onBlurLink(key,newValue){
                this.$emit('blur', {key: key, value: newValue})
            },
            onChangeLink(key,newValue){
                this.$emit('change', {key: key, value: newValue})
            },
        },
    }
</script>


