<template>
    <div class="ehc-card">
        <slot></slot>

    </div>
</template>


<script>

export default {
    data() {
        return {

        }
    }
}

</script>


<style>

    .ehc-card {

    }


</style>