<script>
export default {
  computed: {
    //fields array used in setup form
    fields: function(){
      let fields = 
      [
        //PROPERTY TYPE
        [
          {
            type: "header", 
            label: "Choose Your Property Type", 
            labelClass: this.isSmallScreen ? "text-subtitle-1 font-weight-bold mb-1" : "text-h6 font-weight-bold mb-7",
            instructions: "Adding a property is quick and easy. Before editing your welcome book & area guide let's do some basic setup", 
            // instructionsClass: this.isSmallScreen ? 'text-subtitle-2 font-weight-regular mb-1' : 'text-subtitle-1 font-weight-regular mb-3',
            breakPoint: 800,
          },
          {
            type: "selectPropertyTypes", 
            title:"",
            instructions: "",
            options: [
              {value: "house", label: "House", icon: "House@3x.svg", selected: this.isSelected('house') ? true : false},
              {value: "apartment", label: "Apartment", icon: "Building@3x.svg", selected: this.isSelected('apartment') ? true : false},
              {value: "hotel", label: "Hotel", icon: "Hotel@3x.svg", selected: this.isSelected('hotel') ? true : false},
              {value: "resort", label: "Resort", icon: "Resort@3x.svg", selected: this.isSelected('resort') ? true : false},
              {value: "hostel", label: "Hostel", icon: "Hostel@3x.svg", selected: this.isSelected('hostel') ? true : false},
              {value: "camp", label: "Camp/Glamp", icon: "Camp@3x.svg", selected: this.isSelected('camp') ? true : false},
              {value: "carRent", label: "Car Rent", icon: "car-rent@3x.svg", selected: this.isSelected('carRent') ? true : false},
              {value: "camper", label: "Camper/RV", icon: "Camper@3x.svg", selected: this.isSelected('camper') ? true : false},
              {value: "tourism", label: "Tourism", icon: "Tourism.svg", selected: this.isSelected('tourism') ? true : false},
              {value: "attraction", label: "Attraction", icon: "Attraction.svg", selected: this.isSelected('attraction') ? true : false},
              {value: "events", label: "Events", icon: "Events.svg", selected: this.isSelected('events') ? true : false},
              {value: "other", label: "Other", icon: "Others@3x.svg", selected: this.isSelected('other') ? true : false},
            ],
          },
        ],

        //PROPERTY NAME AND IMAGE
        [
          {
            type: "header", label: "Property Name & Image", instructions: "A catchy or memorable name for your property in 26 characters or less.",
          },
          { type: "space" },
          {
            type: "text",
            label: "Property Name",
            key: "name",
            maxCharacters: 26,
            counter: 26,
            required: true,
          },
          { type: "space" },
          {
            type: "switch",
            key: "showName",
            label: "Show Property Name",
            prependLabel: true,
            defaultValue: true,
          },
          { type: "space" },
          {
            type: "header", instructions: "Property name will be shown on your homepage unless you hide it.",
          },
          {
            type: "space",
          },
          {
            type: "space",
          },
          {
            type: "space",
          },
          {
            type: "imageUpload",
            label: "Upload Property Image or Logo",
            labelClass: "text-h6 mt-15 font-weight-bold",
            key: "avatarImg",
            uploadPath: `/propertyPictures/${Date.now()}avatarImg`,
            circle: true,
            size: {width: 200, height: 200},
            required: false,
            showInForm: false,
            remove: false,
            confirmRemoveMessage: 'Are you sure you want to remove this custom logo?',
          },
        ],

        //ADDRESS
        [
          {
            type: "header", label: "Property Address",
          },
          {
            type: "mapsAutocomplete",
            label: "Start typing property's address",
            key: "mapAddress",
          },
          { type: "space" },
          {
            type: "switch",
            key: "showAddress",
            label: "Show Address",
            prependLabel: true,
            defaultValue: true,
          },
          { type: "space" },
          {
            type: "header", instructions: "Street name will be shown under Property Name, unless hidden.",
          },
          { type: "space", class: "nt-1",},
          { 
            type: `${this.showAddress ? 'hidden' : 'text'}`,
            key: "subtitle",
            maxCharacters: 30,
            maxlength: 30,
            counter: 30,
            label: '<b>Optional</b> - subtitle will replace street name',
          },
          { 
            type:"space"
          },
        ],

        //HOST NAME AND IMAGE
        [
          {
            type: "header", label: "Host Name and Image",
          },
          {
            type: "text",
            label: "Host Name",
            key: "contactInfo_hostName",
          },
          { type: "space" },
          {
            type: "imageUpload",
            label: "",
            key: "contactInfo_hostPhotoUrl",
            uploadPath: `/propertyPictures/${Date.now()}hostImg`,
            placeholderIcon: "mid-account-circle",
            circle: true,
            size: {width: 200, height: 200},
            required: false,
            showInForm: false,
            remove: false,
            confirmRemoveMessage: 'Are you sure you want to remove this custom logo?',
          },
        ],

        //PLATFORM
        [
          {type: "header",   label: "Platform Messaging", instructions: "Guest may contact the host using the following platforms. More options coming soon."},
          {type: "switch",      label: "Airbnb",  prependLabel: true,            key: "contactInfo_arbnbBool", defaultValue: false},
          {type: "switch",      label: "VRBO",    prependLabel: true,            key: "contactInfo_vrboBool", defaultValue: false},
        // {type: "switch",      label: "Home Away",       key: "contactInfo_homeAwayBool", defaultValue: false},
        // {type: "switch",      label: "Booking.com",     key: "contactInfo_bookingBool", defaultValue: false},
          {type:"space"},
        ], 

        //HOST CONTACT INFO
        [
          {type: "header", label: "Host Contact", instructions: ""},
          {type: "intPhoneNumber", label: "Calling Number",    key: "contactInfo_callingNumber"},
          {type: "intPhoneNumber", label: "SMS Number",        key: "contactInfo_smsNumber"},
        ],

        //WEATHER SETTING
        [
          {
            type: "header", instructions: "Show weather as Fahrenheit or Celsius", label: "Weather Setting",
          },
          { type: "space" },
          {
            type: "select",
            label: "Show temperature as:",
            options: [
              { 
                text: "Fahrenheit",
                value: "imperial"
              },
              { 
                text: "Celsius",
                value: "metric"
              }
            ],
            key: "unit_of_temperature",
          },
        ],

        //BACKGROUND AND WELCOME PAGE
        [
          {
            type: "header", label: "Background Theme and Guest Welcome Page",
          },
          {
            type: "switch",
            key: "showSplashScreen",
            label: "Show Welcome Page",
            prependLabel: true,
            defaultValue: true,
          },
          { type: "space" },
          // {
          //   type: "header", instructions: "A guest welcome page will display unless you hide it.",
          // },
          { type: "space" },
          { 
            type: `${this.showSplashScreen ? 'text' : 'hidden'}`,
            key: "welcomeText",
            maxCharacters: 40,
            maxlength: 40,
            counter: 40,
            label: '<b>Optional</b> - replaces default Welcome to "City Name".',
          },

          { type: "space" },
          {
            type: "header", 
            label: "Background Theme", 
            instructions: "Select a background theme for your guidebook",
            labelClass: "text-h6 font-weight-bold mb-1"
          },
          { type: "space" },
          {
            type: "select",
            label: "Select a Background",
            key: "backgroundColor",
            options: this.bgImages,
          },
        ],

        //GUEST INFO
        [
          {
            type: "header", label: "Guest Information Form", instructions: "Collect and store guest data such as name, email, phone number.",
          },
          { type: "space" },
          {
            type: "select",
            label: "Guest info...",
            options: [
              { 
                text: "Do not require guest info. form",
                value: true
              },
              { 
                text: "Require guest info. form on first use",
                value: false
              }
            ],
            key: "noguestinfo",
          },
        ],

        //BOOK AGAIN
        [
          {
            type: "header", label: "Book Again", instructions: "Get more bookings by adding a Book Again link! Airbnb, VRBO, direct booking pages, etc...",
          },
          { type: "space" },
          {
            type: "header", instructions: "<strong>No link?</strong> <br/> Choose next and skip.",
          },
          { type: "space" },
          {
            type: "link",
            label: "Book Again Link (must be a valid link, including https:// or http://)",
            key: "bookagain",
          },
        ],

       //QR Code
        [
          {
            type: "header", 
            label: "QR Code", 
            instructions: "Download QR code, print out, or use the digital version.  Make sure to display this QR code in your space or as an image with your listing pictures.  Don't forget to publish your digital property guide first!",
          },
          { type: "space" },
          {
            type: "slot",
            slotName: "qrcode",
            qrcode: "https://www.easyhostconcierge.com",
          },
        ],

        //WELL DONE
        [
          {
            type: "header", 
            label: "Well Done!", 
            instructions: `You can now view your welcome book & area guide. Next take some time to update and manage page content such as text, images and videos. We've added some default content to get you started. <br/><br/><span class="text-h6">Need Help?</span><br/> Checkout our short <a href="https://www.youtube.com/watch?v=KK0GMKyl0zM&list=PLYpLQUDA_fMb9zo-hIXgqCVBROsJVrWtq" target="_new">Training Videos <img src="${this.linkOutIcon}" /></a>. `,
          },
        ],

      ]

      return fields
    },
  }
}
</script>
