import { render, staticRenderFns } from "./Organizations.vue?vue&type=template&id=7e0a5760&scoped=true"
import script from "./Organizations.vue?vue&type=script&lang=js"
export * from "./Organizations.vue?vue&type=script&lang=js"
import style0 from "./Organizations.vue?vue&type=style&index=0&id=7e0a5760&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e0a5760",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VIcon,VList,VListItem,VListItemTitle,VMenu})
