var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ehc-sheet',[_c('ehc-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"items":_vm.properties,"pagination":"infinite"},scopedSlots:_vm._u([{key:"guestInfo",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({},'v-list-item-icon',attrs,false),on),[(!item.noguestinfo)?_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/Seen@3x.svg'),"small":""},on:{"click":function($event){return _vm.setGuestInfo(item)}}}):_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/Close.svg'),"small":""},on:{"click":function($event){return _vm.setGuestInfo(item)}}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.noguestinfo ? 'No guest info' : 'Guest info'))])])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticClass:"mx-0"},'v-list-item-icon',attrs,false),on),[_c('v-img',{staticClass:"mr-2 pointer",attrs:{"src":require('@/assets/icons/edit@3x.svg'),"small":""},on:{"click":function($event){return _vm.$emit('editProperty', item)}}})],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticClass:"mx-0"},'v-list-item-icon',attrs,false),on),[_c('v-img',{staticClass:"mr-2 pointer",attrs:{"src":require('@/assets/icons/Copy@3x.svg'),"small":""},on:{"click":function($event){return _vm.copyProperty(item)}}})],1)]}}],null,true)},[_c('span',[_vm._v("Copy")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticClass:"mx-0"},'v-list-item-icon',attrs,false),on),[(!item.publishedAt)?_c('v-img',{staticClass:"mr-2 pointer",attrs:{"src":require('@/assets/icons/unpulish@3x.svg'),"small":""},on:{"click":function($event){return _vm.$emit('publishProperty',item)}}}):_c('v-img',{staticClass:"mr-2 pointer",attrs:{"src":require('@/assets/icons/unpulish@3x.svg'),"small":""},on:{"click":function($event){return _vm.$emit('publishProperty',item)}}})],1)]}}],null,true)},[_c('span',[_vm._v("Publish")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticClass:"mx-0"},'v-list-item-icon',attrs,false),on),[_c('v-img',{staticClass:"mr-2 pointer",attrs:{"small":"","src":require('@/assets/icons/trash@3x.svg')},on:{"click":function($event){return _vm.$emit('deleteProperty',item)}}})],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])},'ehc-table',_vm.$attrs,false),_vm.$listeners))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }