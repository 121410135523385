<template>
    <ehc-page>
        <ehc-properties 
            :orgObj="{...gcvOrg, orgId: gcvOrgId}"
            @addProperty="refreshOrg()"
            @deleteProperty="refreshOrg()"
            :search="gcvDebouncedSearchString"/>
    </ehc-page>
</template>


<script>
import ehcProperties from '@/components/properties/ehc-properties.vue'

export default {
    components: {ehcProperties},
    data() {
        return {

        }
    },
    mounted () {
        this.gcvSearchString = null
    },
    methods: {
        refreshOrg() {
            this.$store.dispatch('getOrg')
        }
    }

}
</script>

<style>


</style>