<template>
    <v-list nav dense class="ehc-menu">
        <template v-for="(item, index) in items" >

            <v-divider 
                v-if="item.type == 'divider'" 
                :key="item.type"></v-divider>
            <v-subheader 
                v-else-if="item.type == 'subheader'" 
                :key="item.type" >{{item.label}}</v-subheader>
            <v-list-item 
                v-else-if="item.type == 'link'" 
                @click="linkClick(item)" 
                :key="index">
                <v-list-item-icon v-if="'iconURL' in item" class="mr-3">
                    <v-img :src="item.iconURL" contain max-height="22px"></v-img>
                </v-list-item-icon>
                <v-list-item-icon v-else-if="'icon' in item" class="mr-3">
                    <v-icon color="#536d91">{{item.icon}}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                    {{item.label}}
                </v-list-item-title>
            </v-list-item>


            <v-list-item 
                v-else-if="item.type == 'toggle'"
                :key="index"
                @click="linkClick(decideToggle(item))" >
                <v-list-item-icon v-if="'iconURL' in decideToggle(item)" class="mr-3">
                    <v-img :src="decideToggle(item).iconURL" contain max-height="22px"></v-img>
                </v-list-item-icon>
                <v-list-item-icon v-else-if="'icon' in decideToggle(item)" class="mr-3">
                    <v-icon color="#536d91">{{decideToggle(item).icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{decideToggle(item).label}}
                </v-list-item-title>
            
            </v-list-item>
        </template>
   </v-list>

</template>

<script>

export default {
    props: {
        items: {type:Array},
        context: {default: true}
        },
    methods: {
        decideToggle(item) {
            if (this.context[item.key]) {
                return item.onTrue
            } else {
                return item.onFalse
            }
        },
        linkClick(item) {
            if ('route' in item) {
                this.$router.push(item.route)
            } else if ('emitClick' in item) {
                // console.log("emitting", item.emitClick, this.context)
                this.$emit(item.emitClick, this.context)
            }
        }
    }

}


</script>

<style>
.ehc-menu .v-list-item:hover{
    background-color: #edd4f0 !important; 
}



</style>