<template>
  <div>
    <v-row class="mt-0">
      <v-col cols="12" v-if="!pageNo" class="text-center">
        <v-img 
          src="@/assets/icons/Button_Get_Started.svg" 
          class="pointer" 
           @click="nextInterview(formData.propertyId)"/>
      </v-col>
      <v-col :cols="4" v-if="pageNo" class="text-center">
        <v-img 
          src="@/assets/icons/Back_Button.svg" 
          class="pointer" 
          @click="backInterview(formData.propertyId)"/>
      </v-col>
      <v-col :cols="4" v-if="pageNo" class="text-center">
          <v-img 
            src="@/assets/icons/Button_Next.svg" 
            class="pointer" 
            @click="nextInterview(formData.propertyId)"/>
      </v-col>
      <v-spacer v-if="!pageNo"></v-spacer>
    </v-row>
    </div>
</template>

<script>
export default {
  name: 'EhcInterviewToolbarBotton',
  props: {
    pageNo: {
      type: Number,
      default: 0
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    fieldsLength: {
      type: Number,
      default: 0
    }
  },
  methods: {
    nextInterview (propertyId) {
      this.$emit('nextInterview', propertyId)
    },
    backInterview (propertyId) {
      this.$emit('backInterview', propertyId)
    }
  },
  computed: {
    backText () {
      let text = "Back"
      if ( this.pageNo === (this.fieldsLength - 1) ) { text = "Back" }
      return text
    },
    nextText () {
      let text = "Next"
      if ( this.pageNo === (this.fieldsLength - 1) ) { text = "View and Edit" }
      return text
    }
  }
}
</script>

<style scoped>
.btn {
  border-radius: 10px;
}
</style>