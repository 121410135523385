<template>
  <div class="mt-5 ehc-interview">
    <div v-if="!loading">
      <v-btn
        class="mt-n1"
        dark
        icon
        large
        absolute
        right
        style="text-align: right"
        color="primary"
        @click="closeInterview"
      >
        <v-icon dark>mdi-close</v-icon>
      </v-btn>
      <ehc-form
        v-model="formData"
        :meta="fields[pageNo]"
        @submit="submit()"
        @change="change"
        @blur="blur"
        @valid="isValid = $event"
        @onImageUpload="onImageUpload($event)"
        @select="select($event)"
        :useAutocomplete="useAutocomplete"
        :shakeInvalid="shakeInvalid"
        :user="user"
        :persistent="true"
      >

      <template #qrcode>
        <v-card  class="mx-auto" flat>
          <v-card-text >
            <qrcode 
              :value=guidebookLink 
              size="200" 
              level="H" 
              :showTitle=false 
              :showBigButton=false 
              class="mx-auto qrcode" 
              style="inline-size: fit-content;" />
          </v-card-text>
          <p v-html="linkTo(guidebookLink)" class="text-center"></p>
        </v-card>
      </template>

      </ehc-form>

      <v-card v-if="lastMessage.show">
        <v-card-title>{{ lastMessage.title }}</v-card-title>
        <v-card-text>{{ lastMessage.text }}</v-card-text>
      </v-card>
      <ehc-interview-toolbar-bottom
        :formData="formData"
        :fieldsLength="fieldsLength"
        :pageNo="pageNo"
        @nextInterview="nextInterview(formData.propertyId)"
        @backInterview="backInterview(formData.propertyId)"
      />
    </div>
    <div v-else>
      <v-progress-linear
        indeterminate
        color="primary"
        reverse
      ></v-progress-linear>
    </div>
  </div>
</template>


<script>
import apiMixin from "@/mixins/api.vue";
import fieldsForSetup from "@/mixins/fieldsForSetup.vue";
import ehcInterviewToolbarBottom from "./ehc-interview-toolbar-bottom.vue";
import QrCodeVue from 'qrcode.vue'
import qrcode from '@/components/ehc-qrcode.vue'

export default {
  components: { ehcInterviewToolbarBottom, QrCodeVue, qrcode },
  mixins: [apiMixin, fieldsForSetup],
  props: {
    propertyId: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      property: {},
      pageNo: 0,
      formData: {},
      isValid: false,
      shakeInvalid: false,
      useAutocomplete: false,
      lastMessage: {
        show: false,
        title: "What's next?",
        text: "Next you will be take to your new guidebook. However, the page content for Property Info and Area Guide still need your personal customization. ",
      },
      loading: false,
      propertyTypeSelected: null,
      showAddress: null,
      showSplashScreen: null,
      bgImages: [],
    };
  },
  methods: {
    async getBackgroundImagesArray() {
      const bgImages = await this.apiGetBackgroundImages();
      this.bgImages = bgImages
        .filter((bgImage) => bgImage.active) // only active images
        .sort((a, b) => a.text.localeCompare(b.text)) // sort by text
        .map((bgImage) => ({
          value: bgImage.name,
          text: bgImage.text,
        })); // map to value/text object for v-select
    },

    closeInterview() {
      this.pageNo = 0;
      this.lastMessage.show = false;
      this.$emit("closeInterview");
    },

    setFormData: function () {
      // console.log("setFormData",this.property)
      let obj = { ...this.property };
      try {
        obj = { ...obj, ...this.property.contactInfo };
      } catch (error) {
        console.log("Error: ", error);
      }
      if (
        obj.avatarImg ==
        "https://firebasestorage.googleapis.com/v0/b/everhostio.appspot.com/o/propertyPictures%2Fdefault128211654638639031500x500?alt=media&token=d6982373-43ae-4f36-9ad1-8254450f0f97"
      ) {
        obj.avatarImg =
          "https://firebasestorage.googleapis.com/v0/b/everhostio.appspot.com/o/propertyPictures%2F10122261671219428819500x500?alt=media&token=b9fc322d-7728-413c-bcc5-fb8dd50e0567";
      }
      if (obj.showName === undefined) {
        obj.showName = true;
      }
      if (obj.showAddress === undefined) {
        obj.showAddress = false;
      }
      this.formData = { ...obj };
    },

    updateProperty: async function (propertyId) {
      let contactInfo = this.makeContactObj();
      console.log("contactInfo ", contactInfo);
      if (this.formData.bookagain) {
        this.formData.bookagain = this.apiPrependHttp(this.formData.bookagain);
      }
      this.property = { ...this.formData, contactInfo };
      await this.apiPutProperty(propertyId, this.property);
    },

    makeContactObj() {
      let contactInfo = {
        hostName: this.formData.contactInfo_hostName || null,
        hostPhotoUrl: this.formData.contactInfo_hostPhotoUrl || null,
        arbnbBool: this.formData.contactInfo_arbnbBool || null,
        vrboBool: this.formData.contactInfo_vrboBool || null,
        homeAwayBool: this.formData.contactInfo_homeAwayBool || null,
        bookingBool: this.formData.contactInfo_bookingBool || null,
        callingNumber: this.formData.contactInfo_callingNumber || null,
        smsNumber: this.formData.contactInfo_smsNumber || null,
      };
      console.log("contactInfo obj: ", contactInfo);
      return contactInfo;
    },

    async onImageUpload(payload) {
      // console.log("onImageUpload", payload)
      this.formData[payload.key] = payload.fileName;
      // console.log("this.formData[payload.key] ", this.formData[payload.key])
    },

    goToProperty(prop) {
      const url = this.appSite + "login/" + prop.propertyId;
      // console.log("opening property URL", url)
      window.open(url, "", "width=900, height=812");
    },

    nextInterview(propertyId) {
      if (this.pageNo > this.fieldsLength - 2) {
        this.closeInterview();
        this.goToProperty(this.property);
      } else {
        this.updateProperty(propertyId);
        this.pageNo++;
      }
    },

    backInterview() {
      if (this.pageNo < 1) {
        this.closeInterview();
      } else {
        this.pageNo--;
      }
    },

    select(type) {
      console.log("selected", type);
      this.propertyTypeSelected = type;
      this.formData.type = type;
    },

    async getProperty() {
      this.loading = true;
      this.property = await this.apiGetDoc("properties", this.propertyId);
      console.log("this.property ", this.property);
      // this.setFormData()
    },

    isSelected(type) {
      if (this.propertyTypeSelected === type) {
        return true;
      } else if (this.formData.type === type) {
        return true;
      } else {
        return false;
      }
    },

    clearFormDataAndProperty: function () {
      this.formData = {};
      this.property = {};
    },

    change: function () {
      console.log("change", this.formData);
      this.showAddress = this.formData.showAddress;
      this.showSplashScreen = this.formData.showSplashScreen;
      console.log("showAddress", this.showAddress);
      this.$emit("change", this.formData);
    },
    linkTo: function (url) {
      return `<a href="${url}" target="_blank">${url}</a>`;
    },
  },

  computed: {

    //get fields array length
    fieldsLength() {
      return this.fields.length;
    },

    // get appSite from store for linking to property
    appSite() {
      return this.$store.getters.appSite;
    },

    linkOutIcon: function () {
      return require("@/assets/icons/Vector.png");
    },

    isSmallScreen() {
      return window.innerWidth < 900;
    },

    windowWidth() {
      return window.innerWidth;
    },

    trigger: function () {
      return this.$store.getters.trigger;
    },
    guidebookLink: function () {
      return this.appSite + "/" + this.propertyId;
    },
  },

  watch: {
    propertyId: async function () {
      await this.clearFormDataAndProperty();
      await this.getProperty();
      this.setFormData();
    },
    formData() {
      this.showAddress = this.formData.showAddress;
      this.loading = false;
    },
    "$attrs.trigger": async function () {
      console.log("trigger changed in ehc-interview");
      await this.clearFormDataAndProperty();
      await this.getProperty();
      this.setFormData();
    },
  },

  async mounted() {
    this.clearFormDataAndProperty();
    // console.log("this.formData ", this.formData)
    await this.getProperty();
    // console.log("this.property ", this.property)
    await this.setFormData();
    // console.log("this.formData ", this.formData)
    this.showAddress = this.formData.showAddress;
    this.showSplashScreen = this.formData.showSplashScreen;
    this.getBackgroundImagesArray();
  },
};
</script>

//TODO: needs to be responsive on mobile.
<style scoped>
</style>

