<template>
          <v-container fluid>
            <v-row>
                <v-col 
                    cols="12" 
                    xs="12"
                    sm="6"
                    md="4"
                    lg="3"
                    xl="2"
                    v-for="(property, index) in properties" 
                    :key="index">
                    <ehc-property-card :property="property" :actions="actions" v-on="$listeners"></ehc-property-card>
                </v-col>
            </v-row>
            <div v-if="endOfData" class="mt-4">
            <span  class="grey--text" >no more data</span>
            </div>
            <template v-else>
                <v-progress-linear v-if="loading" indeterminate color="tertiary" height="20" rounded class="mt-4"></v-progress-linear>
                <ehc-btn v-else @click="$emit('bottomButton')" color="tertiary" :dark="false" width = "100%" x-small class="mt-4">load more</ehc-btn>
                <div v-if="!loading" v-intersect='{handler: intersectBottom, options: {threshold: [1.0]}}'></div>
            </template>
        </v-container>

</template>

<script>
import EhcPropertyCard from './ehc-property-card.vue'

export default {
  components: {EhcPropertyCard},
  props: ['properties', 'actions', 'endOfData', 'loading'],
  data() {
    return {

    }
  },
  methods: {
    intersectBottom (entries, observer, isIntersecting) {
      if (isIntersecting) {
          console.log("emit intersectBottom")
          this.$emit('intersectBottom')
      }
    },
  }
}
</script>