<template>
  <ehc-page v-if="userHasPermission(['superAdmin'])">
    <ehc-header 
      :actions="actions"
      text="Organizations"
      @reindexOrgs="message = {...confirmReindex}"
      ></ehc-header>
    <ehc-sheet>
      <v-menu right>
        <template v-slot:activator="{ on, attrs }">
          <div class="menuButtonContainer">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            class="menuButton"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
          </div>
        </template>
        <v-list>
        <v-list-item
          v-for="(item, index) in moreItems"
          :key="index"
        >
          <v-list-item-title class="pointer" @click="showPropsCountedMore(item.count)">{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
      </v-menu>
      <ehc-table 
        :headers="headers" 
        :items="orgs" 
        :selectable="true" 
        @click:row="rowClick" 
        :loading="loading"
        pagination="infinite"
        @bottomButton="getOrgs(true)"
        @intersectBottom="getOrgs(true)"
        :endOfData="endOfData"
        >

      </ehc-table>
    </ehc-sheet>
    <ehc-message 
      @reindexOrgs="reindexOrgs()"
      @cancel ="message=closeMessage"
      v-model="message">
    </ehc-message>
  </ehc-page>
</template>

<script>
import mixins from '@/mixins'
import auth from '@/mixins/auth'

export default {
  mixins: [mixins,auth],
  data: function() {
    return {
      actions: [
        {actionType: 'menu', icon: 'mdi-database-outline', items: [
            {type:'link',   label: 'reIndex Organizations', icon: "mdi-database-cog-outline",     emitClick: 'reindexOrgs',     toolTip: 're index organizations'}
        ]}
      ],
      message: {
          show: false
      },
      closeMessage: {
        show: false
      },
      confirmReindex: {
          show: true,
          title: "Are You Sure?",
          message: "this will re-index all organizations, it will take some time",
          width: "400",
          persistent: false,
          loading: false,
          actions: [
              {label: "cancel", clickEmit: "cancel",  dark: false, plain: true},
              {type: "spacer"},
              {label: "yes re-index", clickEmit: "reindexOrgs"}
          ]
      },
      pageTitle: "Organizations",
      expanded: [],
      singleExpand: true,
      whiteLabel: "",
      loading: false,
      orgs: [],
      endOfData: false,
      showPropsCountedMoreThan: 0,
      moreItems: [
            {count: 5,   label: 'Show Orgs with more than 5 properties.', toolTip: 'Show Large Orgs'},
            {count: 10,   label: 'Show Orgs with more than 10 properties.', toolTip: 'Get property link'},
      ],
    }
  },
  watch: {
    gcvDebouncedSearchString() {
      this.getOrgs()
    }
  },
  methods: {
    showPropsCountedMore(count){
      console.log("showPropsCountedMore", count)
      this.showPropsCountedMoreThan = count
      this.getOrgs()
    },
    async reindexOrgs() {
      console.log("reindexOrgs")
      this.message = {
        show:true,
        title: "reindexing",
        loading: true,
        persistent: true,
        message: "reindexing: please do not close your browser, this may take a while"
      }


      const orgs = await this.apiGetOrgs()
      const currentDate = Date.now()

      for (let i=0; i<orgs.length ;i++) {
        await this.apiUpdateOrg(orgs[i].orgId, {indexedAt: currentDate, orgId: orgs[i].orgId})
      }

      this.message = {
        show: true,
        title: "complete",
        message: "complete",
        actions:[
          {type: "spacer"},
          {label: "ok", clickEmit: "cancel"},]
      }


    },
    async importUserProperties() {
        this.message = {
            show: true,
            title: "recalculating Properties",
            message: "counted 0 properties",
            width: "400",
            persistent: true,
            loading: true
        }
      console.log("importUserProperties")
      const orgs = this.$store.getters.orgs
      let i = 0
      let foundProps = 0
      let totalProps = 0
      while (i < orgs.length) {
        console.log("loop", i)
        console.log(orgs[i].members[0], orgs[i].orgId)
        foundProps = await this.apiImportPropertiesFromUser(orgs[i].members[0], orgs[i].orgId)
        totalProps = totalProps + foundProps
        this.message.message = "counted " + totalProps + " properties"

        i++
      }
      this.message.loading = false
      this.message.persistent = false


      
    },
    async recalcPropCount() {
        this.message = {
            show: true,
            title: "Recalculating Property Counts",
            message: "Counted 0 properties. please don't close your browser",
            width: "400",
            persistent: true,
            loading: true
        }
      console.log("importUserProperties")
      const orgs = this.$store.getters.orgs
      let i = 0
      let foundProps = 0
      let totalProps = 0
      while (i < orgs.length) {
        console.log("loop", i)
        console.log(orgs[i].orgId)
        foundProps = await this.apiRecalcPropCount(orgs[i].orgId)
        totalProps = totalProps + foundProps
        this.message.message = "Counted " + totalProps + " properties"

        i++
      }
      this.message.loading = false
      this.message.persistent = false
      this.getOrgs()
    },
    updateLabel: function(item) {
      console.log("updateLabel",item.companyLabel, item.orgId)
      this.$store.dispatch('addWhiteLabel',{orgId: item.orgId, label: item.companyLabel})
    },
    showThisUidProperties: function(memberId){
      console.log("showThisUidProperties",memberId)
      this.$router.push({name: "Properties", query: {search: memberId, showAll: true}})  
    },
    rowClick: function(item) {
      console.log("rowClick",item)
      this.$router.push(`/organization/${item.orgId}`)  
    },
    async getOrgs(nextPage=false) {
      this.loading = true
      this.endOfData = false

      let payload = {
        limit: 20, 
        nextPage: nextPage, 
        search: this.gcvDebouncedSearchString,
        storedArray: "orgs",
      }

      if ( this.showPropsCountedMoreThan ) {
        payload = {...payload, where: [['propCount','>',this.showPropsCountedMoreThan]]}
      }

      this.apiGetDocs('organizations',payload).then((data) => {
        console.log("returned", data)
        if (data.length == 0) {this.endOfData=true}
        // this.orgs = [...this.orgs, ...data]
        this.loading=false
      })
    },
  },
  computed: {

    loadingOrgs: function(){
      return this.orgs.length == 0
    },
    headers: function(){
      return [
          {text: 'Name',            value: 'name',          sortable: true},
          {text: 'Sub Type',        value: 'subType',       sortable: true},
          {text: 'Property Limit',  value: 'propertyLimit', sortable: true},
          {text: 'Prop count',      value: 'propCount',     sortable: true},
          {text: 'Organization Id', value: 'orgId',         sortable: true},
          {text: 'Created By',      value: 'creator',       sortable: true},          
          // { text: 'View', value: 'actions', sortable: false },
          
        ]
    },
  },
  created() {
    // TODO: MOVE THIS FROM STORE TO LOCAL COMPONENT
    this.getOrgs()

  }
}
</script>

<style scoped>
.row-pointer:hover {
  cursor: pointer;
}

.menuButton {
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
  align-items: center; /* Vertically center the icon */
}

.menuButtonContainer {
  display: flex;
  justify-content: flex-end; /* Align the button container to the right */
  align-items: center; /* Vertically center the button within the container */
  margin-top: -8px; /* Adjust margin as needed */
}

</style>

