var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pt-1 black2--text",attrs:{"max-width":"19rem"}},[_c('v-img',{staticClass:"pointer mx-2 mb-2 mt-2 propertyAvatar",attrs:{"src":_vm.property.avatarImg,"height":"13rem"},on:{"click":function($event){return _vm.goToProperty()}}}),(_vm.property.contactInfo.hostPhotoUrl)?_c('ehc-user-avatar',{staticClass:"mt-n15 mx-3",attrs:{"photoURL":_vm.property.contactInfo.hostPhotoUrl}}):_vm._e(),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"black--text font-weight-bold"},[_vm._v(_vm._s(_vm.property.name))]),_c('v-list-item-subtitle',[_c('v-list-item-icon',[_c('v-img',{staticClass:"mr-2",attrs:{"max-height":"20","src":require('@/assets/icons/location@3x.svg')}}),_vm._v(" "+_vm._s(_vm.address)+" ")],1)],1)],1)],1),_c('v-list-item',{staticClass:"mt-n8"},[_c('v-list-item-subtitle',[_c('v-list-item-icon',[_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/calendar-2@3x.svg')}}),_vm._v(" "+_vm._s(_vm.formatDate(_vm.property.createdAt, 'dateOnly'))+" ")],1)],1),_c('v-list-item-subtitle',{staticClass:"ml-sm-n12"},[_vm._v(" Property ID: "+_vm._s(_vm.property.propertyId)),_c('br')])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticClass:"mx-0"},'v-list-item-icon',attrs,false),on),[_c('v-btn',{staticClass:"mx-0",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('editProperty', _vm.property)}}},[_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/edit@3x.svg')}})],1)],1)]}}])},[_c('span',[_vm._v("Edit this property")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticClass:"mx-0"},'v-list-item-icon',attrs,false),on),[_c('v-btn',{staticClass:"mx-0",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('propertyLink', _vm.property)}}},[_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/Link@3x.svg')}})],1)],1)]}}])},[_c('span',[_vm._v("See a link to this property with QR code")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticClass:"mx-0"},'v-list-item-icon',attrs,false),on),[_c('v-btn',{staticClass:"mx-0",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('propertyQRCode', _vm.property)}}},[_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/qrcode.svg')}})],1)],1)]}}])},[_c('span',[_vm._v("See a QR code for this property")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticClass:"mx-0"},'v-list-item-icon',attrs,false),on),[_c('v-btn',{staticClass:"mx-0",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('deleteProperty', _vm.property)}}},[_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/trash@3x.svg')}})],1)],1)]}}])},[_c('span',[_vm._v("Delete this property")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticClass:"mx-0"},'v-list-item-icon',attrs,false),on),[(!_vm.property.publishedAt)?_c('v-btn',{staticClass:"mx-0",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('publishProperty', _vm.property)}}},[_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/unpublish.svg')}})],1):_c('v-btn',{staticClass:"mx-0",attrs:{"small":"","loading":_vm.publishLoading,"icon":""},on:{"click":function($event){return _vm.$emit('publishProperty', _vm.property)}}},[_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/publish.svg')}})],1)],1)]}}])},[(!_vm.property.publishedAt)?_c('span',[_vm._v("Listing is unpublished")]):_c('span',[_vm._v("Listing is published")])]),_c('ehc-context-menu',_vm._g({attrs:{"items":_vm.actions,"context":_vm.property}},_vm.$listeners))],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[(_vm.property.publishedAt)?_c('span',[_vm._v("Published: "+_vm._s(_vm.dateFormat(_vm.property.publishedAt, 'dateOnly')))]):_c('span',[_vm._v("Not Published Yet")])]),_c('v-list-item-subtitle',[(!_vm.showName)?_c('span',[_vm._v("Property Name Hidden")]):_vm._e()]),_c('v-list-item-subtitle',[(!_vm.showAddress && _vm.showName)?_c('span',[_vm._v("Street Address Hidden")]):_vm._e()]),_c('v-list-item-subtitle',[(!_vm.showFullAddress && !_vm.showName)?_c('span',[_vm._v("Street Address Hidden")]):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }