<template>
    <v-card class="pt-1 black2--text" max-width="19rem">

        <!-- TODO: Get border radius on the top of the property avatar -->
        <v-img :src="property.avatarImg" height="13rem" @click="goToProperty()" class="pointer mx-2 mb-2 mt-2 propertyAvatar" />

        <ehc-user-avatar class="mt-n15 mx-3" v-if="property.contactInfo.hostPhotoUrl" :photoURL="property.contactInfo.hostPhotoUrl" />

        <!-- PROPERTY NAME AND ADDRESS -->
        <v-list-item three-line>
            <v-list-item-content>
                <v-list-item-title class="black--text font-weight-bold">{{property.name}}</v-list-item-title>
                <v-list-item-subtitle>  
                    <v-list-item-icon>                  
                        <v-img  max-height="20" :src="require('@/assets/icons/location@3x.svg')" class="mr-2"/>
                        {{address}}
                    </v-list-item-icon>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <!-- CREATED AT AND PROPERTY ID -->
        <v-list-item class="mt-n8">
            <v-list-item-subtitle>
                <v-list-item-icon>
                    <v-img :src="require('@/assets/icons/calendar-2@3x.svg')" class="mr-2"/>
                    {{ formatDate(property.createdAt, 'dateOnly')}} 
                </v-list-item-icon>    
            </v-list-item-subtitle>
            <v-list-item-subtitle class="ml-sm-n12">
                Property ID: {{property.propertyId}}<br/>
                <!-- {{property.showName}}-{{property.showAddress}} -->
            </v-list-item-subtitle>
        </v-list-item>

        <!-- ACTION BUTTONS -->
        <v-list-item >

            <!-- EDIT -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item-icon class="mx-0" v-bind="attrs" v-on="on">
                        <v-btn class="mx-0" small icon @click="$emit('editProperty', property)">
                            <v-img :src="require('@/assets/icons/edit@3x.svg')" class="mr-2"/>
                        </v-btn>
                    </v-list-item-icon>
                </template>
                <span>Edit this property</span>
            </v-tooltip>

            <!-- LINK WITH QR CODE -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item-icon class="mx-0" v-bind="attrs" v-on="on">
                        <v-btn class="mx-0" small icon @click="$emit('propertyLink', property)">
                            <v-img :src="require('@/assets/icons/Link@3x.svg')" class="mr-2"/>
                        </v-btn>
                    </v-list-item-icon>
                </template>    
                <span>See a link to this property with QR code</span>
            </v-tooltip>

            <!--- QR CODE -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item-icon class="mx-0" v-bind="attrs" v-on="on">
                        <v-btn class="mx-0" small icon @click="$emit('propertyQRCode', property)">
                            <v-img :src="require('@/assets/icons/qrcode.svg')" class="mr-2"/>
                        </v-btn>
                    </v-list-item-icon>
                </template>
                <span>See a QR code for this property</span>
            </v-tooltip>

            <!-- DELETE -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item-icon class="mx-0" v-bind="attrs" v-on="on">
                        <v-btn class="mx-0" small icon @click="$emit('deleteProperty', property)">
                            <v-img :src="require('@/assets/icons/trash@3x.svg')" class="mr-2"/>
                        </v-btn>
                    </v-list-item-icon>
                </template>    
                <span>Delete this property</span>
            </v-tooltip>

            <!-- PUBLISH -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item-icon class="mx-0"  v-bind="attrs" v-on="on">
                        <v-btn class="mx-0" v-if="!property.publishedAt" small icon @click="$emit('publishProperty', property)">
                            <v-img :src="require('@/assets/icons/unpublish.svg')" class="mr-2"/>
                        </v-btn>
                        <v-btn class="mx-0" v-else small :loading="publishLoading" icon @click="$emit('publishProperty', property)">
                            <v-img :src="require('@/assets/icons/publish.svg')" class="mr-2"/>
                        </v-btn>
                    </v-list-item-icon>
                </template>
                <span v-if="!property.publishedAt">Listing is unpublished</span>
                <span v-else>Listing is published</span>
            </v-tooltip>


            <ehc-context-menu 
                :items="actions" 
                v-on="$listeners" 
                :context="property">
            </ehc-context-menu>

        </v-list-item>

        <!-- PUBLISHED OR NOT -->
        <v-list-item>
            <v-list-item-content>
                <v-list-item-subtitle>
                    <span v-if="property.publishedAt">Published: {{dateFormat(property.publishedAt, 'dateOnly')}}</span>
                    <span  v-else>Not Published Yet</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                    <span v-if="!showName">Property Name Hidden</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                    <span v-if="!showAddress && showName">Street Address Hidden</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                    <span v-if="!showFullAddress && !showName">Street Address Hidden</span>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item>
            <v-list-item-content>
                <v-list-item-subtitle>
                    <span v-if="property.showName">Property name will not display.</span>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item> -->

        
    </v-card>

</template>


<script>

import uniMixin from '@/mixins/index.vue'
import PropertyActions from '@/mixins/propertyActions'
import EhcUserAvatar from '@/components/ehc-user-avatar.vue'
import EhcTooltip from '@/components/ehc-tooltip.vue'



export default {
    mixins: [uniMixin, PropertyActions],
    components: { EhcUserAvatar, EhcTooltip},
    name: 'ehc-property-card',
    props: ['property', 'actions'],
    data: () => ({
        message: {
            show: false,
        },
        linkDialog: false,
        publishLoading: false,
        deleteConfirm: {
            show: false,
            loading: false
        },
    }),
    watch: {
        loading(val) {this.$store.commit('setLoading', val)}
    },
    computed: {
        link() {return `${this.$store.getters.appSite}${this.property.propertyId}`},
        prop() {return this.property},
        appSite() {return this.$store.getters.appSite},
        loading() {return this.$store.getters.propertiesStatus.loading},
        user() {return this.$store.getters.user},
        address() {
            let property = this.property
            if ( property.mapAddress ) {
                return property.mapAddress.formatted_address
            } 
            return `${property.city} ${property.state}`
        },
        showName() {
            if ( this.property.showName == undefined ) {
                return true
            } else {
                return this.property.showName
            }
        },
        showAddress() {
            if ( this.property.showAddress == undefined || this.property.showAddress) {
                return true
            } else {
                return false
            }
        },
        showFullAddress() {
            if ( this.property.showAddress == undefined || this.property.showAddress ) {
                if ( this.property.showName == undefined || this.property.showName) {
                    return true
                }
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        goToProperty() {
            const url = this.appSite + "login/" + this.prop.propertyId
            console.log("opening property URL", url)
            window.open(url,"", "width=900, height=812");
        },
        // copyProperty() {
        //     console.log("copyProperty TODO")
        // },
        copyLink: function(){
            navigator.clipboard.writeText(this.link)
            this.linkDialog = true
        },

        showExtraForDevs(){
            let devUidArray = ['s7ODEJrba6YtcvaisMnEmt4XQgh1']
            if ( devUidArray.includes(this.user.uid) ) {
                return true
            }   else {
                return false
            }   
        }
    },
    watch: {

    },
    mounted(){
    },
    created() {
    }

}
</script>

<style scoped>

 div.v-card__actions {
    border: 2px solid lightgray;
    border-radius: 10px;
    padding: 0px;
    font-size:.6em;
}

.created {
    font-size: .8em !important;
    padding:0px;
    margin:0px
}
.publish {
    fill: #d4002e;
}

.unpublish {
    transform: rotate(180deg);
}

.propertyAvatar{
    border-radius: 10px 10px 10px 10px;
}

</style>