<template>
<div>
<div id="ehcformpropertytypes">
  <!-- {{options}} -->
  <div v-for="(option,index) in options" :key="index" @click="select(option)"  :class="optionClassObject(option)">
      <v-img :class="checkmarkClassObject(option)" src="@/assets/icons/Check@3x.svg"/>
      <v-img class="mx-auto icon" :src="require(`@/assets/icons/${option.icon}`)"/>
      <div class="option-text text-center label">{{option.label}}</div>
  </div>
</div>
  <div class="mx-auto text-center labelSelected" v-if="labelSelected">Selected: {{labelSelected}}</div>
</div>
</template>

<script>
export default {
  name: 'EhcFormPropertyTypes',
  props: ['options', 'type', "trigger"], 
  data() {
    return {
      selected: null,
      isActive: true,
      error: null,
      label: 'Property Types',
      message: 'Select all that apply',
      hover: null,
      labelSelected: null,
    }
  },
  methods: {
    select(option) {
      this.labelSelected = option.label
      console.log('select ',option)
      this.optionClassObject.option = false
      this.$emit('select', option.value)
    },
    optionClassObject(option) {
      return {
        'option': true,
        'optionSelected': option.selected,
        'mr-0': true,
      }
    },
    checkmarkClassObject(option) {
      return {
        'checkmark': true,
        'checkmarkSelected': option.selected,
        'mr-0': true,
      }
    },
    clearOptions() {
      console.log('clearOptions ', this.options)
      this.options.forEach(option => {
        if ( option.value !== this.type) {
          option.selected = false
        }
      })
    },
    showOptionHover(option){
      console.log('showOptionHover ',option)
    }  
  },
  computed: {
  },
  created(){
    this.clearOptions()
    this.selected = this.type
  }
}
</script>

<style scoped>
#ehcformpropertytypes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

.option {
  border: 2px solid lightgray;
  border-radius: 14px;
  width: 110px;
  padding: 10px;
  margin: 15px;
  float: left;
  cursor: pointer;
}

.optionSelected {
  border: 2px solid #893393;
  border-radius: 14px;
  overflow: none;
  cursor: not-allowed;  
}

.icon {
  padding: 5px;
  margin: 5px;
  width:50px;
}

.checkmark {
  margin: 0 auto;
  position: relative;
  top: 0;
  width: 15px;
  height: 15px;
  opacity: 0;
}

.checkmarkSelected {
   opacity: 1;
}

.labelSelected {
  display: none;
}

/* div:hover {
  border: 2px solid #893393;
  border-radius: 14px;
  cursor: pointer;  
} */


@media (max-width: 1200px) {

  .option {
      width: 80px;
      margin: 3px;
      padding: 0 0 0 0  ;
    }

  .icon {  
    padding: 2px;
    margin: 2px;
    width: 40px;
    }

  .label {
    font-size: 10px;
    font-weight: 500;
    color: #000000;
    text-align: center;
    margin: 8px;
    padding: 0;
    line-height: 5%;
  }

  #ehcformpropertytypes{
    width:95%;
    padding: 0 0 0 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .labelSelected {
    display: block;
  }

}



</style>