<template>
    <ehc-page>
        
        <ehc-header 
            :text="`Organization: ${org.name}`"
            :actions="orgActions"
            @incrementTest="test()"
            @viewChange="orgEdit={show: $event, data: {...org}}"
            @recalcPropCount="recalcPropCount()"
            style="max-width: 500px;">
        </ehc-header>

        <ehc-sheet class="ml-2" width="500px">
            <ehc-form 
                v-if="orgEdit.show"
                v-model="orgEdit.data" 
                :meta="orgMetaEdit" 
                dense 
                @submit="updateOrg(orgEdit.data)"                 
                >
            </ehc-form>
            <ehc-show-obj v-model="org" :meta="orgMetaShow" @goToLink = "goToLink($event)" v-else>
            </ehc-show-obj>
            
        </ehc-sheet>


        <ehc-header 
            text="Branding" 
            :actions="brandingActions" 
            style="max-width: 500px;"             
            @viewChange="brandingEdit={show: $event, data: {...org}}"
        ></ehc-header>
        <ehc-sheet width="500px">
            <ehc-form 
                v-model="brandingEdit.data" 
                :meta="brandingMeta" 
                v-if="brandingEdit.show"
                @submit="updateOrg(brandingEdit.data)"
                @removeImage="removeImage($event)"
                @onImageUpload="updateOrg({[$event.key]: $event.fileName})"></ehc-form>
            <ehc-show-obj v-model="org" :meta="brandingMeta" v-else></ehc-show-obj>
      <!--      WhiteLabel: {{org.companyLabel}}
            <br />
            Logo: 
            <v-img :src="org.companyImage" max-height="200" contain></v-img>
            <ehc-btn plain>change Logo</ehc-btn>
            <br />
            Org Background:
            <v-img :src="org.companyBackgroundImage" max-height="200" contain></v-img>-->

        </ehc-sheet>

        <ehc-header text="Member Users">
        </ehc-header>
        <ehc-sheet>
            <ehc-table 
                :items="members" 
                :headers="memberHeaders" 
                :loading="membersLoading"
                @importProperties="importProperties($event)"
                @removeUser="removeUser($event)"></ehc-table>
        </ehc-sheet>

        <ehc-properties 
            :orgObj="{...org, orgId: value}"
            @addProperty="getOrganization()"
            @deleteProperty="getOrganization()"></ehc-properties>

        <ehc-message v-model="message"></ehc-message>

    </ehc-page>
</template>



<script>
import ehcProperties from '@/components/properties/ehc-properties.vue'

export default {
    components: {ehcProperties},
    props: ['value'],
    data() {
        return {
            message: {
                show: false
            },
            orgEdit: {
                show: false,
                data: {}
            },
            org: {
                membersData: []
            },
            orgMeta: [
                { type: "text", label: "Organization Name", key: "name" },
                { type: "link", label: "Web Site", key: "webSite" },
                { type: "intPhoneNumber", label: "Phone", key: "phone" },
                { type: "number", label: "Property Limit", key: "propertyLimit" },
                { type: "select", label: "Subscription Type", key: "subType", options: ["Free", "Premium"], required: true, errorMessage: "you must select something" },
                
            ],
            brandingEdit: {
                show: false,
                data: {}
            },
            brandingMeta: [
                { type: "textArea",     label: "Company White Label",   key: "companyLabel", rows: 3, ['no-resize']: true },
                { type: "button", label: "Save Whitelabel", key: "submit", emitOnClick: "submit", color: "primary", dark: true, hideEhcShowObj: true },
                { type: "divider", class: "mb-10 mt-6", hideEhcShowObj: true},
                { type: "imageUpload",  label: "Company Logo",          key: "companyImage",            uploadPath: `companyPictures/background${Date.now()}`,  confirmRemoveMessage: 'Are you sure you want to remove this custom logo?',              removeLabel: "Remove Logo",                 showInForm: true, showInEhcShowObj: true, ['max-width']: "100", contain: true},
                { type: "divider", class: "mb-10 mt-0", hideEhcShowObj: true},
                { type: "imageUpload",  label: "Company Background",    key: "companyBackgroundImage",  uploadPath: `companyPictures/image${Date.now()}`,       confirmRemoveMessage: 'Are you sure you want to remove this custom background theme?',  removeLabel: "Remove Background Theme" ,    showInForm: true, showInEhcShowObj: true, ['max-width']: "100", contain: true},
            ],
            page:1,
            membersLoading: false,
            members: []
        }
    },
    computed: {
        brandingActions() {

            let actions = [
                {actionType: 'v-btn-toggle', dense: true, changeEmit: "viewChange", default: this.brandingEdit.show, mandatory: true,
                    buttons: [     
                        {label: 'View', value: false, outlined: true},
                        {label: 'Edit', value: true, outlined: true}
                    ]},

            ]


            return actions

            
        },
        memberHeaders() { 
            let memberHeaders = [
                {text: 'Display Name', value: 'displayName' },
                ...this.userHasPermission(["superAdmin"],[
                    {type:'menu', icon:  'mdi-dots-vertical', items: [
                        {type:'link',   label: 'import users properties', icon: "mdi-database-arrow-left-outline",     emitClick: 'importProperties',     toolTip: 'import users proerties into organization'},
                        {type:'link',   label: 'remove user from organization', color: 'red', icon: "mdi-trash-can-outline",     emitClick: 'removeUser',     toolTip: 'removes user from property'},
                    ]},
                ],[]),
                {text: 'Email', value: 'email' },
                {text: 'User ID', value: 'uid' },
            ]
            console.log("MEMBER HEADERS", memberHeaders)
            return memberHeaders

        },
        orgActions() {
            let actions = [
                ...this.userHasPermission(["superAdmin"],[
                    {actionType: 'menu', color: "primary",  items: [
                            {type:'link',   label: 'recalculate property count', icon: "mdi-database-refresh-outline",     emitClick: 'recalcPropCount',     toolTip: 'Edit property'},
                        ]}
                    ], []),
                {actionType: 'v-btn-toggle', dense: true, changeEmit: "viewChange", default: this.orgEdit.show, mandatory: true,
                    buttons: [     
                        {label: 'View', value: false, outlined: true},
                        {label: 'Edit', value: true, outlined: true}
                    ]},
            ]

            return actions
        },
        orgMetaShow() {
            return [...this.orgMeta,
                        { type: "number", label: "Property Count", key: "propCount" },
                        { type: "number", label: "Published Property Count", key: "propCountPublished" },
                        { type: "text", label: "Referred By", key: "referredBy"}
                    ]
        },
        orgMetaEdit() {
            return [...this.orgMeta,
                    { type: "button", label: "submit", key: "submit", emitOnClick: "submit", color: "primary", dark: true, hideInCard: true },
            ]
        }
    },
    watch: {
        org(val) {
            // console.log('org change ' + val.membersData, val)
            if (val.hasOwnProperty('membersData')) {
                // console.log('org membersData Exists', val.membersData)
            }
        },
        value() {
            this.getOrganization()
        }
    },
    created() {
        this.loadPage()
    },
    methods: {
        loadPage() {
            this.getOrganization().then((data)=> {
                this.getListOfUsers(data.members)
            })
        },
        async removeUser(user) {
            this.apiRemoveUserFromOrg(this.value, user.uid).then(()=>{
                this.loadPage()
            })
        },
        async importProperties(user) {
            // console.log("importProperties", user)
            this.message = {
                show: true,
                title: "importing Properties",
                message: "importing any properties belonging to user " + user.displayName + " - uid:  " + user.uid,
                width: "400",
                persistent: true,
                loading: true
            }
            this.apiImportPropertiesFromUser(user.uid, this.value).then((resp)=>{
                this.message = {
                    show: true,
                    title: "importing Properties",
                    message: "imported " + resp + " properties.",
                    width: "400",
                    persistent: false,
                    loading: false
                }
            })
        },
        async recalcPropCount() {
            this.message = {
                show: true,
                title: "recalculating properties",
                message: "recalculating number of properties in orgid: " + this.value + " this may take a while",
                width: "400",
                persistent: true,
                loading: true
            }
            
            this.apiRecalcPropCount(this.value).then(() => {
                this.getOrganization()
                this.message = {show: false}
            })
        },
        updateOrg: function (org) {
            console.log("updateOrg: ", org, this.value)
            this.apiUpdateOrg(this.value, org).then((resp)=>{
                this.getOrganization()
            })
            this.orgEdit = {show: false}
            this.brandingEdit = {show: false}
            /* this.showInfo = true
            this.showForm = false */
        },
        pageChange(page) {
            this.page=page
        },  
        getListOfUsers(list) {
            this.membersLoading = true
            this.apiGetListOfUsers(list).then((data) => {
                // console.log('got users', data)
                this.members = data
                this.membersLoading = false
            })
        },
        async getOrganization() {
            // console.log("getting organization")
            return await this.apiGetOrg(this.value).then((data) => {
                    this.org = data
                    return data
                })
        },
        async onImageUpload(payload) {
            let key = payload.key
            console.log("onImageUpload", payload)
            let obj = {}
            obj[key] = payload.fileName
            console.log("onImageUpload", obj)
            await this.updateOrg(obj)
            this.orgEdit = false
            this.org[payload.key] = payload.fileName
        },
        goToLink(link) {
            if ( link.includes('http') ) {
            window.open(link, '_blank')
            }
        },
        async removeImage(payload) {
            console.log('removeImage', payload)
            let orgId = this.gcvOrgId
            console.log('orgId', orgId)
            let obj = {}
            obj[payload.key] = null
            console.log("confirmAction", obj)
            await this.apiUpdateDoc('organizations', orgId, obj).then(()=>{
                this.getOrganization()
            })
            this.orgEdit = {show:false}
            this.brandingEdit = {show:false}
        }
      
    }
}


</script>