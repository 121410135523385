<template>
  <div class="slider-container">
    <div
      class="slider-track pointer"
      @click="moveToClickPosition"
    >
      <div
        class="slider-thumb"
        ref="sliderThumb"
        :style="{ left: thumbLeftPosition }"
        @mousedown="startDragging"
      >{{ value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      isDragging: false,
      thumbLeftPosition: "0px", // Initial position
    };
  },
  methods: {
    updateSliderPosition(value) {
      const sliderThumb = this.$refs.sliderThumb;
      const sliderTrack = sliderThumb.parentElement;
      const trackWidth = sliderTrack.clientWidth - sliderThumb.offsetWidth;
      const newPosition =
        ((value - this.minValue) * trackWidth) / (this.maxValue - this.minValue);
      this.thumbLeftPosition = `${newPosition}px`; // Update thumb position
    },
    startDragging(event) {
      this.isDragging = true;
      this.dragSlider(event);
      document.addEventListener("mousemove", this.dragSlider);
      document.addEventListener("mouseup", this.stopDragging);
    },
    dragSlider(event) {
      if (this.isDragging) {
        const sliderThumb = this.$refs.sliderThumb;
        const sliderTrack = sliderThumb.parentElement;
        const trackWidth = sliderTrack.clientWidth - sliderThumb.offsetWidth;
        const newPosition =
          event.clientX -
          sliderTrack.getBoundingClientRect().left -
          sliderThumb.offsetWidth / 2;
        let newValue = Math.max(
          this.minValue,
          Math.min(
            this.maxValue,
            (newPosition / trackWidth) * (this.maxValue - this.minValue) +
              this.minValue
          )
        );
        newValue = Math.round(newValue);
        this.$emit("input", newValue);
        this.updateSliderPosition(newValue);
      }
    },
    stopDragging() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.dragSlider);
      document.removeEventListener("mouseup", this.stopDragging);
    },
    moveToClickPosition(event) {
      // console.log("moveToClickPosition", event.clientX)
      const sliderThumb = this.$refs.sliderThumb;
      const sliderTrack = sliderThumb.parentElement;
      const trackWidth = sliderTrack.clientWidth - sliderThumb.offsetWidth;
      const clickPosition =
        event.clientX - sliderTrack.getBoundingClientRect().left;
      let newValue = Math.max(
        this.minValue,
        Math.min(
          this.maxValue,
          (clickPosition / trackWidth) * (this.maxValue - this.minValue) +
            this.minValue
        )
      );
      newValue = Math.round(newValue);
      this.$emit("input", newValue);
      this.updateSliderPosition(newValue);
    },
  },
  watch: {
    value(newValue) {
      this.$emit("change", newValue);
    },
  },
  mounted() {
    console.log("mounted");
    setTimeout(() => {
      this.updateSliderPosition(this.value);
    }, 1000);
  },
};
</script>

<style scoped>
/* Styles */
.slider-container {
  position: relative;
}

.slider-track {
  width: 400px;
  height: 10px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
}

.slider-thumb {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #2196F3;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  color: white;
  z-index: 1;
  transform: translateX(-50%);
}
</style>
