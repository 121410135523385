<script>

export default {
  data () {
    return {
      confirmDelete: {
          show: true,
          title: "Are You Sure?",
          message: "are you sure you want to delete this property?",
          width: 350,
          persistent: false,
          loading: false,
          actions: [
              {label: "yes delete", clickEmit: "deleteProp", color: "error"},
              {type: "spacer"},
              {label: "cancel", clickEmit: "cancel", color: "success"},                        
          ]
      },
    }
  },
  created() {
  },
  methods:{
      publishProperty(prop) {    
        // console.log("publishProperty", prop)
          let publishedAt = Date.now()
          let message = {title: "Property Published", message: "your property has been published"}      
          if (prop.publishedAt) {
              publishedAt = null
              message = {title: "Property Unpublished", message: "your property has been Unpublished"}   
          }

          this.apiSetProperty(prop.propertyId,{publishedAt: publishedAt})
          this.message= {
                  width: 300,
                  show: true,
                  ...message
              }
      },
      deleteProperty(prop) {
        console.log("deleteProperty", prop)
        this.message = {
            show: true,
            width: 350,
            loading: true,
            persistent: true,
            message: "deleting",
            title: "deleting",
        }
        this.apiMarkPropertyDeletedAt(prop).then((resp)=>{
          try {
            this.properties = this.properties.filter(el => el.key != prop.key)
          } catch (e) { console.log(e) } 
            this.message = {
                show: true,
                width: 350,
                message: "Property deleted",
                title: "Property deleted",
                actions: [
                    {type: "spacer"},
                    {label: "ok", clickEmit: "cancel"},                    
                ]
            }
        })
      },

  },
  computed: {
  }
}

</script>