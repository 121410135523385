<template>
  <div>
    <v-card flat>
      <v-card-title>
        <span v-if="showTitle">Download QR Code</span>
        <v-spacer></v-spacer>
        <span style="text-align:right">
          <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                href="#"
                id="canvasDownload"
                download="everhostQRCode.png"
                color="primary"
                dark
                text
                v-bind="attrs"
                v-on="on"
              >
                <v-icon large>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>Download QR Code Image</span>
          </v-tooltip>
        </span>
      </v-card-title>
      <v-card-actions>
      </v-card-actions>
      <v-card-text>
        <qr-code-vue
          ref="qrCodeComponent"
          :value="value"
          size="300"
          level="H"
          class="mx-auto qrcode"
          style="inline-size: fit-content;"
        />
      </v-card-text>
      <v-card-actions>
          
        <ehc-btn 
          v-if="showBigButton"
          href="#" 
          color="primary" 
          class="white--text mx-auto" 
          dark 
          block
          id="canvasDownload" 
          download="everhostQRCode.png">
            <span>Download QR Code Image</span>
        </ehc-btn>

        </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import QrCodeVue from 'qrcode.vue'

export default {
  components: { QrCodeVue },
  props: {
    value: {
      type: String,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    showBigButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      canvas: null,
      canvasDownload: null,
      pngDataUrl: null,
    }
  },
  mounted() {
    this.generateQRDownloadCode()
  },
  methods: {
    generateQRDownloadCode() {
      //get reference to the canvas element and the image download link
      this.canvas = document.querySelector('canvas');
      this.pngDataUrl = this.canvas.toDataURL("image/png");

      //get reference to the download link and add the image data url as the href
      this.canvasDownload = document.querySelector('#canvasDownload');
      this.canvasDownload.href = this.pngDataUrl;    
      },
    },
}
</script>
