<template>
    <ehc-page>
    <ehc-header text="Guest Data" :actions="headerActions" @download="download.show = true"></ehc-header>

    <ehc-sheet>
        <ehc-table
            :headers="headers"
            :items="guestsFiltered"
            >
            
        </ehc-table>
    
    </ehc-sheet>


    <ehc-download-dialog 
        v-model="download.show"
        :fetch="getData"
        :data = guestsFiltered
    >
    </ehc-download-dialog>

    </ehc-page>
</template>

<script>
import auth from "@/mixins/auth.vue"
import mixins from '@/mixins'
import EhcDownloadDialog from '@/components/ehc-download-dialog.vue'

export default {
    mixins: [auth, mixins],
    components: {EhcDownloadDialog}, 
    data() {
        return {
            pageTitle: "Guest Data",
            search: '',
            download: {
                show: false
            },
            headers: [
                {
                    text: 'Last Name',
                    align: 'start',
                    sortable: true,
                    value: 'lname',
                    filterable: true,
                },
                {
                    text: 'First Name',
                    align: 'start',
                    sortable: true,
                    value: 'fname',
                    filterable: true,
                },
                {
                    text: 'Email',
                    align: 'start',
                    sortable: true,
                    value: 'email',
                    filterable: true,
                },
                {
                    text: 'Phone',
                    align: 'start',
                    sortable: false,
                    value: 'phone',
                },
                {
                    text: 'City',
                    align: 'start',
                    sortable: true,
                    value: 'propertyCity',
                    filterable: true,
                },
                {
                    text: 'State',
                    align: 'start',
                    sortable: true,
                    value: 'propertyState',
                    filterable: true,
                },
                {
                    text: 'Property',
                    align: 'start',
                    sortable: true,
                    value: 'propertyName',
                    filterable: true,
                },
                {   
                    text: 'Created', 
                    value: "createdAtAsString", 
                    sortby: "createdAt",
                    sortable: true 
                },
            ]
        }
    },
    methods: {
        async getData(settings) {
            console.log("getData", settings)
            let mappedGuests = []
            let guest = {}
            let headers= this.headers
            this.guestsFiltered.forEach( el => {
                guest = {}
                for (const header of headers) { //use the header to map the data
                    guest[header.text] = el[header.value] || ""
                }
                mappedGuests.push(guest)
            })

            return mappedGuests
        },
    },
    computed: {
        searchString: function(){
            return this.$store.getters.searchString
        },
        headerActions() {
            let actions = [
                {actionType: 'v-btn', 
                label: "Download",
                data: this.guestsForDownload , 
                fileName:"'MY_GUESTS'" ,
                header:"'EVERHOST GUEST DATA'",
                color: "primary", 
                dark: true, 
                prependIconURL: require('@/assets/icons/Download All@3x.svg'), 
                clickEmit: "download"},        
            ]
            return actions
        },
        guests: function(){
            return this.$store.getters.guests.map( el => {
                el.createdAtAsString = this.dateFormat(el.createdAt,"dateOnly")
                el.searchAble = el.lname + " " + el.fname + " " + el.email + " " + el.phone + " " + el.propertyCity + " " + el.propertyState + " " + el.propertyName
                return el
            })
        },
        guestsFiltered: function(){
            if ( !this.gcvSearchString || this.gcvSearchString == "") 
                { return this.guests }
            return this.guests.filter( el => {
                return el.searchAble.toLowerCase().includes(this.gcvSearchString.toLowerCase())
            })
        },

        guestsForDownload: function(){
            let guestsForDownload = []
            let guest = {}
            this.guests.forEach( el => {
                guest = {}
                guest.LAST_NAME = el.lname || ""
                guest.FIRST_NAME = el.fname || ""
                guest.EMAIL = el.email || ""
                guest.PHONE = el.phone || ""
                guest.CITY = el.propertyCity || ""
                guest.STATE = el.propertyState || ""
                guest.PROPERTY = el.propertyName || ""
                guest.PROPERTY_ID = el.propertyId || ""
                guest.CREATED = el.createdAtAsString || ""
                guestsForDownload.push(guest)
            })
            return guestsForDownload
        },
        searchString: function(){
            return this.$store.getters.searchString
        },  // this is the search string from the store
    },
    created(){
        this.$store.dispatch('getGuests',this.$store.getters.user.uid)
        .then( console.log("guests") )
    }
}
</script>