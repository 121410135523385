<template>
  <ehc-page v-if="userHasPermission(['superAdmin'])">
    <ehc-header
      text="Analytics"
      @recalcPropCount="message = confirmRecalcPropCount"
    ></ehc-header>

    <ehc-sheet>
      {{ this.chartHeader }}
      <div>
        <ehc-slider
          v-model="sliderValue"
          :min-value="10"
          :max-value="totalOptionsCount"
          @change="sliderChange"
        ></ehc-slider>
      </div>

      <v-tabs class="mt-5">
        <v-tab @click="showAllProperties">Show All Properties</v-tab>
        <v-tab @click="showPublishedProperties"
          >Show Published Properties</v-tab
        >
      </v-tabs>

      <apexchart
        v-if="loaded"
        width="1500"
        type="bar"
        :options="chartOptionsTrimmed"
        :series="seriesTrimmed"
        ref="chart"
      />
    </ehc-sheet>

    <ehc-analytics-guest-vue :propertyId="propertyId"></ehc-analytics-guest-vue>

    <ehc-message
      v-model="message"
      @cancel="message = closeMessage"
      @recalcPropCount="recalcPropCount()"
    ></ehc-message>
  </ehc-page>
</template>

<script>
import ehcCardContent from "../../components/support/ehc-card-content.vue";
import ehcAnalyticsGuestVue from "../../components/ehc-analytics-guest.vue";
import ehcSlider from "../../components/ehc-slider.vue";
import apiMixin from "@/mixins/api.vue";

import BackgroundImages from "/src/store/backgroundImages.json";

export default {
  components: { ehcCardContent, ehcAnalyticsGuestVue, ehcSlider },
  mixins: [apiMixin],
  data() {
    return {
      // https://blog.logrocket.com/charting-with-vue-a-comparison/
      chartOptions: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "Properties",
          data: [],
        },
      ],

      sliceSize: 30,
      sliderValue: 30,

      loaded: false,

      counter: {},
      closeMessage: {
        show: false,
      },
      confirmRecalcPropCount: {
        show: true,
        title: "Are You Sure?",
        message:
          "this will recount all property counts for all organizations and total properties. This will take some time and costs a lot of database reads. It should only need to be done if there is an issue with the current count.",
        width: "400",
        persistent: false,
        loading: false,
        actions: [
          { label: "cancel", clickEmit: "cancel", dark: false, plain: true },
          { type: "spacer" },
          { label: "yes recalculate", clickEmit: "recalcPropCount" },
        ],
      },
      message: {
        show: false,
      },
      actions: [
        {
          actionType: "menu",
          icon: "mdi-database-outline",
          items: [
            {
              type: "link",
              label: "recalculate all property counts",
              icon: "mdi-database-cog-outline",
              emitClick: "recalcPropCount",
              toolTip: "recalculate totals for all organizations",
            },
          ],
        },
      ],
      propertyId: null,
      optionsUpdateCompleted: true,
      totalPropsPublished: 0,
      analyseThisCollection: "counter",
      analyseThisCount: "count",
    };
  },
  mounted() {
    this.getCounter(this.analyseThisCollection, this.analyseThisCount);
  },
  methods: {
    async getCounter(collectionName = "counter", countName = "countPublished") {
      this.counter = await this.apiGetDoc("settings", collectionName);
      let log = this.counter.propCountLog;
      console.log();

      let categories = [];
      let data = [];
      let i = 0;
      log.forEach((item) => {
        var date = new Date(item.timeStamp);
        let cat = date.getMonth() + 1 + "/" + date.getDate();

        if (cat != categories[i - 1] || i == 0) {
          categories.push(cat);
          data.push(item[countName]);
          i++;
        }

        this.totalPropsPublished = item.countPublished;
      });
      console.log("getCounter ", categories, data);

      this.chartOptions.xaxis.categories = categories;
      this.series[0].data = data;
      this.loaded = true;
    },
    async recalcPropCount() {
      console.log("recalcPropCount");
      this.message = {
        show: true,
        title: "Recalculating: don't close your browser",
        message: "getting organizations",
        width: "500",
        persistent: true,
        loading: true,
      };

      const orgs = await this.apiGetOrgs();
      console.log("recalcPropCount got orgs", orgs);

      let i = 0;
      let foundProps = 0;
      let totalProps = 0;
      while (i < orgs.length) {
        console.log("recalcPropCount loop", i);
        console.log("recalcPropCount org: ", orgs[i].orgId);
        foundProps = await this.apiRecalcPropCount(orgs[i].orgId);
        totalProps = totalProps + foundProps;
        this.message.message = "Counted " + totalProps + " properties";

        i++;
      }

      await this.apiUpdatePropCount(totalProps);
      this.getCounter();
      this.message.actions = [{ label: "ok", clickEmit: "cancel" }];
      this.message.loading = false;
      this.message.persistent = false;
    },

    goToProperty(prop) {
      const url = this.appSite + prop.propertyId;
      // console.log("opening property URL", url)
      window.open(url, "", "width=900, height=812");
    },
    showAll() {
      this.sliceSize = 0;
      this.$refs.chart.updateOptions(this.chartOptionsTrimmed);
      this.$refs.chart.updateSeries(this.seriesTrimmed);
    },
    updateChart() {
      console.log("updateChart");
      this.$nextTick(() => {
        if (this.$refs.chart) {
          this.$refs.chart.updateOptions(this.chartOptionsTrimmed);
          this.$refs.chart.updateSeries(this.seriesTrimmed);
        }
      });
    },
    deepCopy(obj) {
      if (obj === null || typeof obj !== "object") {
        return obj;
      }

      let copy;

      if (obj instanceof Array) {
        copy = [];
        for (let i = 0; i < obj.length; i++) {
          copy[i] = this.deepCopy(obj[i]);
        }
        return copy;
      }

      if (obj instanceof Object) {
        copy = {};
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            copy[key] = this.deepCopy(obj[key]);
          }
        }
        return copy;
      }
    },
    zoomIn() {
      if (this.sliceSize < this.totalOptionsCount) {
        this.sliceSize = this.sliceSize + 10;
        this.slider = this.sliceSize;
      }
    },
    zoomOut() {
      if (this.sliceSize > 10) {
        this.sliceSize = this.sliceSize - 10;
        this.slider = this.sliceSize;
      }
    },
    sliderChange() {
      console.log("sliderChange");
      this.sliceSize = this.sliderValue;
    },
    putBackgroundImages() {
      console.log("putBackgroundImages");
      this.backgroundImages.forEach((item) => {
        this.apiPutBackgroundImage(item, item.name);
      });
    },
    showPublishedProperties() {
      this.analyseThisCollection = "counterPublished";
      this.analyseThisCount = "countPublished";
      this.getCounter(this.analyseThisCollection, this.analyseThisCount);
      this.updateChart();
    },
    showAllProperties() {
      this.analyseThisCollection = "counter";
      this.analyseThisCount = "count";
      this.getCounter(this.analyseThisCollection, this.analyseThisCount);
      this.updateChart();
    },
  },

  watch: {
    chartOptionsTrimmed: {
      handler() {
        console.log("chartOptionsTrimmed");
        this.updateChart();
      },
      deep: true,
    },
    seriesTrimmed: {
      handler() {
        this.updateChart();
      },
      deep: true,
    },
  },

  computed: {
    appSite() {
      return this.$store.getters.appSite;
    },
    chartOptionsTrimmed() {
      let self = this;
      let options = self.deepCopy(this.chartOptions);
      options.xaxis.categories = options.xaxis.categories.slice(
        -this.sliceSize
      );
      return options;
    },
    seriesTrimmed() {
      let self = this;
      let series = self.deepCopy(this.series);
      series[0].data = series[0].data.slice(-this.sliceSize);
      return series;
    },
    chartHeader() {
      if (this.sliceSize == 0) {
        return `Number Of Properties: ${this.counter.propCount}`;
      }
      return `Number Of Properties: ${this.counter.propCount} (showing last ${this.sliceSize} data points)`;
    },
    totalOptionsCount: function () {
      return this.chartOptions.xaxis.categories.length;
    },

  },
  created() {
    //if there is a param for property in the url set this.propertyId
    if (this.$route.params.propertyId) {
      this.propertyId = this.$route.params.propertyId;
    }
    //if sliceSize is in the query string set this.sliceSize
    if (this.$route.query.showing) {
      this.sliceSize = this.$route.query.showing;
    }
  },
};
</script>

<style>
.sliderWrapper {
  width: 300px;
}
</style>
