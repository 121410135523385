<template>
    <div>
        <ehc-properties-table 
            v-if="view === 'table'" 
            v-bind="$attrs"
            v-on="$listeners" />
        <ehc-properties-gallery 
            v-if="view === 'gallery'" 
            v-bind="$attrs"
            v-on="$listeners" />
    
    </div>
</template>


<script>

import EhcPropertiesTable from '@/components/properties/ehc-properties-table.vue'
import EhcPropertiesGallery from '@/components/properties/ehc-properties-gallery.vue'

export default {
    name: "ehcPropViewDecider",
    components: {EhcPropertiesTable, EhcPropertiesGallery},
    props: ['view']
}
</script>