<template>
    <div>
        <v-fade-transition leave-absolute>
            <v-container fluid class="ma-0 pa-0 login" v-if="!showApp & !gcvAppLoading">
                <v-row align="center">
                    <v-col cols=12 sm=12 xs=12 md=4 lg=4 xl=4 class="pb-0">
                        <v-slide-x-reverse-transition>
                            <v-img 
                                class="bgFixed 
                                pa-0 ma-0 center-image" 
                                :height="(gcvIsMobile) ? '40vh' : '100vh'"
                                :src="require('@/assets/img/loginPagePics/' + pic.fileName)" 
                                :position="(gcvIsMobile) ? pic.mobilePosition : pic.position" />
                        </v-slide-x-reverse-transition>
                    </v-col>
                    <v-col cols=12 sm=12 xs=12 md=7 lg=7 xl=7>
                        <v-fade-transition :leave-absolute="true">
                            <v-card max-width="400px" flat class="mx-auto d-flex justify-center mb-6">
                                <v-progress-circular v-if="loading" class="mx-auto" indeterminate color="primary"
                                    :size="100" :width="7"></v-progress-circular>
<!--                                <v-progress-circular v-if="createAccountLoading" class="mx-auto" indeterminate
                                    color="primary" :size="100" :width="7"></v-progress-circular>-->
                            </v-card>
                        </v-fade-transition>
                      <!--  <v-slide-y-transition :leave-absolute="true">
                            <ehc-new-org-prompt v-if="newOrgPrompt" flat max-width="400px" class="mx-auto">
                            </ehc-new-org-prompt>
                        </v-slide-y-transition>-->

                        <v-slide-y-transition :leave-absolute="true">
                            <v-card flat max-width="400px" class="mx-auto" v-if="!loading">
                                <!--<v-card-text v-if="showSignUp" class="text-h4">Create A New Account</v-card-text>
                                <v-card-title v-else>
                                    <h1>Welcome Back</h1>
                                </v-card-title>
                                <v-card-subtitle v-if="showSignUp">All information is required</v-card-subtitle>
                                <v-card-subtitle v-else>Please enter your credentials to log in</v-card-subtitle>-->
                                <v-card-text class="pb-0">
                                    <ehc-form 
                                        :meta="form[page]" 
                                        v-model="credentials"
                                        :shakeInvalid = "shakeInvalid"
                                        @createNewAccount = "createNewAccount(credentials)"
                                        @submitReferral = "submitOrg()"
                                        @submitOrg="page='referral'"
                                        @valid="valid=$event"
                                        @resetPassword="forgotPassword"
                                        >


                                <!--LOGIN-->
                                        <template #loginHeader>
                                            <v-card-title class="pl-0">
                                                <h1>Welcome Back</h1>
                                            </v-card-title>
                                            <v-card-subtitle class="pl-0">Please enter your credentials to log in</v-card-subtitle>
                                        </template>

                                        <template #loginFooter>
                                        <!--TODO: make an "actions" option in ehc-form that allows for buttons side by side like this"-->
                                            <v-btn @click="page='forgotPassword'" plain
                                                color="button" class="mr-5 ml-0 pl-0" large><strong>Forgot password</strong>
                                            </v-btn>
                                            <v-btn color="button" dark class="ma-0 px-13"
                                                @click="login(credentials)" large>
                                                <strong>Login</strong>
                                            </v-btn>                                       
                                        </template>
                                        <template #loginSecondFooter>
                                            <v-btn color="button" plain class="mt-3 ml-0 pl-0"
                                                @click="page='signup'" large>
                                                <strong>Sign Up</strong>
                                            </v-btn>
                                        </template>
                                <!--ALERTS-->
                                        <template #alerts>
                                            <v-alert v-for="(alert, index) in alerts" v-bind="alert" dismissible :key=index>
                                                {{alert.text}}
                                            </v-alert>
                                        </template>

                                <!--SIGNUP-->
                                        <template #signupHeader>
                                            
                                            <v-card-text class="text-h4 pl-0">Create A New Account</v-card-text>
                                        </template>     

                                <!-- Org Setup -->
                                        <template #orgHeader>
                                            <v-card-title><h1>Account Setup</h1></v-card-title>
                                            <v-card-subtitle>We need a little information to setup your account</v-card-subtitle>       
                                        </template>


                                <!--forgotPassword-->
                                        <template #resetPasswordAction>
                                            <v-btn @click="page='login'" plain
                                                color="button" class="mr-5 ml-0 pl-0" large><strong>go back</strong>
                                            </v-btn>
                                            <v-btn color="button" dark class="ma-0 px-11"
                                                @click="forgotPassword" large>
                                                <strong>send reset email</strong>
                                            </v-btn>    
                                        </template>





                                    </ehc-form>
                                    <span class="error--text mt-0 pt-0">{{loginError}}</span>
                                </v-card-text>
                                <!--<v-card-actions class="pt-0 px-4">
                                    <v-btn v-if="!showSignUp" @click="forgotPassword(credentials.email)" plain
                                        color="button" class="mr-5" large><strong>Forgot password</strong></v-btn>
                                    <v-btn text v-if="false" color="button" dark class="ma-0 px-4"
                                        @click="showSignUp=!showSignUp" small>
                                        Create a new account</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn v-if="!showSignUp" color="button" dark class="ma-0 px-9"
                                        @click="login(credentials)" large>
                                        <strong>Login</strong>
                                    </v-btn>
                                    <v-btn v-if="showSignUp" width="100%" color="button" dark class="ma-0 pa-0"
                                        @click="createNewAccount(credentials)" large>
                                        <strong>Create new account</strong>
                                    </v-btn>

                                </v-card-actions>
                                <v-card-actions>
                                    <v-btn v-if="false" @click="showSignUp=!showSignUp" plain color="button"
                                        class="mr-5" large width="100%">Login to an existing account</v-btn>

                                </v-card-actions>-->
                            </v-card>
                        </v-slide-y-transition>
                    </v-col>
                </v-row>
            </v-container>
        </v-fade-transition>


        <template v-if="showApp">
            <slot></slot>
        </template>
        <ehc-alert></ehc-alert>
        <ehc-message 
            v-model="message" 
            @close="message={...closeMessage}"
            @okReset="page='login'; message={...closeMessage}"></ehc-message>
    </div>

</template>

<script>
import EhcNewOrgPrompt from '@/components/ehc-new-org-prompt.vue';
import auth from "@/mixins/auth.vue"
import api from "@/mixins/api.vue"
import EhcAlert from './ehc-alert.vue';

export default {
  props: {
      value: Boolean
  },
  components: {EhcNewOrgPrompt,EhcAlert},
  mixins: [auth, api],
  data () {
    return {
        valid: false,
        alerts: [],
        shakeInvalid: false,
        loading: false,
        page: 'login',
        message: {
            show: false
        },
        closeMessage: {
            show: false
        },
        loginError: "",
        alwaysFalse: false,
        credentials: {
            email: null,
            password: null
        },
        picOptions: [
            { fileName: "shutterstock_62211796_b.jpg", position: "bottom -5rem left -50rem", mobilePosition: "bottom 10rem left -10rem"},
            { fileName: "shutterstock_557019241_b.jpg", position: "top 0px right 60rem", mobilePosition: "top -15rem right -15rem"},
            { fileName: "shutterstock_1141204517_b.jpg", position: "bottom 0px left -10rem", mobilePosition: "bottom 17rem left -10rem"},
        ],
        showSignUp: false,
        createAccountLoading: false,
        sendEmailTo: [
            'tom@everhost.io', 
            'ed@everhost.io', 
            'grant@everhost.io'
            ],
        newUser: {},
        picOptionsIndex: null, //use this to force one of three picOptions
    }
  },
  watch: {
    // improved by ChatGpt
    apiUser(val) {
        if (!val) { return }

        const { uid, firstName, lastName } = this.newUser

        if (uid) { val.uid = uid }
        if (firstName) { val.firstName = firstName }
        if (lastName) { val.lastName = lastName }

        this.$store.commit('setUser', val)
        this.apiUpdateUser(val.uid, val)
        this.loading = false
    },
    async loggedIn(val) {
        if (val) {
            this.apiGetUser(this.user.uid).then((user) => {
                if (user) {
                    //console.log("got the userarino ", user)
                    this.$store.commit('setUser', user)
                    this.loading = false
                }
            })
            this.$store.dispatch('getOrg')
        }
    },
    showApp(val) {
        this.$emit("input", val)
    },
    newOrgPrompt(val) {
        if (val==true) {
            this.page='setupOrg'
        }
    },
  },
  computed: {
    form() {
        let form = {
            login: [
                {type: "slot",      slotName: "loginHeader"},
                {type: "email",     label: "Email",     key: 'email',     required: true},
                {type: "password",  label: "Password",  key: 'password',  required: true},
                {type: "slot",      slotName: "loginFooter"},
                {type: "slot",      slotName: "loginSecondFooter"},
            ],
            signup: [
                {type: "slot",              slotName: "signupHeader"},
                {type: "slot",              slotName: "alerts"},
                {type: "email",             label: "Email",                 key: 'email',               required: true},
                {type: "password",          label: "Password",              key: 'password',            required: true},   
                {type: "password",          label: "Confirm Password",      key: 'passwordCheck',       required: true},
                {type: "text",              label: "First Name",            key: 'firstName',         required: true},
                {type: "text",              label: "Last Name",            key: 'lastName',         required: true},
                {type: "intPhoneNumber",    label: "Phone",                 key: 'phoneNumber',         required: true},
                {type: "button",            label:"create new account",     emitOnClick: "createNewAccount", large: true, color: 'primary'},
            ],
            setupOrg: [
                {type: "slot",      slotName: "orgHeader"},
                {type: "text",      label: "Company Name",              key: 'orgName',         required: true},
                {type: "button",    label: "Submit",                    key:"submit",   emitOnClick: "submitOrg", large: true, color:'primary'}
            ],
            referral: [
                {type: "subheader", label: "how did you find out about us?", class: "text-h5 mb-0 pb-0"},
                {type: "radio",         key:"referredBy",  required: true , radio: [
                    {label: "Facebook",              value:"Facebook"},
                    {label: "Instagram",             value:"Instagram"   },
                    {label: "Friend / Referral",     value:"Friend / Referral"},
                    {label: "Podcast",               value:"Podcast"},
                    {label: "Conference / Meetup",   value:"Conference / Meetup"},
                    {label: "LinkedIn",              value:"LinkedIn"},
                    {label: "Google Search",         value:"Google Search"},
                    {label: "Other",                 value:"Other"},
                ]},
                {type: "button",            label:"Submit",     emitOnClick: "submitReferral", large: true, color: 'primary'}
            ],
            forgotPassword: [
                {type: "subheader", label: "Please enter your email", class: "text-h5 mb-0 pb-0 pl-0"},
                {type: "email",     label: "email",  key: 'email', required: true},
                {type: "slot",      slotName: "resetPasswordAction"},
                // {type: "button",    label:"Send reset password Email",     emitOnClick: "resetPassword", large: true, color: 'primary'},
            ]

        }

        return form
    },
    loginForm() {
        let loginFields = [
            {type: "email",     label: "Email",     key: 'email',     required: true},
            {type: "password",  label: "Password",  key: 'password',  required: true}
        ]
        let signupFields = [
            {type: "password",  label: "Confirm Password",  key: 'passwordCheck',  required: true},
            {type: "text",      label: "Name", key: 'displayName', required: true},
            {type: "intPhoneNumber",     label: "Phone",   key: 'phoneNumber',  required: true},
        ]
        return this.showSignUp ? [...loginFields,...signupFields] : loginFields
        },
    // loading() {
    //     let status = this.orgStatus
    //     if (this.loggedIn == true && status.loading == true) {return false}
    // },
    newOrgPrompt() {
        let status = this.orgStatus
        if (status.checked == true && status.found == false && status.loading == false) {
            return true
        }
        return false
    },
    orgStatus() {
        return this.$store.getters.orgStatus
    },
    pic() {
        let max = this.picOptions.length;
        let index = (this.picOptionsIndex != null) ? this.picOptionsIndex : null || Math.floor(Math.random() * max)
        return this.picOptions[index]
    },
    showApp() {
        let status = this.orgStatus
        if (this.loggedIn == true && status.found == true ) {
            this.loginError=""
            return true
        } else {
            return false
        }
    },
    loginDialog: function() {
        return !this.loggedIn
    },
    intro: function(){
        this.$store.dispatch('getMetaPage','intro')
        return this.$store.getters.metaPage
    },
    style: function(){
        return this.$store.getters.emailStyle
    },
    bodyTableWrapperStart: function(){
        return this.$store.getters.emailBodyTableWrapper
    },
    bodyTableEnd: function(){
        return this.$store.getters.emailBodyTableEnd
    },
    emailCustomContent: function(){
        let email = this.credentials.email
        let displayName = this.credentials.displayName || "User"
        return `<p class="displayName">Hi ${displayName},</p>
                <p>Thank you for joining the Everhost community. Here is your account information:</p>
                https://manage.everhost.io<br/>
                u: ${email}<br/>
                p: ***********<br/><br/>
                <p>Excited to see some amazing property welcome books! We're always looking to hear from our users.  Don't hesitate to contact support@everhost.io with your feedback.</p>
                <p>Cheers,<br/>
                Nomad</p>`
    },
    emailHead: function(){
        return `<head>
                    <style>
                        ${this.style}
                    </style>
                </head>`
    },
    mailObj: function(){
        let email = this.credentials.email

        let mailObj = {
            to: [email],
            bcc: this.sendEmailTo,
            subject: "Welcome to Everhost!",
            html: `
                <html>
                ${this.emailHead}                
                <body>
                ${this.bodyTableWrapperStart}
                ${this.emailCustomContent}
                ${this.bodyTableEnd}
                </body>
                </html>
            `
            }
        return mailObj
    },
    
  },
  methods: {
    submitOrg() {
        this.gcvAppLoading = true
        //console.log("submit org")
        let formData = {
            name: this.credentials.orgName,
            referredBy: this.credentials.referredBy
        }
        if (this.valid) {
            //console.log("make a organization with this info", formData)
            this.$store.dispatch("makeOrg", formData).then(()=>{
                this.emailReferral()
                this.credentials = {}
                this.page = "login"
                this.gcvAppLoading = false
            })
        } else {
            //console.log("input invalid")
            this.shake = true
        }
            
    },
    emailReferral() {
        let org = this.credentials.orgName
        let referredBy = this.credentials.referredBy
        let content = `<p> ${org} : ${referredBy} </p>`
        let mailObj = {
            to: this.sendEmailTo,
            subject: "everhost referral: " + referredBy,
            html: `
                <html>
                ${this.emailHead}                
                <body>
                ${this.bodyTableWrapperStart}
                ${content}
                ${this.bodyTableEnd}
                </body>
                </html>
            `
            }
            this.sendMail(this.mailObj)
            //console.log("submitReferral", mailObj)
            this.sendMail(mailObj)
    },

    makeDisplayName: function(credentials) {
        console.log("makeDisplayName", credentials)
        let displayName = '';
        
        if (credentials.firstName) {
            displayName = credentials.firstName;
            
            if (credentials.lastName) {
            displayName += ' ' + credentials.lastName;
            }
        }
        
        return displayName;
    },

    createNewAccount: async function(){
      let credentials = this.credentials    
      let obj = {
        'email': this.credentials.email,
        'password': this.credentials.password,
        'displayName': this.makeDisplayName(credentials),
        'firstName': credentials.firstName || '',
        'lastName': credentials.lastName || '',
      }
      if ('phoneNumber' in credentials) {
          obj.phoneNumber  = credentials.phoneNumber
      }

      this.newUser = obj
      
      console.log("createNewAccount", obj)
      if ( !this.credentials.email || !this.credentials.password ){
        // this.$store.commit('setAlertMessage', "Please fill in all fields")
        // this.$store.commit('setShowAlert',true)
        this.message = {
            show: true,
            width: 300,
            title: "Missing fields",
            message: "Please fill in all fields",
            actions: [
                {type: "spacer"},
                {type: "button", clickEmit: "close", label: "ok"}
            ]
        }
        return
      }
      if ( this.credentials.password.length < 6 ){
        // this.$store.commit('setAlertMessage', "Password must be at least 6 characters")
        // this.$store.commit('setShowAlert',true)
        this.message = {
            show: true,
            width: 300,
            title: "Invalid password",
            message: "Password must be at least 6 characters",
            actions: [
                {type: "spacer"},
                {type: "button", clickEmit: "close", label: "ok"}
            ]
        }
        return
      } 
      if ( this.credentials.password !== this.credentials.passwordCheck ){ 
        // this.$store.commit('setAlertMessage', "Passwords do not match")
        // this.$store.commit('setShowAlert',true)
        this.message = {
            show: true,
            width: 300,
            title: "Mismatched password",
            message: "Passwords do not match",
            actions: [
                {type: "spacer"},
                {type: "button", clickEmit: "close", label: "ok"}
            ]
        }        

        return
      }

      this.loading = true

      await this.$store.dispatch('createUserWithEmailAndPassword',obj)
      .then( res => {
        // console.log("createUserWithEmailAndPassword", res)
        if ( res.error ){
            this.message = {
                show: true,
                width: 400,
                title: "Error",
                message: `Something went wrong: ${res.error.message}`,
                actions: [
                    {type: "spacer"},
                    {type: "button", clickEmit: "close", label: "ok"}
                ]
            }  
          this.loading = false
          return res
        }
        // this.$store.commit('setAlertMessage', `Account for ${this.credentials.email} was created`)
        // this.$store.commit('setShowAlert',true)
 
        if ( res ){
            this.sendMail(this.mailObj)
            this.login(this.credentials,true).then(()=>{
                // this.page = 'setupOrg'
                this.loading = false    
            })
        }
          
        // this.$route.push({name: "Properties"})
      })
      .then( res => {
            console.log("then", res)
        }
      )
      .catch( err => {
          this.loading = false
            this.message = {
                show: true,
                width: 400,
                title: "Error",
                message: err.message,
                actions: [
                    {type: "spacer"},
                    {type: "button", clickEmit: "close", label: "ok"}
                ]
            }  
            this.alerts = [...this.alerts, {
                type: "error",
                text: err.message,
            }]
      })
    },

    sendMail: function(mailObj) {
        //console.log("sendMailMethod", mailObj)
        try {
            this.$store.dispatch('sendMail',mailObj)
        } catch (error) {
            // console.log("error sending mail", error)
        }
    },

    login: async function(credentials, first = false) {
        //console.log("login", credentials)
        
        this.gcvAppLoading = (first) ? false : true
        this.loading=true
        return await this.$store.dispatch('signInWithEmailAndPassword', credentials).then(res=>{
                    //console.log("logged in")
                    this.gcvAppLoading=false
                    this.loading= (first) ? true : false
                    return true
                }).catch((err) => {
                    this.loginError = err.message
                    this.gcvAppLoading=false
                    this.loading = false
                })
    },
    logout: function() {
        this.$store.dispatch('logout')
    },
    forgotPassword: async function(){
        let email =  this.credentials.email
        if ( !this.valid ) { 
            this.shakeInvalid=true
            this.message = {
                show: true,
                title: "Invalid email",
                width: 300,
                message: "please enter a valid email address",
                actions: [
                    {type: "spacer"},
                    {label: "ok", clickEmit: "close"}
                ]
            }
        }
        else {
            await this.$store.dispatch('resetPassword',email)
            this.message = {
                show: true,
                title: "reset link sent",
                message: `A reset link has been sent to ${email}.  Be sure to check your junk mail`,
                actions: [
                    {type: "spacer"},
                    {label: "ok", clickEmit: "okReset"}
                ]
            }

        }    
    },
    emailIsValid: function(email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) { return (true) }
        else { return (false) }
    },
    confirmAction: function(){
        alert("confirm ation")
    },
  },
  mounted() {
      //this removes the signup psudo route from the url if it is there
      if ( this.$route.path === '/signup' ) {
          this.showSignUp = true
          this.page = "signup"
          this.$router.push('/')
      }
        // this.sendMail(this.mailObj)
  },  
}
</script>

<style>
.bgFixed .v-image__image--cover{
      background-attachment: fixed;
      min-height: 100%;
}
.center-image {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* Additional styles if needed, like max-width or max-height */
}
.login {
    background-color: white;
    min-height: 100vh;
}
</style>