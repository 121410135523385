<!-- if v-model = true it is debounced
if immediate is true there is no debounce
-->


<template>
    <v-fade-transition>
    <v-overlay 
        v-if="show"
        :absolute="true"
        v-bind="$attrs"
        :value="show"
        :z-index="1000"
        :color="('color' in $attrs) ? $attrs.color : 'tertiary'"
        :opacity="('opacity' in $attrs) ? $attrs.opacity : .9"
        >
        <img :src="require('@/assets/ehicond.svg')" width="80px"></img>
        <v-progress-linear
        indeterminate
        color="primary"
        ></v-progress-linear>
    </v-overlay>
    </v-fade-transition>
</template>



<script>

import _ from 'lodash';

export default {
    props: ['value', 'immediate'],
    data() {
        return {
            debounce:  false
        }
    },
    watch: {
        value(val) {
            //console.log("loading overlay", val, this.debounce)
            if (val == true) {
                this.debounce = true
            } else if (val == false) {
                this.show = val
            }
        },
        immediate(val) {
            //console.log("loading overlay immediate", val, this.debounce)
            this.debounce = val
        }
    },
    computed: {
        show: {
            get() {
                return this.debounce
            },
            set: _.debounce(function(newValue) {
                //console.log("debounce run", this.debounce, newValue)
                this.debounce = newValue;
            }, 2000)
        }
    },
    mounted() {
        if (this.immediate ===  true) {
            this.debounce = this.immediate
        }
        
    }
}
</script>


<style>



</style>