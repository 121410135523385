<template>
  <ehc-dialog v-model="show" :title="title" :width="width">
    <ehc-form
      :meta="meta"
      v-model="formData">
      </ehc-form>
      
      <vue-json-to-excel
        :data="data"
        :name="fileName + '.' + formData.fileType"
        :header="header"
        :type="formData.fileType"
        :escapeCsv = "false"
        :fetch = "getData"
      
      >      
        <ehc-btn color="primary" dark width="100%" :loading="loading">
            <template #left>
              <img :src="require('@/assets/icons/Download All@3x.svg')" class="mr-2"></img>
            </template>
            Download  
        </ehc-btn>
      </vue-json-to-excel>
      
  </ehc-dialog>
</template>


<script>
import vueJsonToExcel from "vue-json-excel";



export default {
  name: "ehc-download-dialog",
  components: {
    vueJsonToExcel
  },
  props: {
    fetch:    {type: Function},
    data:     {type: Array, default: null},
    value:    {type: Boolean, default: false},
    title:    {type: String, default: "Download"},
    width:    {type: String, default: "200px"},
    fileName: {type: String, default: "Data"},
    header:   {
                default: '<span style="font-size: 1.2em">EVERHOST DATA</span>'
              },
    meta:     {
                type:Array,
                default() { 
                  return [
                            // {type:'radio', class: "mt-0", label: "Data: ", key: "data",
                            //     radio: [
                            //         {label:"search results", value: "search"},
                            //         {label:"all data", value: "all"}
                            //     ]},
                            
                            // {type:'divider'},
                            
                            {type:'radio', class: "mt-0", label:"file type: ", key: "fileType",
                                radio: [
                                    {label:"csv", value: "csv"},
                                    {label:"xls", value: "xls"}
                                ]}
                          ]
                }
              }
  },
  methods: {
    async getData() {
      this.loading=true
      return await this.fetch(this.formData).then((response) => {
        console.log("loading stop", response)
        this.loading = false
        this.show =  false        
        return response

      })

    }
  }, 
  data() {
    return {
      loading: false,
      formData: {fileType:null, }

    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set(val) {
        this.$emit('input', val)

      }
    }
  }
}



</script>



<style>
</style>

