<template>
    <ehc-page v-if="userHasPermission(['superAdmin'])">
        <v-card class="pa-3 mb-3">
        <ehc-header text="Global App Settings" ></ehc-header>
            <ehc-sheet >

                <ehc-form
                dense
                color="primary"
                :meta="globalAppSettingsMeta"
                v-model="editSettings"
                @save="saveGlobalAppSettings()">

                </ehc-form>
            </ehc-sheet>        
        </v-card>

        
        <v-card class="pa-3 mb-3">
        <ehc-header text="Default Property Template" ></ehc-header>

        <p @click="goToDefault" class="pointer mt-5 ml-5 text-h6"  v-html="defaultLink" />
        </v-card>

        <v-card class="pa-3 mb-3">
        <ehc-header text="Un-delete a property" ></ehc-header>

        <ehc-sheet >
            <ehc-form
                dense
                color="primary"
                :meta="undeletePropertyMeta"
                v-model="formData"
                @undelete="undeleteProperty()">
            </ehc-form>

            {{ deleteMessage }}

        </ehc-sheet>    
        </v-card>




    </ehc-page>
</template>

<script>
import apiMixin from '@/mixins/api.vue'
import ehcButtonSmall from '../../components/global/ehc-button-small.vue'

export default {
  components: { ehcButtonSmall },
        mixins: [apiMixin],
        data() {
            return {
                edit: false, 
                //just add to this to get more settings
                globalAppSettingsMeta: [
                    {type: "number", label: "default property limit", key: "defaultPropertyLimit"},
                    {type: "button",        label: "Save",                    key: "save",          emitOnClick: "save"},
                ],
                editSettings:  {},
                defaultLink: 'https://everhost.io/login/default12821',
                formData: {
                    propertyId: '',
                },
                undeletePropertyMeta: [
                    {type: "text", label: "Property ID", key: "propertyId"},
                    {type: "button", label: "Undelete", key: "undelete", emitOnClick: "undelete"},
                ],     
                deleteMessage: "",
            }
        },
        watch: {
            gcvAppSettings() {
                this.editSettings=this.$store.getters.appSettings
            }
        },
        mounted() {
            this.editSettings = this.$store.getters.appSettings
        },  
        created() {
            this.editSettings = this.$store.getters.appSettings
        },
        computed: {

        },
        methods: {
            getAppSettings() {
                this.appSettings = this.$store.getters.appSettings
            },  
            saveGlobalAppSettings() {
                this.apiUpdateAppSettings(this.editSettings)
            },
            goToDefault() {
                // console.log("goToDefault")
                let defaultLink = this.defaultLink
                window.open(`${defaultLink}`, "", "width=900, height=812");
            },
            async undeleteProperty() {
                const propertyId = this.formData.propertyId
                console.log("undeleteProperty", propertyId )
                await this.apiUndeleteProperty(propertyId)
                this.deleteMessage = `Property ${propertyId} undeleted`
            }

        }


    }

</script>



<style>

</style>