<template>
<!-- TODO: workout issues with displaying these, turn this into a table make corners rounded -->
  <div class="ehc-Show-Obj" >
    <v-container class="ma-0 pa-0" width="600px">
      <template v-for="(item, index) in meta">
        <v-row :key="index" dense class="" v-if="!item.hideEhcShowObj">
          <v-col><span class="label">{{item.label}}: </span></v-col>
          <v-col 
            v-if = "item.type == 'imageUpload'" 
            @click="$emit('goToLink',value[item.key])"
            class="pointer" 
            >
            <v-img v-if="item.showInEhcShowObj && value[item.key]" :src="value[item.key]" v-bind="item" ></v-img>
            <v-icon v-else-if="value[item.key]">mdi-open-in-new</v-icon>
            </v-col>
          <v-col v-else >{{value[item.key]}}</v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'AllDataPanel',
    props: ['value', 'meta'],
    methods: {
      goToLink(link) {
        if ( link.includes('http') ) {
          window.open(link, '_blank')
        }      
      }
    }
  }
</script>


<style>

.ehc-Show-Obj .label {
  font-size: 14px;  
  color: #5c6d86;
}


.ehc-Show-Obj .row:nth-child(odd) {
  background-color: #f3f5f7;
}

.ehc-Show-Obj .col:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ehc-Show-Obj .col:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.pointer {
  cursor: pointer;
}

</style>