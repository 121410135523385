<template class="ehc-message">
    <ehc-dialog v-model="show" v-bind="val" :width="('width' in val) ? val.width : '400' ">
        <v-alert 
            v-if="'alert' in val"
            v-bind="val.alert" 
            :dense="('dense' in val)? val.dense : true">
            {{val.alert.text}}</v-alert>
        {{val.message}}
        <slot></slot>
        <slot :name="val.slotName"></slot>

        <template #actions>
            <template v-for="(action, index) in val.actions" >
                <v-spacer v-if="action.type=='spacer'" :key="index"/>
                <ehc-btn v-else :key="index" v-bind="action" @click="$emit(action.clickEmit)" class="mr-2"> 
                    <span v-if="action.label">{{action.label}}</span>
                    <!-- <v-icon v-if="action.iconImg">{{action.iconImg}}</v-icon> -->
                </ehc-btn>
            </template>
        </template>
    
    </ehc-dialog>

</template>


<script>
export default {
    props: {
        value: Object,
    },
    computed: {
        val: { 
            get() {
                return this.value
            },
            set(val) {
                //console.log("ehcmessage emit", val)
                this.$emit('input', val)
            }
        },
        show: {
            get() {
                return this.value.show
            },
            set(val) {
                this.$emit('input',{show: val})
            }
        }
    }
}
</script>


<style scoped>
/* TODO: this might cause problems in some uses of this component. Need to scope better */
.v-dialog {
    overflow: hidden;
}

</style>