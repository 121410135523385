<script>

export default {
 data () {
   return {
    gcvTimeout: null
   }
  },
  methods: {

  },
  computed: {
    gcvAppLoading: {
      get() {
        return this.$store.getters.appLoading
      },
      set(val) {
        this.$store.commit('setAppLoading', val)
      }
    },
    gcvBreakpoint() {
      return this.$vuetify.breakpoint.name
    },
    gcvIsMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
    },
    gcvAppSettings: {
      get() {
        return  this.$store.getters.appSettings
      },
      set(val) {
        this.$store.commit('setAppSettings', val)
      }
    },
    gcvOrg:{
      get() {
        return this.$store.getters.org
      }
    },
    gcvOrgId: {
      get() {
        return this.$store.getters.orgID
      }
    },
    gcvSearchString: {
        get: function() {
            return this.$store.state.searchString
        },
        set: function(newValue) {
            
            let searchString = (newValue == null) ? null : newValue.toLowerCase()
            this.$store.commit('setSearchString', searchString)

            if (this.gcvTimeout) clearTimeout(this.gcvTimeout)
            this.gcvTimeout = setTimeout(() => {
                this.$store.commit('setDebouncedSearchString', searchString)
            }, 500)
            
        }
    },
    gcvDebouncedSearchString:  function () {
      return this.$store.getters.debouncedSearchString
    }
 }

}

</script>