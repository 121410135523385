<template>
    <v-card width="400" flat height="100%" class="ehc-profile-card">

      <v-container class="pa-1 ma-1">
        <v-row>
          <v-col
            class="pt-4 pl-5"
            justify="center">
            {{org.name}}
          </v-col>
          <v-col self-align="end" cols="2">
            <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                <v-btn plain small fab v-on="on" v-bind="attrs" @click="$store.dispatch('logout')">
                  <v-img :src="require('@/assets/icons/logout@3x.svg')"/>
                </v-btn>
                </template>
                <span>Logout</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col align="center"
                justify="center"
                cols="4"
                class="pl-0 pr-0 ma-0">
            <ehc-user-avattar :size="90" :photoURL="user.photoURL"></ehc-user-avattar>
          </v-col>
          <v-col cols="1" class="pl-0 pr-0 ma-0">
            <v-divider vertical />
            </v-col>
          <v-col align="left"
                justify="left"
                cols="6"
                class="pl-0 pr-0 ma-0">
            <strong>{{user.displayName}}</strong>
            {{user.email}}<br/>
            <a href="#" color="Black2" @click="changeProfilePic()">Change Profile Picture</a><br/>
            <a href="#" color="Black2" @click="changePassword(user.email)">Change Password</a>
          </v-col>
        </v-row>
        <v-row></v-row>
      </v-container>

                <ehc-image-upload 
                    v-model="picDialog" 
                    title="Upload Profile Image" 
                    :uploadPath="'/profilePictures/'+user.uid + Date.now()"  
                    @upload="setProfilePic($event)" 
                    circle
                    :size="{
                        width: 200,
                        height: 200
                    }"
                />




    </v-card>
</template>


<script>
import auth from "@/mixins/auth.vue"
import EhcUserAvattar from "@/components/ehc-user-avatar.vue"
import ehcImageUpload from "@/components/ehc-image-upload.vue"

import api from "@/mixins/api.vue"


export default {
  props: [],
  mixins: [api, auth],
  components:{EhcUserAvattar, ehcImageUpload},
  data () {
    return {
      picDialog: false,
    }
  },
  mounted() {
    this.apiGetUser(this.user.uid)
  },
  computed: {
    org() {
      return this.$store.getters.org
    },
    passwordChangeMessage() {
      return `A password change link has been sent to ${this.user.email}. Be sure to check your junk mail.`
    }
  },
  methods: {
    changeProfilePic() {
      this.$emit('changeProfilePic')
      this.picDialog = true
    },
    openDialog() {
      console.log("openDialog")
      this.picDialog= true
    },
    async changePassword(email){
      let message =  
      this.showPasswordChangeAlert = true
      await this.$store.dispatch('resetPassword',email)
      this.$store.commit('setAlertMessage', this.passwordChangeMessage)
      this.$store.commit('setShowAlert', true)
    }

  }
}
</script>

<style>
.ehc-profile-card {
  border-radius:20px;
}

</style>