//This is a wrapper for using ehc-image-upload.vue in a form
//adds a button and alert-confirm
//        <ehc-form-image-upload
//             v-else-if="field.type == 'imageUpload'"
//             v-model="data[field.key]" 
//             :field="field" 
//             @onImageUpload="$emit('onImageUpload', $event)">
//        </ehc-form-image-upload>


<template>
    <div class="mb-5 mt-n7 wide">
        <span :class="labelClass">{{field.label}}</span>
        <div class="text-center mb-n12 mt-3">
                <v-avatar 
                color="button" 
                :size="size"
                class="mx-auto"
                @click="addPic = !addPic"            >
                    <v-img v-if="value" :src="value" class="pointer img"></v-img>
                    <v-img v-else src="@/assets/img/emptyProfilePic.svg" class="pointer img"></v-img>
                </v-avatar>
                <v-img height="44" width="44" src="@/assets/icons/camera.svg" class="camera pointer" @click="addPic = !addPic"></v-img>
        </div>
        <!-- <ehc-btn @click="addPic = true" color="primary" class="my-3 wide" rounded elevation="10">{{ field.label }}</ehc-btn> -->
        <ehc-image-upload v-bind="field" v-model="addPic" :title="field.label" :uploadPath="field.uploadPath"
            @upload="$emit('onImageUpload', {fileName: $event, key: field.key})" 
            :shape="field.shape"
            :size="field.size" />
        <v-img v-if="field.showInForm" :src="value" :style="field.size" max-width="100" max-height="200" contain class="my-5 mx-auto"></v-img>
        <ehc-btn v-if="value && remove" text color="primary" class="float-right" @click="message = {...confirmMessage}">
            {{removeLabel}}</ehc-btn>

        <ehc-message 
            v-model="message"
            @cancel="message = {...closeMessage}"
            @yes="confirmAction(field.key)">
            <template #confirm>
                <v-card-title>{{field.confirmRemoveMessage}} </v-card-title>
            </template>
        </ehc-message>
    </div>
</template>


<script>
import EhcImageUpload from '@/components/ehc-image-upload.vue'
import EhcAlertConfirm from '@/components/ehc-alert-confirm.vue'

export default {
    components: {
        EhcImageUpload,
        EhcAlertConfirm
    },
    props: {
        field: Object,
        value: String,
        remove: {
            type: Boolean,
            default: true
        },
        labelClass: {
            type: String,
            default: ''
        }
    },  
    // props: ['field', 'value', 'remove'],
    data() {
        return {
            message: {
                show: false
            },
            closeMessage: {
                show: false
            },
            confirmMessage: {
                width: 600,
                show: true,
                title: "confirm remove image",
                message: this.field.confirmRemoveMessage,
                actions: [
                    {type: "spacer"},
                    {label: "no",   clickEmit: "cancel"},
                    {label: "yes",  clickEmit: "yes", plain: true},
                ]
            },
            addPic: false,
            confirmRemove: false,
            size: 174,
        }
    },
    methods: {
            confirmAction(key) {
                this.$emit('removeImage',{key: key})
                this.message = {...this.closeMessage}
            },
 /*        removeCustomItem(key) {
            console.log('removeCustomItem', key)
            // this.itemToRemove = key
            let message  = ''

            // // TODO: this is too specific, can't be used elswhere
            // if ( key = "companyImage" ) {message = 'Are you sure you want to remove this custom logo?'}
            // else { message = 'Are you sure you want to remove this custom background theme?' }

            // this.confirmRemove=true
            // this.$store.commit('setShowConfirm', true)
            // this.$store.commit('setConfirmMessage', message)  
        }, */
        

    },
    computed: {
        removeLabel() {
            return this.field.removeLabel || 'Remove'
        },
    }
}

</script>
<style>
.wide {
    width: 100%;
}
.camera {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(50px, -50px);
}

/* .img {
    border: 20px double white;
} */

</style>
