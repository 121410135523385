import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase'
import Auth from './auth'
import Org from './org'
import Properties from './properties'
import GuestData from './guestdata'
import Feedbacks from './feedbacks'
import Mail from './mail'
import Support from './support'
import Users from './users'
import UpdatesLog from './updateslog.js'
import Meta from './meta'
import appSettings from './appSettings.js'



Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    devAppSite: "http://localhost:5000/",
    prodAppSite: "https://everhost.io/",
    appLoading: true,
    feedback: {
      show: false,
    },
    alert: {
      show: false,
      message: null,
      title: null,
    },
    confirm: {
      show: false,
      message: null,
    },
    drawer: {
      show: false
    },
    searchString: null,
    debouncedSearchString: null,
  },
  getters: {
    drawer: state => state.drawer,
    appSite: state => {
      if (window.location.hostname === "localhost") {
        return state.devAppSite
      }
      return state.prodAppSite
    },
    appLoading: state => state.appLoading,
    feedback: state => state.feedback,
    showAlert: state => state.alert.show,
    alertMessage: state => state.alert.message,
    alert: state => state.alert,
    showConfirm: state => state.confirm.show,
    confirmMessage: state => state.confirm.message,
    searchString: state => state.searchString,
    debouncedSearchString: state => state.debouncedSearchString,

  },
  mutations: {
    setDrawer(state, payload) {
      for (var key in payload) {
        state.drawer[key] = payload[key]
      }
    },
    setAppLoading(state, payload) {
      // console.log("setLoading", payload)
      state.appLoading = payload
    },
    setFeedback(state, payload) {
      for (var key in payload) { //change only the settings that were input everything else keep the same
        state.feedback[key] = payload[key]
      }
    },
    setAlert(state, payload) {
      for (var key in payload) {
        state.alert[key] = payload[key]
      }
    },
    setShowAlert(state, payload) {
      state.alert.show = payload
    },
    setAlertMessage(state, payload) {
      state.alert.message = payload
    },
    setShowConfirm(state, payload) {
      state.confirm.show = payload
    },
    setConfirmMessage(state, payload) {
      state.confirm.message = payload
    },
    setSearchString(state, payload) {
      state.searchString = payload
    },
    setDebouncedSearchString(state, payload) {
      state.debouncedSearchString = payload
    }
  },
  actions: {
    submitFeedback(context, feedback) {
      return new Promise((resolve, reject) => {
        var docRef = firebase.firestore().collection("feedbacks");
        feedback.uid = context.getters.user.uid
        feedback.uemail = context.getters.user.email
        feedback.createdAt = Date.now()
        docRef.add(feedback).then((ret) => {
          resolve(ret)
        })
      })
    }
  },
  modules: { Auth, Org, Properties, GuestData, Feedbacks, Mail, Users, UpdatesLog, Support, Meta, appSettings },
})
