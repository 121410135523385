<template>
    <ehc-page v-if="userHasPermission(['superAdmin'])">
        <ehc-properties
         :search="gcvDebouncedSearchString"
         headerText="All Properties">
        </ehc-properties>
    </ehc-page>
</template>


<script>
import ehcProperties from '@/components/properties/ehc-properties.vue'

    export default {
        components: {ehcProperties},
        mounted() {
            this.gcvSearchString = null
        }
    }

</script>