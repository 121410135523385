<script>
import firebase from 'firebase'



export default {
  data() {
    return {
      apiUser: null,
      apiTimeout: null,
      apiUnsubArray: [],
      apiPropertiesArray: [],
      apiVars: {
        getUsers: {
          lastDoc: null,
          lastQuery: null
        },
        getDocs: {
          lastDoc: null,
          lastQuery: null
        }
      }
    }
  },
  created() {
  },
  methods: {
    async apiGenerateIndex(obj, keysToIndex = []) {

      const stringIndex = function(inputString) {
        //makes an index of all possilbe consecutive strings inside that string
        //console.log("inputString", inputString)
        inputString = inputString.toLowerCase();
        let outputArray = []
        for (let g=0; g<inputString.length; ++g){
          let processString = inputString.substring(g)
          for (let i =0; i<processString.length; ++i) {
            outputArray.push(processString.substring(0,i+1))
          }
        }

        return outputArray
      }

      let index = []
      let value = ''
      for (let i = 0; i < keysToIndex.length; ++i) {
        value = obj[keysToIndex[i]]
        if (value != null){
          if ((typeof value) == "string") { //make sure its a string, 
            index = [...index, value]
          } else if ((typeof value) == 'object') {
            index = [...index, ...value]
          }
        }
      }
      //get index for partial strings
      let separatedIndex = []
      for (let t = 0; t< index.length; ++t) {
        separatedIndex = [...separatedIndex, ...stringIndex(index[t])]
      }
      let returnIndex = [...new Set(separatedIndex)]
      //console.log("index built", returnIndex)
      return returnIndex
    },

    async apiCreateUserWithEmailAndPassword(payload) {
      //console.log("apiCreateUserWithEmailAndPassword", payload)
      const createUser = firebase.functions().httpsCallable('createUser');
      return await createUser(payload)
        .then(
          (result) => {
            // console.log('createUserWithEmailAndPassword result', result)
            return result.data
          }
        ).catch((error) => {
          console.error(error)
          throw error
        });
    },
    async apiGetUser(id) {
      //console.log("apiGetUser", id)
      const userRef = firebase.firestore().collection('users').doc(id)
      let user = {}

      return await userRef.get().then((doc) => {
        if (doc.exists) {
          let user = doc.data()
          //console.log("got user", user)
          this.apiUser = user
          return user
        } else {
          //console.log("user does not exist")
          //setup listener for if the user is created 
          userRef.onSnapshot((doc)=>{
            let user = doc.data()
            this.apiUser = user
          })
        }
      })     
    },
    async apiUpdateUser(id, payload) {
      //console.log("apiUpdateUser", id, payload)
      const userRef = firebase.firestore().collection('users').doc(id)

      return await userRef.update(payload).then((resp) => {
        //console.log('apiUpdateUser success!', resp)
        return true
      })
    },
    // takes an array of user ID's and returns a array of objects with user info
    async apiGetListOfUsers(uidList) {
      //console.log("apiGetListOfUsers", uidList)
      let members = []

      uidList.forEach(async (member) => {
        let userRef = firebase.firestore().collection("users").doc(member)
        await userRef.get()
          .then(doc => {
            members.push(doc.data())
          })
      })
      return members
    },
    async apiGetUsers(passedParams) {
      //console.log("apiGetUsers", passedParams)
      let params = { //defines default parameters
        search: null,
        limit: null,
        lastItem: null,
        nextPage: false
      }
      for (const key in passedParams) { //applies the passed params
        params[key] = passedParams[key]
      }

      let query

      if (this.apiVars.getUsers.lastQuery && params.nextPage === true) { //if there is a lastQuery and nextPage flag start from there
        query = this.apiVars.getUsers.lastQuery.startAfter(this.apiVars.getUsers.lastDoc || 0)
      } else {
        const ref = firebase.firestore().collection("users")
        query = ref
        if (params.search) {
          query = query.where("searchIndex", "array-contains", params.search)
        } 
    
        if (params.limit) {
          query = query.limit(params.limit)
        }
      }

      return await query.get().then( async (docs) =>{
        let users = []
        this.apiVars.getUsers.lastDoc = docs.docs[docs.docs.length-1]
        this.apiVars.getUsers.lastQuery = query
        // console.log(docs)
        await docs.forEach((doc) => {
          
          let user = doc.data()

          if (!('permissions' in user)) {
            user.permissions = []
          }
          // console.log("getProperties forEach", prop)
          users = [...users, user]
        })
        return users
      })
    },

    async apiMarkPropertyDeletedAt(prop) {
      const setDeleted =  firebase.functions().httpsCallable('setPropertyAsDeleted')
      return await setDeleted(prop)
    },
    async apiUpdateProperty(id, payload) {
      console.log("apiUpdateProperty", id, payload)
      const propRef = firebase.firestore().collection('properties').doc(id)

      return await propRef.update(payload).then((resp) => {
        console.log("apiUpdateProperty success", resp)
        return true
      })
    },

    async apiPutProperty(id, payload){
      let obj = {}
      obj = payload
      obj.docId = id
      obj.updatedAt = Date.now()
      obj.updatedFrom = "setUp"
      const propertiesRef = firebase.firestore().collection('properties')
      const propertyRef = propertiesRef.doc(obj.docId)
      return await propertyRef.set(obj)
      .then((response) => {
        console.log(`${obj.docId} updated ${obj.updatedFrom}`) })
      .catch( (err) => console.log(err) )
    },

    async apiSetProperty(id, payload) {
      console.log("apiSetProperty", id, payload)
      const propRef = firebase.firestore().collection('properties').doc(id)
      return await propRef.set(payload, { merge: true }).then((ret) => {
        return true
      })

    },

    async apiMakeNewProperty(orgid, uid, orgName) {
      console.log("apiMakeNewProperty", orgid, uid, orgName)
      let obj = {
        'uid': uid,
        'orgid': orgid,
        'orgName': orgName
      }
      const addProperty = firebase.functions().httpsCallable('makeNewProperty')
      return await addProperty(obj).then(async (resp) => {
        //console.log("made Property", resp)

        //increment counter on organization 
        await this.apiPropCountInrement(orgid)
        return resp.data  

        // return true
      }).catch(err => {
        //console.log("failed to make property", err)
      })


    },
    async apiRecalcPropCount(orgid = null) {
      // if orgid provided calculates just that org
      // if not then calculates total properties
      //console.log("apiRecalcPropCount", orgid)
      let query

      let ref = firebase.firestore().collection('properties')
        .where("deletedAt", "==", null)
      if (orgid) {
        query = ref.where("orgId", "==", orgid)
      } else {
        query = ref
      }

      return await query.get().then(async (resp) => {
        const size = resp.size
        if (orgid) {
          //console.log("property number", size)
          const orgRef = firebase.firestore().collection('organizations').doc(orgid)
          return await orgRef.update({ propCount: size }).then(() => {
            //console.log("org updated")
            return size
          })
        }
      })
    },
    async apiImportPropertiesFromUser(uid, orgid) {
      // gets all properties with a uid and makes the property orgid orgid
      //console.log("apiImportPropertiesFromUser", uid, orgid)
      let ref = firebase.firestore().collection('properties')
        .where('uid', '==', uid)

      return await ref.get().then(async (docs) => {
        let counter = 0
        // console.log("apiImportPropertiesFromUser got properties")
        await docs.forEach(doc => {
          let data = doc.data()
          //console.log("property", data.docId)
          if ('docId' in data) {
            let docref = firebase.firestore().collection('properties').doc(data.docId)

            docref.update({ orgId: orgid })

            counter++
            // console.log("COUNTING", counter)
          }

        })
        // console.log("counting done", counter)
        return counter
      })
    },
    async apiPropCountInrement(orgid, count = 1) {
      let orgRef = firebase.firestore().collection('organizations').doc(orgid)
      //console.log("incrementing org", orgid, count)
      return await orgRef.update({ propCount: firebase.firestore.FieldValue.increment(count) }).then(async (resp) => {
        //console.log("incrementing global", count)
        let counterRef = firebase.firestore().collection('settings').doc('counter')
        return await counterRef.update({ propCount: firebase.firestore.FieldValue.increment(count) })
      })
    },
    async apiUpdatePropCount(count) {
      const ref = firebase.firestore().collection("settings").doc('counter')

      return await ref.update({
        propCount: count,
        propCountLog: firebase.firestore.FieldValue.arrayUnion({ timeStamp: Date.now(), count: count })
      })
    },

    //GENERIC get and update doc by ID
    async apiGetDoc(collection, docID) {
      console.log("apiGetDoc", collection, docID)

      const docRef = firebase.firestore().collection(collection).doc(docID)
      return await docRef.get().then((doc) => {
        if (doc.exists) {
          let data = doc.data()
          console.log("got doc", data)
          return data
        } else {
          console.log("data doesn't exist")
        }
      })
    },
    async apiUpdateDoc(collection, docID, payload) {
      // console.log("apiUpdateDoc", collection, docID, payload)

      const docRef = firebase.firestore().collection(collection).doc(docID)

      return await docRef.update(payload).then((resp) => {
        //console.log("updated", resp)
        return true
      })
    },

    // Subscribe to a document by ID
    apiSubscribeToDoc(collection, docID) {
      const docRef = firebase.firestore().collection(collection).doc(docID);
      
      const unsubscribe = docRef.onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          this.property = data
        } else {
          console.log("Document doesn't exist");
        }
      });
      
      // Return the unsubscribe function to allow unsubscribing when needed
      return unsubscribe;
    },


    //Gets list of docs  
    //takes collection name and conditions and order by returns array of objects
    async apiGetDocs(collection, passedParams = {}) {
      //console.log("apiGetDocs",collection, passedParams)
      let params = { //defines default parameters
        search: null,
        limit: null,
        lastItem: null,
        nextPage: false,  //this tells it to just load the next page
        orderBy: null, //this should be array of arrays [['valueKey','asc or desc']]
        where: null, //this should be an array of arrays [['valueKey','==',somevalue]]
        storedArray: null, //this should just be a string of the array name
        snapshot: false
      }
      for (const key in passedParams) { //applies the passed params
        params[key] = passedParams[key]
      }

      console.log("apiGetDocs", collection, params)

      let waitTime = 0
      if (params.nextPage) {
        waitTime = 200 // I don't want to wait if this is a new query, this prevents a bug when first loading the page
      } else if (params.storedArray && !params.nextPage) {
        this[params.storedArray] = []
      }
      return new Promise((resolve, reject) => { //snapshot doesn't return a promise so I have to do this
      if (this.apiTimeout) clearTimeout(this.apiTimeout)
      this.apiTimeout = setTimeout(async () => { //this prevents errors when loading the page. 
        //console.log("apiGetDocs debounced", collection, params)
        let query

        if (this.apiVars.getDocs.lastQuery && params.nextPage === true) { //if there is a lastQuery and nextPage flag start from there
          query = this.apiVars.getDocs.lastQuery.startAfter(this.apiVars.getDocs.lastDoc || 0)
        } else {
          //BUILD THE QUERY
          const ref = firebase.firestore().collection(collection)
          query = ref

          if(params.storedArray) {
            this[params.storedArray] =[]
          }

          if (params.where) {
            params.where.forEach((where) => {
              query = query.where(where[0],where[1],where[2])
            })
          }

          if (params.orderBy) {
            params.orderBy.forEach((orderBy) => {
              if (orderBy.length == 2) {
                query = query.orderBy(orderBy[0], orderBy[1])
              } else {
                query = query.orderBy(orderBy[0])
              }
            })
          }

          if (params.search) {
            query = query.where("searchIndex", "array-contains", params.search)
          } 
      
          if (params.limit) {
            query = query.limit(params.limit)
          }
        }



          if (params.snapshot) {
            //SNAPSHOT//
            this.apiUnsubArray.push(query.onSnapshot(async (docs) => {
                let finishedDocs = []
                //save query and last doc for reference with next page
                this.apiVars.getDocs.lastDoc = docs.docs[docs.docs.length-1]
                this.apiVars.getDocs.lastQuery = query
                await docs.forEach((doc) => {
                  let finDoc = doc.data()
                  // console.log("getProperties forEach", prop)
                  let foundIndex = this[params.storedArray].findIndex(el => el.key === finDoc.key)//check if this document is already loaded
                  if (foundIndex >= 0 ) {
                    // console.log("updating document", finDoc)
                    this[params.storedArray][foundIndex] = finDoc
                  } else {
                    // console.log("adding new document", finDoc)
                    finishedDocs = [...finishedDocs, finDoc]
                  }
                })
                this[params.storedArray] = [...this[params.storedArray],...finishedDocs]
                resolve( finishedDocs)
              }))


          } else {
            //GET//
            query.get().then(async (docs) =>{
              let items = []
              console.log("got Docs", docs)
              this.apiVars.getDocs.lastDoc = docs.docs[docs.docs.length-1]
              this.apiVars.getDocs.lastQuery = query
              console.log("got Docs")
              await docs.forEach((doc) => {

                let item = doc.data()
                item.docId = doc.id
                items = [...items, item]
              })
              // console.log("items", items)
              if (params.storedArray) {
                this[params.storedArray] = [...this[params.storedArray],...items]
              }
              // console.log("returning")
              resolve(items)
            })
          }
        }, waitTime)  //end debounce
      })
    },

    async apiGetOrgsForUid(uid) {
      let orgs = []
      var docRef = firebase.firestore().collection("organizations")
      return await docRef.where("members", "array-contains", uid).get()
      .then((docs) => {
        console.log("testing ", docs)
        let obj = null
        let id = null
        docs.forEach((doc) => {
          obj = doc.data()
          orgs.push(obj)
        })
        console.log("orgs", orgs)
        return orgs
      })
    },

    //HANDLING SETTINGS
    async apiGetAppSettings() {
      //console.log("apiGetAppSettings")

      await this.apiGetDoc("settings", "globalAppSettings").then((data) => {
        //console.log("commit app settings", data)

        this.$store.commit('setAppSettings', data)
      })
    },
    async apiUpdateAppSettings(payload) {
      //console.log("apiUpdateAppSettings")
      this.apiUpdateDoc("settings", "globalAppSettings", payload).then((data) => {
        this.apiGetAppSettings()
      })
    },


    // ORGANIZATION MANIPULATION
    async apiGetOrg(orgID) {
      //console.log("apiGetOrg", orgID)
      const ref = firebase.firestore().collection('organizations').doc(orgID)

      return await ref.get().then(async (doc) => {
        let data = doc.data()
        //console.log("got org", data)
        return data
      })
    },
    async apiUpdateOrg(orgID, payload) {
      // console.log("apiUpdateOrg", orgID, payload)

      if ("webSite" in payload) {
        payload.webSite = this.apiPrependHttp(payload.webSite)
      }

      this.apiUpdateDoc('organizations', orgID, payload).then((resp) => {
        console.log("successfull org update")
      })
    },
    async apiGetOrgs() {
      //console.log("apiGetOrgs")

      const ref = firebase.firestore().collection('organizations')

      return await ref.get().then(async (docs) => {
        let orgs = []
        await docs.forEach((doc) => {
          let org = doc.data()
          org.orgId = doc.id
          // console.log("getProperties forEach", prop)
          orgs = [...orgs, org]
        })
        return orgs
      })
    },
    async apiRemoveUserFromOrg(orgid, uid) {
      let orgRef = firebase.firestore().collection('organizations').doc(orgid)

      return await orgRef.update(
        { members: firebase.firestore.FieldValue.arrayRemove(uid) }
      )
    },
    async apiGetOrgFromUser(uid) {
      console.log("apiGetOrgFromUser", uid)
      const ref = firebase.firestore().collection('organizations')

      return await ref.where("members", "array-contains", uid).get().then((docs) => {
        console.log(docs)
        let org = {}
        docs.forEach((doc) => {
          org = { ...doc.data(), id: doc.id }
          console.log("forEach", org)
        })
        return org

      })
    },
    async apiGetBackgroundImages() {
      console.log("apiGetBackgroundImages");
      const db = firebase.firestore().collection('backgroundImages');

      try {
        const querySnapshot = await db.get();
        const backgroundImages = [];

        querySnapshot.forEach((doc) => {
          backgroundImages.push(doc.data());
        });

        return backgroundImages;
      } catch (error) {
        console.error("Error getting background images:", error);
        throw error; // Optionally rethrow the error
      }
    },
    async apiPutBackgroundImage(obj,index){
      console.log("putBackgroundImage")
      const db = firebase.firestore().collection('backgroundImages')
      return await db.doc(index).set(obj)
    },
    async apiUndeleteProperty(propertyId){
      console.log("apiUndeleteProperty", propertyId)
      let propertyRef = firebase.firestore().collection('properties').doc(propertyId)
      return await propertyRef.update({deletedAt: null})
    },

    async apiGetCountOfOldDocuments(collectionName="updateslog2"){
      const firestore = firebase.firestore();
      const collectionRef = firestore.collection(collectionName);
      const cutoffDate = new Date();
      
      cutoffDate.setDate(cutoffDate.getDate() - 250);
      const query = collectionRef.where('updatedAt', '<', cutoffDate.toISOString());
      return query.get().then((querySnapshot) => {
        var count = querySnapshot.size;
        console.log('Number of documents older than 90 days:', count);
        return count;
      });

    },

    //get logs from logGuestVisits collection in firebase
    async apiGetGuestVisits(daysAgo = 3, propertyId = null) {
      console.log("apiGetGuestVisits");
      const ref = firebase.firestore().collection('logGuestVisits');
      const pastDate = new Date();
      pastDate.setDate(pastDate.getDate() - daysAgo);
      pastDate.setHours(0, 0, 0, 0);

      let query = ref.where('dateTime', '>', pastDate).orderBy('dateTime', 'desc');

      if (propertyId) {
        query = query.where('propertyId', '==', propertyId);
      }
      const querySnapshot = await query.get();

      const visits = [];
      const visitsByDay = {};
      const propertyIds = new Set();

      for (const doc of querySnapshot.docs) {
        const visit = doc.data();
        
        // Add validation or error handling for visit.dateTime if necessary
        
        const visitDate = visit.dateTime.toDate().toDateString();
        visit.id = doc.id;

        visits.push(visit); // Include all visits

        propertyIds.add(visit.propertyId);

        if (visitsByDay.hasOwnProperty(visitDate)) {
          visitsByDay[visitDate] += 1;
        } else {
          visitsByDay[visitDate] = 1;
        }
      }

      const payload = { visits, visitsByDay };
      console.log("payload", payload);
      return payload;
    },


    // THESE METHODS USED FOR PROCESSING DATA FOR UPLOADING
    apiPrependHttp(url, { https = true } = {}) {
      if (!url.length) { return null }
      if (typeof url !== 'string') {
        throw new TypeError(`Expected \`url\` to be of type \`string\`, got \`${typeof url}\``);
      }

      url = url.trim();

      if (/^\.*\/|^(?!localhost)\w+?:/.test(url)) {
        return url;
      }

      return url.replace(/^(?!(?:\w+?:)?\/\/)/, https ? 'https://' : 'http://');
    },
  },
  computed: {

  }
}
</script>