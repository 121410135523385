<template>
  <ehc-page>
    <ehc-organization v-model="orgID">

    </ehc-organization>
  </ehc-page>
</template>

<script>
import EhcOrganizationAddMember from '../../components/ehc-organization-add-member.vue'
import ehcOrganization from '../../components/ehc-organization.vue'


export default {
  components: {  EhcOrganizationAddMember, ehcOrganization },
  data: function() {
    return {
    }
  },
  mounted() {
  },
  computed: {
    orgID() {
      const { id } = this.$route.params;
      const { propertyId } = this.$route.query;

      return propertyId || id || null;
    }
  },

}
</script>

