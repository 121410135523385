<template>
    <v-navigation-drawer
        class="px-0 mx-0 ehc-nav-drawer"
        width="174px"
        clipped
        app
        overlay-color="#1D3557"
        :overlay-opacity=".7"
        v-model="drawerShow"
        >
        <template #prepend>
            <v-list-item>
                <v-list-item-icon class="ma-0">
                    <v-img src="@/assets/ehicond.png" contain height="25px" max-width="25px" class="my-2 ml-2 pa-0" @click = "drawerShow = !drawerShow"></v-img>
                </v-list-item-icon>
                
            </v-list-item>
            <v-divider></v-divider>
        
        </template>
        <ehc-menu :items="menuItems"></ehc-menu>
        <template #append>
            <ehc-menu :items="logoutMenu" @logout="$store.dispatch('logout')"></ehc-menu>
        </template>
    </v-navigation-drawer>


</template>


<script>
export default {
    data() {
        return {
            logoutMenu: [
                {type: 'link', label:"Logout", iconURL: require('@/assets/icons/logout@3x.svg'), emitClick: "logout"},
            ]

        }
    },
    methods: {
    },
    computed:{    
        menuItems() {
            const basicItems = [
                {type: 'link', label:"Properties", iconURL: require('@/assets/icons/Properties -normal@3x.svg'), route: '/'},
                {type: 'link', label:"Guest Data", iconURL: require('@/assets/icons/User Data -normal@3x.svg'), route: '/guestdata'},
                {type: 'link', label:"Support", iconURL: require('@/assets/icons/Support -normal@3x.svg'), route: '/support'},
                
            ]

            let adminItems = this.userHasPermission(['superAdmin'],[
                {type: "subheader", label: "Admin Links"},
                {type: "divider"},
                {type: 'link', label:"All Properties", iconURL: require('@/assets/icons/Properties -normal@3x.svg'), route: '/allProperties'},
                {type: 'link', label:"Users", iconURL: require('@/assets/icons/2 User@3x.svg'), route: '/users'},
                {type: 'link', label:"Feedbacks", iconURL: require('@/assets/icons/Support-outline@3x.svg'), route: '/feedbacks'},
                {type: 'link', label:"Organizations", iconURL: require('@/assets/icons/Home@3xplain.svg'), route: '/organizations'},
                {type: 'link', label:"Backgrounds", iconURL: require('@/assets/icons/location@3x-plain.svg'), route: '/backgroundImages'},
                {type: 'link', label:"Updates Log", iconURL: require('@/assets/icons/clock@3x.svg'), route: '/updateslog'},
                {type: 'link', label:"Admin Settings", icon: 'mdi-cog-outline', route: '/adminSettings'},
                {type: 'link', label:"Analytics", icon: 'mdi-poll', route: '/analytics'}
                ],[])

            return [...basicItems, ...adminItems]
        },
        drawerShow: {
            get() {
                return this.$store.getters.drawer.show
            },
            set(val) {
                this.$store.commit('setDrawer',{show: val})
            } 
        },
    }
}



</script>

<style>

.ehc-nav-drawer .v-list-item__title {
    font-family: 'Sailec';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1D3557;;
}


</style>