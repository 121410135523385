<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
              <ehc-property-card 
                :property="property" 
                :actions="actions" 
                v-on="$listeners"
                @deleteProperty="message= {...confirmDelete, prop: $event}"
                @publishProperty="publishProperty($event)"
                @showInterview="showInterview($event.propertyId)"
                @showAnalytics="showAnalytics($event.propertyId)"
                @goToPreview="goToPreview($event.propertyId)"
                @propertyLink="showPropertyLink($event)"
                @editProperty="goToProperty($event)"
                @showTransferProperty="showTransferProperty($event.propertyId)"
                @copyProperty="copyProperty($event)"
                @guestInfo="setGuestInfo($event)"
                @goToOrg="goToOrg($event)"
                @bottomButton="getProperties(true)"
                @intersectBottom="getProperties(true)"

                ></ehc-property-card>
            </div>
        </div>
          <ehc-message 
            @reindexProps="reindexProps()"
            @cancel ="message=closeMessage"
            @deleteProp = "deleteProperty(message.prop)"
            @nextInterview="nextInterview($event)"
            @closeInterview="closeInterview()"
            v-model="message"
            >

            <template #link>
                Link to {{message.prop.name}} guide has been copied to your clipboard <br/><br/>
                <p class="text-h5 text-center" v-if="!message.prop.publishedAt">This property has not been published yet.</p>
                <v-text-field 
                    outlined 
                    dense 
                    v-model="message.link"
                    append-icon="mdi-content-copy"
                    @click:append="copyLink(message.link)"
                    readonly
                    >
                </v-text-field>
                <qrcode-vue  :value="message.link" size="300" level="H" class="mx-auto qrcode" style="inline-size: fit-content;" ></qrcode-vue>
            </template>

            <template #maxProps>
                <h5>you have reached the maximum number of properties available ({{propLimit}}). To talk about our premium options contact ed@everhost.io.</h5> 
            </template>

            <template #transferProp>
                <span style="font-size: .8em;">This feature is in 'beta'. Be sure you enter a valid uid and orgId<br /></span>
                <ehc-transfer-prop :value="message" @transferProp="transferProp($event)" @closeTransfer="closeTransfer" ></ehc-transfer-prop>
            </template>

            <template #interview>
                <ehc-interview 
                    :propertyId="message.propertyId" 
                    :page="message.page" 
                    @change="message.formData = $event" 
                    @closeInterview="closeInterview"
                    :trigger = "message.trigger"
                    :key="propertyId"></ehc-interview>
            </template>

            <template #analytics>
                <ehc-analytics-guest 
                    close 
                    :propertyId="message.propertyId" 
                    :guestInfo="message.guestInfo" 
                    @closeAnalytics="closeAnalytics">
                </ehc-analytics-guest>
            </template>

        </ehc-message>
    </div>
</template>

<script>
import ehcPropertyCard from '../components/properties/ehc-property-card.vue';
import PropertyActions from '../mixins/propertyActions.vue';

export default {
  components: { ehcPropertyCard },
    name: 'Home',
    mixins: [PropertyActions],
    data() {
        return {
            propertyId: null,
            property: null,
            action: [],
            message: {
              show: false,
            },

        }
    },
    computed: {
            actions() {
                let items = [
                        {type:'link',   label: 'Edit',                iconURL: require('@/assets/icons/edit@3x.svg'),     emitClick: 'editProperty',     toolTip: 'Edit property'},
                        {type:'link',   label: 'QR Code & Link',                iconURL: require('@/assets/icons/Link@3x.svg'),     emitClick: 'propertyLink',     toolTip: 'Get property link'},
                        {type:'link',   label: 'Delete',              iconURL: require('@/assets/icons/trash@3x.svg'),    emitClick: 'deleteProperty',   toolTip: 'Delete property'},
                        {type:'toggle', key: 'publishedAt',   
                            onTrue:     {label: 'Unpublish',          iconURL: require('@/assets/icons/unpulish@3x.svg'), emitClick: 'publishProperty',        toolTip: 'click to unpublish property'},
                            onFalse:    {label: 'Publish',            iconURL: require('@/assets/icons/unpulish@3x.svg'), emitClick: 'publishProperty',        toolTip: 'click to publish property'}
                        },
                        {type:'link',   label: 'Copy',                iconURL: require('@/assets/icons/Copy@3x.svg'),     emitClick: 'copyProperty',     toolTip: 'Copy property'},
                        {type:'toggle', key: 'noguestinfo',   
                            onFalse: {label: 'Do not ask for guest info',            icon:    'mdi-clipboard-account-outline',           emitClick: 'guestInfo',        toolTip: 'Copy property'},
                            onTrue:  {label: 'Ask for guest info',                   icon:    'mdi-clipboard-account-outline',           emitClick: 'guestInfo',        toolTip: 'Copy property'}
                        },
                        {type:'link',   label: 'Analytics', icon: 'mdi-chart-box-outline', emitClick: 'showAnalytics', toolTip: 'Guest Analytics'},                        
                        ...this.userHasPermission(['superAdmin'],[
                            {type: 'subheader', label:'Admin'},
                            {type: 'divider'},
                            {type:'link', label: 'Transfer', icon: "mdi-swap-horizontal", emitClick: 'showTransferProperty', toolTip: 'Transfer property to another account'},
                            {type:'link', label: 'View Organization', iconURL:  require('@/assets/icons/Home@3xplain.svg'), emitClick: 'goToOrg', toolTip: 'view the organization of this property'},
                            {type:'link',   label: 'Setup',              icon: 'mdi-handshake',    emitClick: 'showInterview',   toolTip: 'Add Property Helper'},                        
                            {type:'link',   label: 'Preview',              icon: 'mdi-eye-arrow-right',    emitClick: 'goToPreview',   toolTip: 'Preview of Dev'},                        
                            {type:'link',   label: 'Property Template',              icon: 'mdi-copyleft',    emitClick: 'goToDefault',   toolTip: 'Default Template'},                        
                        ],[])
                    ]
                return items
            },
    },
    method: {
      setProperty(prop) {
        this.property = prop
      }
    },
    async created() {
        this.propertyId = this.$route.params.propertyId;
        this.property = await this.apiSubscribeToDoc("properties", this.propertyId);
    } 

}
</script>

<style>

</style>