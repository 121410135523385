import { render, staticRenderFns } from "./ehc-message.vue?vue&type=template&id=05edda4a&scoped=true&class=ehc-message"
import script from "./ehc-message.vue?vue&type=script&lang=js"
export * from "./ehc-message.vue?vue&type=script&lang=js"
import style0 from "./ehc-message.vue?vue&type=style&index=0&id=05edda4a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05edda4a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VSpacer})
