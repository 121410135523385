<template>
    <div class="ehc-context-menu">
        <v-menu offset-y rounded="lg">
            <template #activator="{on, attrs}">
                <v-icon v-bind="attrs" v-on="on" :color="color">{{icon}}</v-icon>
            </template>
            <ehc-menu :items="items" v-on="$listeners" v-bind="$attrs"></ehc-menu>
        </v-menu>
    </div>
</template>


<script>
export default {
    props: {
        icon: {type: String, default: "mdi-dots-vertical"},
        items: {type: Array},
        color: {type: String}
    },
    data() {
        return  {

        }
    }

}


</script>

<style>
</style>