<template>
    <div>
        <ehc-card>

          <v-btn 
            v-if="close"
            class="mt-n1"
            dark     
            icon 
            large
            absolute
            right
            style="text-align: right"
            color="primary"
            @click="$emit('closeAnalytics')">
            <v-icon dark>mdi-close</v-icon>
          </v-btn>

          <ehc-card-content class="text-h4">
              {{title}}
              <v-icon @click="showGuidbookLogIntro=!showGuidbookLogIntro">mdi-information</v-icon>
          </ehc-card-content>
      
          <p v-if="showGuidbookLogIntro">
              {{guidbookLogIntroNoRepeat}}
          </p>
          <ehc-card-content>
            <v-row>
              <v-cols cols="6" class="ma-5">
              <v-select
                  v-model="guidebookLogLimit"
                  :items="[1,2,3,4,5,6,7,10,14,21,30,90]"
                  label="Number of Days"
                  item-text="name"
                  item-value="id"
                  @change="getGuestVisits(guidebookLogLimit-1)"
                  ></v-select>
              </v-cols>
            </v-row>
          </ehc-card-content>

          <v-list v-if="guestVisitsByDay.length">
              <v-list-item v-for="(item, i) in guestVisitsByDay" :key="i">
                  {{item[0]}}: {{item[1]}}
              </v-list-item>
              <v-list-item>
                  Total: {{guestVisits.length}}  
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                          <v-icon
                          @click="guestVisitsShowAllToggle"
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="mx-3"
                          >
                          mdi-all-inclusive-box-outline
                          </v-icon>
                      </template>
                      <span>{{dailyRepeatsButtonText}}</span>
                      </v-tooltip>

              </v-list-item>
          </v-list>    

        </ehc-card>

        <ehc-table 
            :items="guestVisits" 
            :headers="guestHeaders" 
            :loading="guestsLoading"
            @click:row="rowClick"
            :selectable="true"
            :rowClass="'pointer'"
            >
        </ehc-table>
    </div>  
</template>

<script>
export default {
  props: {
    propertyId: {
      type: String,
      default: null
    },
    close: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Guest Browser Sessions"
    },
  },
  data() {
      return {
        guestVisits: [],
        guestVisitsByDay: [],
        guestVisitsShowAll: false,         
        guestsLoading: false,
        showGuidbookLogIntro: false,
        guidebookLogLimit: 0,
        guidbookLogIntro: "This feature provides a comprehensive overview of guest browser sessions. Whenever a guest accesses this guidebook through a browser, a session is initiated. This functionality proves valuable in monitoring the number of individuals actively utilizing the guidebook. Moreover, it enables tracking the number of users engaging with the guidebook on a specific day. It's important to note that if a guest opens the guidebook multiple times within the same day, each instance will be counted as a separate session. However, it's worth mentioning that sessions generated by logged-in users will not be included in this count.",
        guidbookLogIntroNoRepeat: 'This feature provides a comprehensive overview of daily guestbook access. This functionality proves valuable in monitoring the number of days a guidebook is accesses by a guest. Sessions generated by logged-in users will not be included in this count. Use the infinity icon to toggle between showing/hiding repeated daily access.',
        showAnaylticsForPropertyId: null,
      }
    },
  methods: {         
    async getGuestVisits(daysAgo, propertyId = this.propertyId) {
      this.guestsLoading = true;
      
      try {
          const results = await this.apiGetGuestVisits(daysAgo, propertyId);
          const guestVisits = results.visits.map((item) => ({
          ...item,
          dateTime: this.convertTimestampToDate(item.dateTime),
          }));

          const { unigueVisits, guestVisitsByDay } = this.filterUniqueVisits(guestVisits);
          
          this.guestVisits = unigueVisits.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
          this.guestVisitsByDay = guestVisitsByDay;
          this.guestsLoading = false;
      } catch (error) {
          console.log(error);
          // Handle error appropriately (e.g., display error message)
          this.guestsLoading = false;
      } finally {
          this.guestsLoading = false;
      }
    },

    filterUniqueVisits(array) {
      const unigueVisits = [];
      const seenPropertyDateTime = new Set();
      const dateTimeCounts = {};

      for (const obj of array) {
          const { propertyId, dateTime } = obj;
          const propertyDateTimeKey = `${propertyId}_${dateTime}`;

          if (!seenPropertyDateTime.has(propertyDateTimeKey) || this.guestVisitsShowAll) {
          seenPropertyDateTime.add(propertyDateTimeKey);
          unigueVisits.push(obj);
          dateTimeCounts[dateTime] = (dateTimeCounts[dateTime] || 0) + 1;
          }
      }

      const guestVisitsByDay = Object.entries(dateTimeCounts);

      return { unigueVisits, guestVisitsByDay };
    },
    rowClick(item) {
      console.log("rowClick", item)
      this.goToProperty(item)
    },
    convertTimestampToDate(timestamp) {
      // Extract the seconds and nanoseconds parts of timestamp
      const timestampSeconds = timestamp.seconds;
      const timestampNanoseconds = timestamp.nanoseconds;

      // Calculate the total number of milliseconds (including fractional milliseconds)
      const totalMilliseconds = timestampSeconds * 1000 + Math.floor(timestampNanoseconds / 1e6);

      // Create a new Date object with the calculated milliseconds
      const date = new Date(totalMilliseconds);

      // Extract the components of the date
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1; // Months are zero-based, so add 1
      const day = date.getUTCDate();
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const seconds = date.getUTCSeconds();

      // Format the date as a string
      // const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} `;

      return formattedDate;
    },
    guestVisitsShowAllToggle() {
      this.guestsLoading = true;
      this.guestVisitsShowAll = !this.guestVisitsShowAll;
      this.getGuestVisits(this.guidebookLogLimit);
      this.guestsLoading = false;
    },
    goToProperty(prop) {
      const url = this.appSite + prop.propertyId
      // console.log("opening property URL", url)
      window.open(url,"", "width=900, height=812");
    },

  },

  computed: {
    guidebookLogLimitButtonText() {return `Get Guest Browser Sessions for the past ${this.guidebookLogLimit} days`},
    dailyRepeatsButtonText() {return this.guestVisitsShowAll ? 'Hide Repeated Visits' : 'Show All Visits'},
    guestHeaders() { 
      let headers = []
      if ( !this.propertyId) {
        headers = [
          {text: 'Property', value: 'propertyId'},
          {text: 'Date', value: 'dateTime'},
        ]
      } else {
        headers = [
          {text: 'Date', value: 'dateTime'},
        ]
      }
      return headers
    },
    appSite() {return this.$store.getters.appSite},

  }

}
</script>