<template>
  <ehc-page>
    <ehc-page-title>Obsolete? - is now handled in ehc-interview</ehc-page-title>
    # {{currentPage}} of {{fieldsLength}}
    <!-- {{objToUpdate}} -->
    <ehc-form 
          v-model="formData" 
          :meta="currentFields" 
          @submit="submit()" 
          @change="change"
          @blur="blur"
          @valid="isValid=$event"
          @onImageUpload="onImageUpload($event)"
          :useAutocomplete="useAutocomplete"
          :shakeInvalid="shakeInvalid"
          :user = user>
      </ehc-form>
  <v-btn 
    v-if="currentPage <= fieldsLength"
    dark
    absolute 
    right 
    icon 
    large
    color="primary"
    @click="nextPage">
    Next
    <v-icon dark large>mdi-chevron-right</v-icon>
  </v-btn>
  <v-btn 
    v-if="currentPage > fieldsLength"
    color="primary"
    dark
    large
    block
    @click="goToProperty(propertyId)">
    Go to my property to finish the setup
  </v-btn>
  <v-btn 
    v-if="currentPage > fieldsLength"
    absolute 
    right 
    icon 
    large
    color="primary"
    @click="currentPage = 1">
    <v-icon dark large>mdi-chevron-left</v-icon>
    Back  
  </v-btn>
  <v-card width="400" class="mx-auto" @click="goToProperty(propertyId)">
    <v-card-text>
      Property Name: {{formData.name}}
    </v-card-text>
    <v-card-text>
      Avatar: 
      <img :src="formData.avatarImg" alt="avatar" height="100px" />
    </v-card-text>
    <v-card-text v-if="formData.mapAddress">
      Address: {{formData.mapAddress.formatted_address}}
    </v-card-text>
    <v-card-text>
      Show temperature as:: {{formData.unit_of_temperature == 'imperial' ? 'Fahrenheit' : 'Celsius'}} 
    </v-card-text>  
    <!---card item for backgroundColor--->
    <v-card-text>
      Background: {{formData.backgroundColor}} 
    </v-card-text>
    <!---guest info card item--->
    <v-card-text>
      Require Guest Info: {{formData.noguestinfo? "No" : "Yes"}}
    </v-card-text>
    <!--- booking info card item--->
    <v-card-text>
      Book again link: {{formData.bookagain}}
    </v-card-text>
        

  </v-card>
  </ehc-page>
</template>

<script>
import api from '@/mixins/api'
import BackgroundImages from "../store/backgroundImages.json"

export default {
  name: 'Interview',
  mixins: [api],
  data() {
    return {
      pageTitle: 'Interview',
      propertyId: null,
      formData: {},
      isValid: false,
      shakeInvalid: false,
      useGoogleAutocomplete: true,
      useAutocomplete: false,
      currentPage: 1
    }
  },
  computed: {
    property: function(){
      return this.$store.getters.property
    },
    fields: function(){
      let fields = [
        {
          type: "alert",
          label: "What is the name of your property in 26 words or less? (You can change this later)",
          adminOnly: false,
          pageNo: 1,
        },
        {
          type: "text",
          label: "Property Name",
          key: "name",
          maxCharacters: 26,
          counter: 26,
          required: true,
          pageNo: 1,
        },

        {
          type: "alert",
          label: "Upload a image of your property or a logo that will display on your main page. (You can change this later)",
          adminOnly: false,
          pageNo: 2,
        },

        {
          type: "space",
          label: "--",
          adminOnly: false,
          pageNo: 2,
        },

        {
          type: "imageUpload",
          label: "Upload Avatar For your property",
          key: "avatarImg",
          uploadPath: `/propertyPictures/'+property.propertyId+'-'+Date.now()`,
          circle: true,
          size: {width: 200, height: 200},
          required: false,
          showInForm: false,
          confirmRemoveMessage: 'Are you sure you want to remove this custom logo?',
          pageNo: 2,
        },


        {
          type: "alert",
          label: "Start typing your address and pick a location that is close to your address from the dropdown list. Everhost will use this to look up weather and nearby information. This address is not displayed in your guidebook. (You can change this later)",
          adminOnly: false,
          pageNo: 3,
        },
        {
          type: "mapsAutocomplete",
          label: "Start typing property's address",
          key: "mapAddress",
          pageNo: 3,
        },

        {
          type: "alert",
          label: "We will show the weather on your guidebook. Would you like the temperature in Fahrenheit or Celsius? (You can change this later)",
          adminOnly: false,
          pageNo: 4,
        },
        {
          type: "select",
          label: "Show temperature as:",
          options: [
            { 
              text: "Fahrenheit",
              value: "imperial"
             },
            { 
              text: "Celsius",
              value: "metric"
             }
          ],
          key: "unit_of_temperature",
          pageNo: 4,
        },

        {
          type: "alert",
          label: "Select a background for your guidebook. (You can change this later.)",
          adminOnly: false,
          pageNo: 5,
        },
        {
          type: "select",
          label: "Select a Background",
          key: "backgroundColor",
          options: this.bgImagesArray,
          pageNo: 5,
        },

        {
          type: "alert",
          label: "You can asks your guests for contact information. By default this is off. (You can change this later.)",
          adminOnly: false,
          pageNo: 6,
        },
        {
          type: "select",
          label: "Guest info...",
          options: [
            { 
              text: "Do not require guest info. form",
              value: true
             },
            { 
              text: "Require guest info. form on first use",
              value: false
             }
          ],
          key: "noguestinfo",
          pageNo: 6,
        },

        {
          type: "alert",
          label: "If your guest wants to book again, we will give them a link to your rental service (ie: AirBnb, VrBo). You can change this later.",
          adminOnly: false,
          pageNo: 7,
        },
        {
          type: "link",
          label: "Book Again Link (must be a valid link, including https:// or http://)",
          key: "bookagain",
          pageNo: 7,
        },

      ]
      if ( !this.userIsAdmin ) { 
          fields = fields.filter( el => !el.adminOnly )
      }
      if ( !this.orgIsPremium ) { 
          fields = fields.filter( el => !el.premiumOnly )
      }
      if ( this.showAdminOnly ) {
        fields = fields.filter( el => el.adminOnly || el.key === "submit")
      }
      if ( this.showPremiumOnly ) {
        fields = fields.filter( el => el.premiumOnly || el.key === "submit")
      }
      return fields
    },
    objToUpdate: function(){
      let obj = {}
      this.fields.forEach( field => {
        if ( field.key !== "submit" ) {
          obj[field.key] = this.formData[field.key]
        }
      })
      return obj
    },
    currentFields: function(){
      return this.fields.filter( el => el.pageNo === parseInt(this.currentPage) )
    },
    //create formFields of fields that are not alerts
    formFields: function(){
      return this.fields.filter( el => el.type !== "alert" )
    },
    fieldsLength: function(){
      let len = 0
      let allFields = this.fields.forEach( el => {
        if ( el.pageNo ) { len = el.pageNo }
      } )
      return len
    },
    bgImages: function(){
        return BackgroundImages
    },
    bgImagesArray: function(){
      let bgImagesArray = this.bgImages.map((el) => {
          return {value: el.name, text: el.text}
        })
      return bgImagesArray.sort( (a,b) => {
        if ( a.text > b.text ) { return 1 }
        else { return -1 }
      } )
    },
    bgImageFile: function(){
      let bgImage = this.bgImages.find( el => el.name === this.formData.backgroundColor )
      return bgImage.fileName
    },

  },
  methods: {
    setFormData: function(){
      this.formData = this.property
    },
    change: function(){
      console.log("change method")
    },
    goToProperty: async function(propertyId) {
      await this.updateProperty()
      const url = this.$store.getters.appSite + "login/" + propertyId
      // console.log("opening property URL", url)
      window.open(url,"", "width=900, height=812");
    },
    change: function(event){
      console.log("change method", event)
    },
    blur: function(event){
      console.log("blur method", event)
    },
    nextPage: async function(){
      this.currentPage++
      await this.updateProperty()
    },
    updateProperty: async function(){
      let obj = {}
      if (this.formData.name) {obj.name = this.formData.name}
      if (this.formData.avatarImg) {obj.avatarImg = this.formData.avatarImg}
      if (this.formData.mapAddress) {obj.mapAddress = this.formData.mapAddress}
      if (this.formData.unit_of_temperature) {obj.unit_of_temperature = this.formData.unit_of_temperature}
      if (this.formData.backgroundColor) {obj.backgroundColor = this.formData.backgroundColor}
      if (this.formData.noguestinfo) {obj.noguestinfo = this.formData.noguestinfo}
      if (this.formData.bookagain) {obj.bookagain = this.prependHttp(this.formData.bookagain)}

      await this.apiUpdateProperty( this.propertyId, obj )
      console.log("updated property: ", obj)
    },
    async onImageUpload(payload) {
      console.log("onImageUpload", payload)
      let obj = {}
      obj.avatarImg = payload.fileName
      await this.apiUpdateProperty( this.propertyId, obj )
      this.formData.avatarImg = payload.fileName
      console.log("updated property: ", obj)
    },
    //from https://github.com/sindresorhus/prepend-http/blob/main/index.js
    prependHttp(url, {https = true} = {}) {
      console.log("prependHttp", url, https)
        if ( !url ) { return null }
        if (!url.length) {return null}
        if (typeof url !== 'string') {
            throw new TypeError(`Expected \`url\` to be of type \`string\`, got \`${typeof url}\``);
        }

        url = url.trim();

        if (/^\.*\/|^(?!localhost)\w+?:/.test(url)) {
            return url;
        }

        return url.replace(/^(?!(?:\w+?:)?\/\/)/, https ? 'https://' : 'http://');
    },


  },
  watch:{
    property() {
      this.setFormData()
    }
  },
  created() {
    this.propertyId = this.$route.params.propertyId || "no propertyId"
    //if there is a query param for currentPage, set it
    if ( this.$route.query.currentPage ) {
      this.currentPage = this.$route.query.currentPage
    }
    this.$store.dispatch('getProperty', this.propertyId)
    let testPrepend = this.prependHttp("www.google.com")
    console.log("testPrepend", testPrepend)
    
  },
}
</script>