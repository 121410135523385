<!--
            let actions = [
                {actionType: 'v-btn-toggle', dense: true, changeEmit: "viewChange", default: "gallery", mandatory: true,
                    buttons: [     
                        {label: 'Grid View', value: 'gallery', outlined: true, prependIconURL: require('@/assets/icons/Grid View@3x.svg')},
                        {label: 'List View', value: 'table', outlined: true, prependIconURL: require('@/assets/icons/List View@3x.svg')}
                    ]},
                {actionType: this.userHasPermission(['superAdmin'],'v-btn'), label: "Show All", color: "tertiary", dark: false, prependIconURL: require("@/assets/icons/show@3x.svg"), clickEmit: "showAll"},
                {actionType: 'v-btn', label: "add property", color: "primary", dark: true, prependIcon: "mdi-plus", clickEmit: "addProperty"},
            ]



-->



<template>
  <div class="ehc-header">
    <div class="toolbar" v-bind="$attrs">
    <span class="text mt-1 mb-1">
      {{ text }}
    </span>
   

    <span class="actions mt-auto" >
      <template v-for="(action, index) in actions">
        <ehc-btn v-if="action.actionType == 'v-btn'" 
                :key="index" 
                v-bind="action" 
                @click="$emit(action.clickEmit,true)" 
                class="mr-2 mb-2">
            <template v-slot:left>
              <v-icon left v-if="'prependIcon' in action">
                  {{action.prependIcon}}
              </v-icon>
              <img v-if="'prependIconURL' in action" :src="action.prependIconURL" class="mr-2" />
            </template>
            <v-icon v-if="'icon' in action">
                {{action.iconName}}
            </v-icon>
            {{action.label}}
        </ehc-btn>

        <v-btn-toggle 
          v-else-if="action.actionType == 'v-btn-toggle'" 
          class="mr-2 mb-2" 
          v-bind="action"
          v-model="action.default"
          @change="$emit(action.changeEmit, $event)"
          :key=index>
            <template v-for="(button, bindex) in action.buttons">
              <ehc-btn v-bind="button" :key='bindex'>
                <template v-slot:left>
                  <v-icon left v-if="'prependIcon' in button">
                      {{button.prependIcon}}
                  </v-icon>
                  <img v-if="'prependIconURL' in button" :src="button.prependIconURL" class="mr-2" />
                </template>
                {{button.label}}
              
              </ehc-btn>
            </template>
        </v-btn-toggle>

        <div v-else-if="action.actionType == 'slot'" class="mr-2 mb-2 mt-1" :key=index>
          <slot :name="action.slotName"></slot>
        </div>

        <ehc-context-menu 
          class="mr-2  mt-1 mb-2" 
          v-bind="action"
          color="primary"
          v-else-if="action.actionType=='menu'"
          :items="action.items"
          v-on="$listeners"
          :key=index>
        </ehc-context-menu >

      </template>
    </span>
    </div>
    <div class=" loadingBar " :class="(loading)? '':'invisible'" >
      <v-progress-linear color="primary" indeterminate  striped height="5"></v-progress-linear>
    </div>
  </div>
</template>

<script>


export default {
  props: {text: String,
          actions: Array,
          loading: {type: Boolean, default: false}},
  components: {}, 
  data() {
    return {};
  },
  methods: {
    getIconURL(url) {
      return require(url)
    }
  }
};
</script>

<style>
.ehc-header .toolbar {
  padding-top: 15px;
  width: 100%;
  display: block;
  overflow: hidden
}

.ehc-header .loadingBar.invisible{
  visibility: hidden;
}

.ehc-header .loadingBar {
  margin-top: -3px;
  margin-bottom: -6px;

}

.ehc-header .text {
  font-family: "Sailec";
  font-style: normal;
  color: #1d3557;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  float: left;
}

.ehc-header .actions {
  width: fit-content;
  float: right;
  margin-left: auto;
  margin-right: 0;
  display: -webkit-box;
}

.ehc-header .actions .v-btn-toggle {
  border-radius: 8px;
}
</style>
